import { connect } from 'react-redux';
import {
  registerCCO,
  resendOtp,
  verifyOtp,
  getCharityDetails,
  getCharityTypes,
  confirmAddress,
  saveServices,
  activateUser
} from '../../../../services/auth/register/actions';
import { RouteComponentProps } from 'react-router-dom';

interface StateProps {
  loading: boolean;
  fetch_register_cco_succeeded: boolean;
  fetch_register_cco_failed: boolean;
  verify_otp_succeeded: boolean;
  verify_otp_failed: boolean;
  get_charity_details_succeeded: boolean;
  get_charity_details_failed: boolean;
  charity_details: any;
  confirm_charity_details_succeeded: boolean;
  confirm_charity_details_failed: boolean;
  services: any;
  save_services_succeeded: boolean;
  save_org_profile_succeeded: boolean;
  save_org_profile_failed: boolean;
}

interface DispatchProps {
  registerCCO: (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    usergroup: string
  ) => void;
  resendOtp: (
    firstName: string,
    lastName: string,
    email: string,
    usergroup: string
  ) => void;
  verifyOtp: (email: string, otp: string, usergroup: any) => void;
  getCharityDetails: (email: string, charityNumber: string, id: any) => void;
  getCharityType: () => void;
  confirmAddress: (email: string, address: any) => void;
  saveServices: (payload: any, charityNumber: any) => void;
  activateUser: (payload: any) => void;
}

interface OwnProps extends RouteComponentProps {
  match: any;
}

export interface RegisterCCOProps extends StateProps, DispatchProps, OwnProps {}

const mapStateToProps = (state: any): StateProps => {
  return {
    loading:
      state.register_cco.fetch_register_cco_loading ||
      state.register_cco.verify_otp_loading ||
      state.register_cco.get_charity_types_loading ||
      state.register_cco.get_charity_details_loading ||
      state.register_cco.confirm_charity_details_loading ||
      state.register_cco.save_services_loading,
    fetch_register_cco_succeeded:
      state.register_cco.fetch_register_cco_succeeded,
    fetch_register_cco_failed: state.register_cco.fetch_register_cco_failed,
    verify_otp_succeeded: state.register_cco.verify_otp_succeeded,
    verify_otp_failed: state.register_cco.verify_otp_failed,
    get_charity_details_succeeded:
      state.register_cco.get_charity_details_succeeded,
    get_charity_details_failed: state.register_cco.get_charity_details_failed,
    charity_details: state.register_cco.charity_details,
    confirm_charity_details_succeeded:
      state.register_cco.confirm_charity_details_succeeded,
    confirm_charity_details_failed:
      state.register_cco.confirm_charity_details_failed,
    services: state.register_cco.services,
    save_services_succeeded: state.register_cco.save_services_succeeded,
    save_org_profile_succeeded: state.cco_profile.save_org_profile_succeeded,
    save_org_profile_failed: state.cco_profile.save_org_profile_succeeded,
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    registerCCO: (
      firstName: string,
      lastName: string,
      email: string,
      password: string,
      usergroup: string,
    ) => dispatch(registerCCO(firstName, lastName, email, password, usergroup)),
    resendOtp: (
      firstName: string,
      lastName: string,
      email: string,
      password: string
    ) => dispatch(resendOtp(firstName, lastName, email, password)),
    verifyOtp: (email: string, otp: string, usergroup: any) => dispatch(verifyOtp(email, otp, usergroup)),
    getCharityDetails: (email: string, charityNumber: string, id: any) =>
      dispatch(getCharityDetails(email, charityNumber, id)),
    getCharityType: () => dispatch(getCharityTypes()),
    confirmAddress: (email: string, address: any) =>
      dispatch(confirmAddress(email, address)),
    saveServices: (payload: any, charityNumber: any) =>
      dispatch(saveServices(payload, charityNumber)),
    activateUser: (payload: any) => dispatch(activateUser(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
