import { connect } from 'react-redux';

interface StateProps {
  email: string;
}

interface DispatchProps {}

interface OwnProps {
  Trigger: any;
  id: any;
  ccoAdminEmail: string;
  getSingleVolunteering: (id: any) => void;
}

export interface AddMeAsVolunteerProps
  extends StateProps,
    DispatchProps,
    OwnProps {}

const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => {
  return {
    email: state.cco_profile.cco_profile_info.email
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps);
