import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface CircularProgressProps {
  value: any;
  text: String;
  width: any;
  height: any;
  strokeWidth?: any;
}

const CircularProgress = (props: CircularProgressProps) => {
  const { value, width, height, strokeWidth = 14 } = props;
  return (
    <div style={{ width, height }}>
      <CircularProgressbar
        value={value}
        strokeWidth={strokeWidth}
        styles={buildStyles({
          pathColor: 'var(--highlight-primary)',
          trailColor: '#e1e2ea'
        })}
      />
    </div>
  );
};

export default CircularProgress;
