import React from 'react';
import ReactSelect from 'react-select';
import cx from 'classnames';
import './styles.scss';

const Select = (props: any) => {
  const { label, options, value, onChange, isMulti = false, className } = props;
  return (
    <div className="select-wrapper">
      {label && <div className="label">{label}</div>}
      <ReactSelect
        options={options}
        value={value}
        isMulti={isMulti}
        onChange={onChange}
        className={cx('v-select', className)}
      />
    </div>
  );
};

export default Select;
