import { connect } from 'react-redux';
import {
  loginCCO,
  sendMyPassword,
  setMyPassword
} from '../../../../services/auth/login/actions';

interface StateProps {
  loading: boolean;
  fetch_login_cco_succeeded: boolean;
  fetch_login_cco_failed: boolean;
  send_password_succeeded: boolean;
  send_password_failed: boolean;
  set_password_succeeded: boolean;
  set_password_failed: boolean;
}

interface DispatchProps {
  loginCCO: (email: string, password: string, pushTo: any) => void;
  sendPassword: (email: string) => void;
  setPassword: (email: string, otp: string, newPassword: string) => void;
}

export interface LoginCCOProps extends StateProps, DispatchProps {}

const mapStateToProps = (state: any): StateProps => {
  return {
    loading:
      state.login_cco.fetch_login_cco_loading ||
      state.login_cco.send_password_loading ||
      state.login_cco.set_password_loading,
    fetch_login_cco_succeeded: state.login_cco.fetch_login_cco_succeeded,
    fetch_login_cco_failed: state.login_cco.fetch_login_cco_failed,

    send_password_succeeded: state.login_cco.send_password_succeeded,
    send_password_failed: state.login_cco.send_password_failed,

    set_password_succeeded: state.login_cco.set_password_succeeded,
    set_password_failed: state.login_cco.set_password_failed
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    loginCCO: (email: string, password: string, pushTo: any) =>
      dispatch(loginCCO(email, password, pushTo)),
    sendPassword: (email: string) => dispatch(sendMyPassword(email)),
    setPassword: (email: string, otp: string, newPassword: string) =>
      dispatch(setMyPassword(email, otp, newPassword))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
