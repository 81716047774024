export interface LoginCCOState {
  fetch_login_cco_loading: boolean;
  fetch_login_cco_succeeded: boolean;
  fetch_login_cco_failed: boolean;

  send_password_loading: boolean;
  send_password_succeeded: boolean;
  send_password_failed: boolean;

  set_password_loading: boolean;
  set_password_succeeded: boolean;
  set_password_failed: boolean;
}

export const LOGIN_CCO_REQUESTED = "LOGIN_CCO_REQUESTED";
export const LOGIN_CCO_SUCCEEDED = "LOGIN_CCO_SUCCEEDED";
export const LOGIN_CCO_FAILED = "LOGIN_CCO_FAILED";

export const SEND_PASSWORD_REQUESTED = "SEND_PASSWORD_REQUESTED";
export const SEND_PASSWORD_SUCCEEDED = "SEND_PASSWORD_SUCCEEDED";
export const SEND_PASSWORD_FAILED = "SEND_PASSWORD_FAILED";

export const SET_PASSWORD_REQUESTED = "SET_PASSWORD_REQUESTED";
export const SET_PASSWORD_SUCCEEDED = "SET_PASSWORD_SUCCEEDED";
export const SET_PASSWORD_FAILED = "SET_PASSWORD_FAILED";