import * as types from './types';
import { notify } from '../../../components/Notifier/Notifier';
import axios from 'axios';
import { baseUrl } from '../../../config/api-config';
import { history } from '../../../routes';

export const registerCCO = (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  usergroup: string
) => (dispatch: any) => {
  let payload = { firstName, lastName, email, password, usergroup };
  dispatch({ type: types.REGISTER_CCO_REQUESTED });
  axios
    .post(baseUrl + '/hb/registerCCO', payload)
    .then(() => {
      dispatch({ type: types.REGISTER_CCO_SUCCEEDED });
    })
    .catch((error: any) => {
      dispatch({ type: types.REGISTER_CCO_FAILED });
      notify.error('Something went wrong', error?.response?.data);
    });
};

export const resendOtp = (
  firstName: string,
  lastName: string,
  email: string,
  password: string
) => (dispatch: any) => {
  let payload = { firstName, lastName, email, password };
  dispatch({ type: types.REGISTER_CCO_REQUESTED });
  axios
    .post(baseUrl + '/hb/registerCCO?resend=true', payload)
    .then(() => {
      dispatch({ type: types.REGISTER_CCO_SUCCEEDED });
    })
    .catch((error: any) => {
      dispatch({ type: types.REGISTER_CCO_FAILED });
      notify.error('Something went wrong', error?.response?.data);
    });
};

export const verifyOtp = (email: string, otp: string, usergroup: string) => (
  dispatch: any
) => {
  let payload = { email, otp };
  dispatch({ type: types.VERIFY_OTP_REQUESTED });
  axios
    .post(baseUrl + '/hb/verifyOTP', payload)
    .then(() => {
      dispatch({ type: types.VERIFY_OTP_SUCCEEDED });
      if (usergroup === 'hb_user') {
        notify.success(
          'Success',
          'Congratulations your account has been successfully created.'
        );
      }
    })
    .catch((error: any) => {
      dispatch({ type: types.VERIFY_OTP_FAILED });
    });
};

export const getCharityTypes = () => (dispatch: any) => {
  dispatch({ type: types.GET_CHARITY_TYPES_REQUESTED });
  axios
    .get(baseUrl + '/hb/charityTypeDD')
    .then((data: any) => {
      dispatch({ type: types.GET_CHARITY_TYPES_SUCCEEDED, payload: data });
    })
    .catch((error: any) => {
      dispatch({ type: types.GET_CHARITY_TYPES_FAILED });
      notify.error('Something went wrong', error.message);
    });
};

export const getCharityDetails = (
  email: string,
  charityNumber: string,
  id: any
) => (dispatch: any) => {
  let emailId = email !== '' ? email : localStorage.getItem('email');
  dispatch({ type: types.GET_CHARITY_DETAILS_REQUESTED });
  axios
    .get(baseUrl + `/hb/validateCharity?charityNumber=${charityNumber}`)
    .then(() => {
      dispatch({ type: types.GET_CHARITY_DETAILS_REQUESTED });
      axios
        .get(
          baseUrl +
            `/hb/getCharityDetails?charityNumber=${charityNumber}&id=${id}&flag=1&email=${emailId}`
        )
        .then((data: any) =>
          dispatch({ type: types.GET_CHARITY_DETAILS_SUCCEEDED, payload: data })
        )
        .catch((erroror: any) => {
          dispatch({ type: types.GET_CHARITY_DETAILS_FAILED });
          notify.error(
            'Something went wrong',
            'Please enter valid charity number.'
          );
        });
    })
    .catch(() => {
      dispatch({ type: types.GET_CHARITY_DETAILS_FAILED });
      notify.error('Error', 'Charity is already registered.');
    });
};

export const confirmAddress = (email: string, address: any) => (
  dispatch: any
) => {
  dispatch({ type: types.CONFIRM_CHARITY_DETAILS_REQUESTED });
  let emailId = email !== '' ? email : localStorage.getItem('email');
  axios
    .post(
      baseUrl +
        `/hb/confirmAddress?charityNumber=${address.RegisteredCharityNumber}&flag=2&email=${emailId}`,
      address
    )
    .then((data: any) => {
      dispatch({
        type: types.CONFIRM_CHARITY_DETAILS_SUCCEEDED,
        payload: data
      });
    })
    .catch((error: any) => {
      dispatch({ type: types.CONFIRM_CHARITY_DETAILS_FAILED });
      notify.error('Something went wrong', error?.response?.data);
    });
};

export const saveServices = (payload: any, RegisteredCharityNumber: any) => (
  dispatch: any
) => {
  dispatch({ type: types.SAVE_SERVICES_REQUESTED });
  axios
    .post(
      baseUrl +
        `/hb/serviceSave?charityNumber=${RegisteredCharityNumber}&flag=3&email=${
          payload.email !== '' ? payload.email : localStorage.getItem('email')
        }`,
      payload
    )
    .then((data: any) => {
      dispatch({ type: types.SAVE_SERVICES_SUCCEEDED, payload: data });
    })
    .catch((error: any) => {
      dispatch({ type: types.SAVE_SERVICES_FAILED });
      notify.error('Something went wrong', error?.response?.data);
    });
};

export const activateUser = (payload: any) => (dispatch: any) => {
  dispatch({ type: types.REGISTER_CCO_REQUESTED });
  axios
    .post(baseUrl + '/admin/activateUser', payload)
    .then((data: any) => {
      history.push('/');
      notify.success('Success', data.data.message);
      dispatch({ type: types.REGISTER_CCO_SUCCEEDED });
    })
    .catch((error: any) => {
      dispatch({ type: types.REGISTER_CCO_FAILED });
      notify.error('Something went wrong', error?.response?.data);
    });
};
