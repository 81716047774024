import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class PricingAndFees extends Component {
  render() {
    return (
      <div>
        <Header activeTab="about" />
        <div className="about-us-cont">
          <div className="about-us-header">
            <div className="title">PRICING AND FEES</div>
            <div className="block-cont">
              <div className="block" style={{ width: '100%' }}>
                <div className="content" style={{ width: '100%' }}>
                  HelpBritain exists to provide practical support to where it’s
                  needed most. We enable people to make donations of real goods
                  remotely online so that they can continue to provide practical
                  help & support even during lockdown scenarios, with reduced
                  mobility, or simply from a distance. These donations do not go
                  into central organisation coffers, they go to the front line
                  of people providing support to the vulnerable and needy.
                  <br />
                  <br />
                  HelpBritain does not charge any fees to charities to use the
                  platform and receive donations. Unlike some other platforms,
                  there are no subscription charges and we take no percentage of
                  donations. We do not charge users to make donations, however
                  we gratefully accept voluntary gratuities towards keeping the
                  site running from people if they wish to support us. Whilst
                  HelpBritain in its infancy is still run by Ovyo Ltd until a
                  formal charity can be set up, all gratuities go to a separate
                  account specifically for HelpBritain.
                  <br />
                  <br />
                  It costs us from 35p to process a donation of Select vouchers.
                  This breaks down as 2.9% (15p) + 20p on the minimum donation
                  level of £5. It costs us 49p to process a £10 donation and
                  £1.65 to process our highest donation level of £50 of Select
                  vouchers.
                  <br />
                  <br />
                  Additionally to payment processing, we are busy monitoring the
                  platform, helping charities reach donors on social media and
                  designing updates. We would like to do even more, such as
                  enabling videos, onboarding more retail partners, providing
                  charities with deeper analytics tools and making it easier to
                  search volunteering opportunities based on certifications &
                  skills.
                  <br />
                  <br />
                  There are no fees for HelpBritain when Amazon donations are
                  made.
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default PricingAndFees;
