import React, { Component } from "react";
import "../CCO-Registration/AuthRegister.scss";
import Header from "../../../../components/Header/Header";
import ScrollAnimation from "react-animate-on-scroll";
import Loader from "../../../../components/Loader/Loader";
import RegisterContainer, {
  RegisterCCOProps,
} from "../../container/CCO-Registration/AuthRegisterContainer";
import {
  validEmailRegex,
  validatePassword,
} from "../CCO-Registration/CCORegister";
import { FormControl, Button } from "react-bootstrap";

class UserActivate extends Component<RegisterCCOProps> {
  state = {
    userEmail: "",
    password: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
    userType: "CCO_USER",
    cco: "",
    confirmPassword: "",
    passwordType: "password",
    errors: {
      fullName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({
      userEmail: params.user_email,
      cco: params.cco,
    });
  }

  handleInputChange = (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "fullName":
        errors.fullName = value.length === 0 ? "Full Name required!" : "";
        break;
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Email is not valid!";
        break;
      case "password":
        errors.password = validatePassword.test(value)
          ? ""
          : "Password must contain at least 1 uppercase & 1 lowercase letter, 1 number and 1 special character, and be a minimum of 8 characters long.";
        break;
      default:
        break;
    }
    this.setState({
      errors,
      [event.target.name]: event.target.value,
    });
  };

  setPasswordType = () => {
    this.setState({
      passwordType:
        this.state.passwordType === "password"
          ? "text"
          : this.state.passwordType === "text"
          ? "password"
          : "text",
    });
  };

  activateUser = (ev: any) => {
    ev.preventDefault();
    const {
      firstName,
      lastName,
      userEmail,
      password,
      cco,
      userType,
      contactNumber,
    } = this.state;

    let payload = {
      firstName,
      lastName,
      userEmail,
      password,
      cco : cco,
      userType,
      contactNumber,
    };
    this.props.activateUser(payload);
  };

  render() {
    const {
      firstName,
      lastName,
      password,
      userEmail,
      errors,
      confirmPassword,
      contactNumber,
    } = this.state;

    let disableRegisterBtn =
      firstName === "" ||
      lastName === "" ||
      password === "" ||
      userEmail === "" ||
      errors.email.length > 0 ||
      errors.password.length > 0 ||
      this.state.password !== this.state.confirmPassword;

    const { loading } = this.props;
    return (
      <div>
        {loading && <Loader />}
        <Header activeTab="register" />
        <ScrollAnimation animateIn="fadeIn" delay={100}>
          <div className="register-cont">
            <div className="register-cont-block">
              <div className="part-1 ">
                <div className="title">Register</div>
              </div>
              <div className="part-2">
                <div className="part-2-header">
                  <div className="title"> Welcome to Help Britain</div>
                  <div className="sub-title">
                    Let’s get you all set up so you can verify your organisation
                    and begin setting up campaigns
                  </div>
                </div>

                <form
                  className="form-cont"
                  onSubmit={(ev: any) => this.activateUser(ev)}
                >
                  <div className="name-form-cont">
                    <div className="name-form-group">
                      <label className="label">First Name *</label>
                      <FormControl
                        type="text"
                        value={firstName}
                        name="firstName"
                        onChange={this.handleInputChange}
                      />
                      <label className="error-msg">{errors.fullName}</label>
                    </div>

                    <div className="name-form-group">
                      <label className="label">Last Name *</label>
                      <FormControl
                        type="text"
                        value={lastName}
                        name="lastName"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="email-form-cont">
                    <label className="label">Email *</label>
                    <FormControl
                      type="text"
                      value={userEmail}
                      name="email"
                      onChange={this.handleInputChange}
                      readOnly
                      disabled
                    />
                  </div>
                  <label className="error-msg"></label>

                  <div className="email-form-cont">
                    <label className="label">Phone Number</label>
                    <FormControl
                      type="text"
                      value={contactNumber}
                      name="contactNumber"
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <label className="error-msg"></label>

                  <div className="email-form-cont">
                    <label className="label">Choose a password *</label>
                    <FormControl
                      type="password"
                      value={password}
                      name="password"
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <label className="error-msg">
                    {password.length > 0 ? errors.password : ""}
                  </label>
                  <div className="email-form-cont">
                    <label className="label">Reconfirm the password *</label>
                    <FormControl
                      type="password"
                      value={confirmPassword}
                      name="confirmPassword"
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <label className="error-msg">
                    {confirmPassword.length > 0 &&
                    this.state.password !== this.state.confirmPassword
                      ? "Your password and confirm password do not match."
                      : ""}
                  </label>
                  <Button
                    type="submit"
                    className={
                      disableRegisterBtn ? "btn-disable btn" : "btn-primary btn"
                    }
                    disabled={disableRegisterBtn}
                  >
                    <span>Submit</span>
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    );
  }
}

export default RegisterContainer(UserActivate);
