import * as types from './types';

const initialState: types.LoginCCOState = {
  fetch_login_cco_loading: false,
  fetch_login_cco_succeeded: false,
  fetch_login_cco_failed: false,

  send_password_loading: false,
  send_password_succeeded: false,
  send_password_failed: false,

  set_password_loading: false,
  set_password_succeeded: false,
  set_password_failed: false
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.LOGIN_CCO_REQUESTED:
      return {
        ...state,
        fetch_login_cco_loading: true,
        fetch_login_cco_failed: false,
        fetch_login_cco_succeeded: false
      };
    case types.LOGIN_CCO_SUCCEEDED:
      return {
        ...state,
        fetch_login_cco_loading: false,
        fetch_login_cco_succeeded: true,
        fetch_login_cco_failed: false
      };
    case types.LOGIN_CCO_FAILED:
      return {
        ...state,
        fetch_login_cco_loading: false,
        fetch_login_cco_succeeded: false,
        fetch_login_cco_failed: true
      };

    case types.SEND_PASSWORD_REQUESTED:
      return {
        ...state,
        send_password_loading: true,
        send_password_failed: false,
        send_password_succeeded: false
      };
    case types.SEND_PASSWORD_SUCCEEDED:
      return {
        ...state,
        send_password_loading: false,
        send_password_succeeded: true,
        send_password_failed: false
      };
    case types.SEND_PASSWORD_FAILED:
      return {
        ...state,
        send_password_loading: false,
        send_password_succeeded: false,
        send_password_failed: true
      };

    case types.SET_PASSWORD_REQUESTED:
      return {
        ...state,
        set_password_loading: true,
        set_password_failed: false,
        set_password_succeeded: false
      };
    case types.SET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        set_password_loading: false,
        set_password_succeeded: true,
        set_password_failed: false
      };
    case types.SET_PASSWORD_FAILED:
      return {
        ...state,
        set_password_loading: false,
        set_password_succeeded: false,
        set_password_failed: true
      };

    default:
      return state;
  }
};
