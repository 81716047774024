import React, { Component } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { MainRoutes } from "./main";
import store from "../config/store";
import ScrollTop from "./ScrollTop";


export const history = createBrowserHistory();

class AppRoutes extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <ScrollTop />
          <MainRoutes />
        </Router>
      </Provider>
    );
  }
}

export default AppRoutes;
