import * as types from './types';

const initialState: types.SearchState = {
  searchOpen: false,
  latitude: null,
  longitude: null,
  location: localStorage.getItem('__location') || '',
  search_org_loading: null,
  search_org_succeeded: null,
  search_org_failed: null,
  search_org_info: []
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.TOGGLE_SEARCH:
      return {
        ...state,
        searchOpen: !state.searchOpen
      };
    case types.SET_LOCATION:
      return {
        ...state,
        location: action.payload
      };
    case types.SET_LAT_LONG:
      return {
        ...state,
        longitude: action.payload.longitude,
        latitude: action.payload.latitude
      };

    case types.SEARCH_ORG_REQUESTED:
      return {
        ...state,
        search_org_loading: true,
        search_org_failed: false,
        search_org_succeeded: false,
        search_org_info: []
      };
    case types.SEARCH_ORG_SUCCEEDED:
      return {
        ...state,
        search_org_loading: false,
        search_org_succeeded: true,
        search_org_failed: false,
        search_org_info: action.payload.data
      };
    case types.SEARCH_ORG_FAILED:
      return {
        ...state,
        search_org_loading: false,
        search_org_succeeded: false,
        search_org_failed: true
      };

    default:
      return state;
  }
};
