import React, { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import QueryString from 'query-string';
import Loader from '../../../components/Loader/Loader';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import { VolunteeringListProps } from '../containers/VolunteeringList';
import VolunteeringListContainer from '../containers/VolunteeringList';
import '../styles/styles.scss';
import VolunteeringCard from './VolunteeringCard';
import Select from '../../../components/Select';
import noResultsFound from '../../../images/no-resultfound.png';

interface FiltersProps {
  title: String;
  list: any[];
  onFilterClick: Function;
}

interface QueryString {
  [key: string]: any;
}

const StatusOption = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Active',
    value: 'ACTIVE'
  },
  {
    label: 'Inactive',
    value: 'ARCHIVED'
  }
];

class VolunteeringList extends Component<
  VolunteeringListProps & RouteComponentProps<{}>
> {
  state = {
    isFiltered: false,
    filterInfo: '',
    showAll: false,
    volStatus: {
      label: 'All',
      value: 'all'
    }
  };

  componentDidMount() {
    const {
      location: { search },
      match: { url },
      getCampaigns,
      getFilteredCampaigns,
      current_location,
      getVolunteeringListAdmin
    } = this.props;
    const queryParams = QueryString.parse(search);
    let is_cco_loggedin: any = localStorage.getItem('__is_loggedIn') || false;

    if (is_cco_loggedin) {
      getVolunteeringListAdmin();
    }

    if(!is_cco_loggedin) {
      getCampaigns();
    }

    if (Object.keys(queryParams).length <= 0) {
      this.props.history.push(`${url}?category=all`);
    }

    if (queryParams.category !== undefined && queryParams.category !== 'all') {
      getFilteredCampaigns(queryParams.category, current_location);
    }
  }

  componentDidUpdate(prevProps: any) {
    const {
      match: { url },
      location: { search }
    } = this.props;
    const queryParams = QueryString.parse(search);
    if (Object.keys(queryParams).length <= 0) {
      this.props.history.push(`${url}?category=all`);
    }

    // if (
    //   this.props.volunteering &&
    //   this.props.volunteering.length !== prevProps.volunteering.length
    // ) {
    //   getCampaigns();
    // }
  }

  getCategoryFilterList = () => {
    const {
      categories,
      location: { search }
    } = this.props;
    const queryParams = QueryString.parse(search);
    const list =
      categories !== undefined
        ? [
            {
              label: 'All Categories',
              value: 'all'
            },
            ...categories.map((c: any) => ({
              label: c,
              value: c
            }))
          ]
        : [
            {
              label: 'All Categories',
              value: 'all'
            }
          ];

    return {
      list,
      active: list.filter((l) => l.value === queryParams.category).shift()
    };
  };

  handleFilter = (key: String, option: any) => {
    const {
      location: { search },
      match: { url },
      history,
      getCampaigns,
      getFilteredCampaigns,
      current_location
    } = this.props;
    let queryParams: QueryString = QueryString.parse(search);
    queryParams = {
      ...queryParams,
      // @ts-ignore
      [key]: option.value
    };
    history.push(`${url}?${QueryString.stringify(queryParams)}`);
    if (queryParams.category === 'all') {
      getCampaigns();
    } else {
      getFilteredCampaigns(option.value, current_location);
    }
  };

  goToCreate = () => {
    this.props.history.push('/volunteering/create');
  };

  handleListShow = (option: any) => {
    this.setState(
      {
        showAll: option.value === 'all'
      },
      () => {
        this.fetchVolunteeringList();
      }
    );
  };

  fetchVolunteeringList = () => {
    const { getCampaigns, getVolunteeringListAdmin } = this.props;
    const { showAll } = this.state;
    const is_logged_in = localStorage.getItem('__is_loggedIn');

    if (!showAll && is_logged_in) {
      getVolunteeringListAdmin();
    } else {
      getCampaigns();
    }
  };

  getVolunteeringList = (is_logged_in: any) => {
    const { volunteering } = this.props;
    const {
      showAll,
      volStatus: { value }
    } = this.state;
    if (is_logged_in) {
      if (showAll) {
        return volunteering;
      } else {
        return volunteering.length > 0
          ? volunteering
              ?.filter((v: any) =>
                value === 'all'
                  ? v
                  : v?.vol_status?.toUpperCase() === value.toUpperCase()
              )
              .filter((vc: any) => vc.cco === this.props.profileEmail)
          : [];
      }
    } else {
      return volunteering.length > 0
        ? volunteering?.filter(
            (v: any) => v?.vol_status?.toUpperCase() === 'ACTIVE'
          )
        : [];
    }
  };
  handleStatus = (option: any) => {
    this.setState({ volStatus: option });
  };

  render() {
    const { loading, profileEmail } = this.props;
    const { showAll } = this.state;
    const option = showAll
      ? {
          label: 'Show All',
          value: 'all'
        }
      : {
          label: 'Show Mine',
          value: 'mine'
        };
    const is_logged_in = localStorage.getItem('__is_loggedIn');
    const volunteeringList = this.getVolunteeringList(is_logged_in);
    return (
      <Fragment>
        {loading && <Loader />}
        <Header activeTab="volunteering" />
        <div className="vl">
          <div className="vl__header">
            <div className="vl__header-top">
              <h4 className="vl__header--title">Volunteering</h4>

              {is_logged_in && (
                <div className="vl__header--right">
                  <Select
                    options={[
                      {
                        label: 'Show Mine',
                        value: 'mine'
                      },
                      {
                        label: 'Show All',
                        value: 'all'
                      }
                    ]}
                    value={option}
                    onChange={this.handleListShow}
                    className="vl__category v-select-small"
                  />
                  <button
                    onClick={this.goToCreate}
                    className="vl__header--create"
                  >
                    Create
                  </button>
                </div>
              )}
            </div>
            <div className="vl__filter">
              <Select
                label="CATEGORY"
                options={this.getCategoryFilterList().list}
                value={this.getCategoryFilterList().active}
                onChange={(option: any) =>
                  this.handleFilter('category', option)
                }
                className="vl__category"
              />
              {!showAll && is_logged_in && (
                <Select
                  label="Status"
                  options={StatusOption}
                  value={this.state.volStatus}
                  onChange={this.handleStatus}
                  className="vl__volStatus"
                />
              )}
              <div className="vl__charity" />
            </div>
          </div>
          <div className="vl__body">
            <div className="vl__content">
              {volunteeringList
                .filter((ds: any) =>
                  showAll ? ds.vol_status?.toUpperCase() === 'ACTIVE' : ds
                )
                .map((v: any, index: number) => (
                  <VolunteeringCard
                    key={index}
                    data={v}
                    showDropdown={v.cco === profileEmail && !showAll}
                    handleStatus={this.props.changeVolunteeringStatus}
                  />
                ))}
              {!loading && !volunteeringList.length && (
                <div className="all-causes__empty">
                  <img
                    src={noResultsFound}
                    alt="no results"
                    className="all-causes__empty--image"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {!loading && <Footer />}
      </Fragment>
    );
  }
}

export default VolunteeringListContainer(withRouter(VolunteeringList));
