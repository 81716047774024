import * as types from "./types";

const initialState: types.WishlistState = {

  fetch_charity_loading: false,
  fetch_charity_succeeded: false,
  fetch_charity_failed: false,
  charity_info: [],

  fetch_campaign_loading: false,
  fetch_campaign_succeeded: false,
  fetch_campaign_failed: false,
  campaign_info: [],

  fetch_wishlist_loading: false,
  fetch_wishlist_succeeded: false,
  fetch_wishlist_failed: false,
  wishlist_info: [],

  publish_loading: false,
  publish_succeeded: false,
  publish_failed: false,
  publish_info: {},
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.CHARITY_REQUESTED:
      return {
        ...state,
        fetch_charity_loading: true,
        fetch_charity_failed: false,
        fetch_charity_succeeded: false,
      };
    case types.CHARITY_SUCCEEDED:
      return {
        ...state,
        fetch_charity_loading: false,
        fetch_charity_succeeded: true,
        fetch_charity_failed: false,
        charity_info: action.payload.data,
      };
    case types.CHARITY_FAILED:
      return {
        ...state,
        fetch_charity_loading: false,
        fetch_charity_succeeded: false,
        fetch_charity_failed: true,
      };

    case types.CAMPAIGNS_REQUESTED:
      return {
        ...state,
        fetch_campaign_loading: true,
        fetch_campaign_failed: false,
        fetch_campaign_succeeded: false,
      };
    case types.CAMPAIGNS_SUCCEEDED:
      return {
        ...state,
        fetch_campaign_loading: false,
        fetch_campaign_succeeded: true,
        fetch_campaign_failed: false,
        campaign_info: action.payload.data,
      };
    case types.CAMPAIGNS_FAILED:
      return {
        ...state,
        fetch_campaign_loading: false,
        fetch_campaign_succeeded: false,
        fetch_campaign_failed: true,
      };

    case types.WISHLIST_REQUESTED:
      return {
        ...state,
        fetch_wishlist_loading: true,
        fetch_wishlist_failed: false,
        fetch_wishlist_succeeded: false,
      };
    case types.WISHLIST_SUCCEEDED:
      return {
        ...state,
        fetch_wishlist_loading: false,
        fetch_wishlist_succeeded: true,
        fetch_wishlist_failed: false,
        wishlist_info: action.payload.data,
      };
    case types.WISHLIST_FAILED:
      return {
        ...state,
        fetch_wishlist_loading: false,
        fetch_wishlist_succeeded: false,
        fetch_wishlist_failed: true,
      };

    case types.PUBLISH_REQUESTED:
      return {
        ...state,
        publish_loading: true,
        publish_failed: false,
        publish_succeeded: false,
      };
    case types.PUBLISH_SUCCEEDED:
      return {
        ...state,
        publish_loading: false,
        publish_succeeded: true,
        publish_failed: false,
        publish_info: action.payload.data,
      };
    case types.PUBLISH_FAILED:
      return {
        ...state,
        publish_loading: false,
        publish_succeeded: false,
        publish_failed: true,
      };

    default:
      return state;
  }
};
