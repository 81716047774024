import React, { Component } from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default class PuffLoader extends Component {
  render() {
    return (
      <Loader
        type="Oval"
        color="#fc4ae8"
        height={20}
        width={20}
      />
    );
  }
}
