import React, { Component } from 'react';
import cx from 'classnames';
import SearchContainer, { SearchProps } from '../containers/SearchContainer';

import '../styles/search.scss';

import noResultsFound from '../../../images/no-resultfound.png';
import ResultCard, { ResultCardSkeleton } from './ResultCard';
import { RouteComponentProps, withRouter } from 'react-router';

class Search extends Component<SearchProps & RouteComponentProps> {
  state = {
    search: '',
    city: {
      value: '',
      label: ''
    }
  };

  componentDidMount(): void {
    const { locations } = this.props;
    this.setState({
      city: { value: locations[0], label: locations[0] }
    });
  }

  componentDidUpdate(prevProps: SearchProps) {
    if (
      this.props.search_org_succeeded &&
      this.props.search_org_succeeded !== prevProps.search_org_succeeded
    ) {
      this.setState({
        searchItem: this.props.search_org_info
      });
    }
  }

  componentWillUnmount() {
    document.getElementsByTagName('body')[0].style.overflowY = 'auto';
  }

  render() {
    const {
      toggleSearch,
      search_org_info,
      history,
      search_org_loading,
      activeTab
    } = this.props;
    return (
      <div className="v-search">
        {/*{search_org_loading && <Loader />}*/}
        <div className="v-search__body">
          <div className="v-search__body-heading">
            <button
              className={cx('v-search__tabs', {
                'v-search__tabs--active': activeTab === 'campaign'
              })}
              onClick={(e) => this.props.onTabClick(e, 'campaign')}
            >
              Wishlists
            </button>
            <button
              className={cx('v-search__tabs', {
                'v-search__tabs--active': activeTab === 'charity'
              })}
              onClick={(e) => this.props.onTabClick(e, 'charity')}
            >
              Organisations
            </button>
          </div>
          {search_org_info.length > 0 && (
            <>
              {activeTab === 'campaign' ? (
                <div className="v-search__results">
                  {search_org_info.map(
                    (s: any, i: number) =>
                      s.campaigns &&
                      s.campaigns
                        .filter((d: any) => d.status === 'Active')
                        .map((c: any) => (
                          <ResultCard
                            key={c.campaignNameUrl}
                            bannerImage={c.image}
                            name={c.campaignName}
                            address={s.Line3}
                            description={c.description}
                            url={`/${s.charityNameUrl}/campaigns/${c.campaignNameUrl}`}
                            onClick={() => {
                              toggleSearch();
                              history.push(
                                `/${s.charityNameUrl}/campaigns/${c.campaignNameUrl}`
                              );
                            }}
                          />
                        ))
                  )}
                </div>
              ) : (
                <div className="v-search__results">
                  {search_org_info.map((s: any, i: number) => (
                    <ResultCard
                      key={i}
                      bannerImage={s.banner}
                      name={s.CharityName}
                      address={s.Line3}
                      description={s.description}
                      url={`/charity/${s.charityNameUrl}`}
                      onClick={() => {
                        toggleSearch();
                        history.push(`/charity/${s.charityNameUrl}`);
                      }}
                    />
                  ))}
                </div>
              )}
            </>
          )}

          {search_org_loading && (
            <div className="v-search__results">
              {[1, 2, 3].map((a: any) => (
                <ResultCardSkeleton key={a} />
              ))}
            </div>
          )}

          {!search_org_info.length && !search_org_loading && (
            <div className="v-search__no-results">
              <img src={noResultsFound} alt="" className="imh" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SearchContainer(withRouter(Search));
