import React, { Component } from 'react';
import './MyDonations.scss';
import ProfileContainer, {
  CCOProfileProps
} from '../container/ProfileContainer';

class MyDonations extends Component<CCOProfileProps> {
  render() {
    return (
      <div className="my-donations-cont">
        <div className="header">My Donations</div>
        <div className="my-donation-items">
          {this.props.profile_info?.metaData?.L2S === undefined && (
            <div className="no">
              No donation yet ,<a href="/all-causes"> Donate</a>{' '}
            </div>
          )}
          {this.props.profile_info?.metaData?.L2S?.map((ls: any, i: any) => (
            <div className="cause-card" key={i}>
              <div className="amount-cont-wrapper">
                <div className="amount-cont">£ {ls.amount_total / 100}</div>
              </div>
              <div className="cause-card__title">{ls.metadata.campaign}</div>
              <div className="cause-card__organization">
                {ls.metadata.charity}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ProfileContainer(MyDonations);
