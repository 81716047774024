import * as types from './types';
import axios from 'axios';
import { notify } from '../../components/Notifier/Notifier';
import { baseUrl } from '../../config/api-config';

export const getVolunteeringList = () => (dispatch: any) => {
  dispatch({ type: types.FETCH_VOLUNTEERING_LIST_REQUESTED });
  axios
    .get(baseUrl + `/admin/getAllVolunteerings?status=ACTIVE`)
    .then(({ data }: any) =>
      dispatch({
        type: types.FETCH_VOLUNTEERING_LIST_SUCCEEDED,
        payload: data.data
      })
    )
    .catch((error: any) => {
      dispatch({ type: types.FETCH_VOLUNTEERING_LIST_FAILED });
      notify.error('Something went wrong', error?.response?.data);
    });
};

export const changeVolunteeringStatus = (params: any) => (dispatch: any) => {
  dispatch({ type: types.FETCH_VOLUNTEERING_LIST_REQUESTED });
  axios
    .post(baseUrl + `/admin/updateVolunteeringStatus`, params)
    .then(({ data }: any) => {
      dispatch(getAdminVolunteeringList());
      notify.success('Success', 'Status updated successfully');
    })
    .catch((error: any) => {
      notify.error('Something went wrong', error?.response?.data);
    });
};

export const getAdminVolunteeringList = () => (dispatch: any) => {
  dispatch({ type: types.FETCH_VOLUNTEERING_LIST_REQUESTED });
  axios
    .get(
      baseUrl +
        `/admin/getAllVolunteeringsByCCO?cco=${localStorage.getItem('email')}`
    )
    .then(({ data }: any) =>
      dispatch({
        type: types.FETCH_VOLUNTEERING_LIST_SUCCEEDED,
        payload: data.data
      })
    )
    .catch((error: any) => {
      dispatch({ type: types.FETCH_VOLUNTEERING_LIST_FAILED });
      notify.error('Something went wrong', error?.response?.data);
    });
};

export const getSingleVolunteering = (id: string) => (dispatch: any) => {
  dispatch({ type: types.FETCH_VOLUNTEERING_LIST_REQUESTED });
  axios
    .get(baseUrl + `/hbAdmin/getVolunteerId?id=${id}`)
    .then((data: any) =>
      dispatch({
        type: types.FETCH_VOLUNTEERING_LIST_SUCCEEDED,
        payload: data.data
      })
    )
    .catch((error: any) => {
      dispatch({ type: types.FETCH_VOLUNTEERING_LIST_FAILED });
      notify.error('Something went wrong', error?.response?.data);
    });
};
