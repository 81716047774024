import React, { Component, Fragment } from 'react';
import './ManageOrganisations.scss';
import { Table } from 'react-bootstrap';
import Loader from '../../../components/Loader/Loader';
import OrganisationContainer, {
  AdminOrgProps
} from '../container/OrganisationContainer';
import swal from 'sweetalert';

class ManageVolunteers extends Component<AdminOrgProps> {
  state = {
    show_dropdown_index: null,
    openFeaturedConfirmModal: false,
    selectedCampaignName: ''
  };

  componentDidMount() {
    this.props.getAllOrganisations();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  myRef: any = React.createRef();

  setDropDown = (i: any, selectedCampaignName: any) => {
    const { show_dropdown_index } = this.state;
    this.setState({
      show_dropdown_index: show_dropdown_index === i ? null : i,
      selectedCampaignName
    });
  };

  handleClickOutside = (e: any) => {
    if (this.myRef.current !== null && !this.myRef.current.contains(e.target)) {
      this.setDropDown(null, '');
    }
  };

  setStatus = (email: any, campaignName: any, params: any) => {
    this.setState(
      {
        show_dropdown_index: null
      },
      () => {
        this.props.changeCampaignStatus(
          email,
          'volunteering',
          campaignName,
          params
        );
      }
    );
  };

  opneFeaturedOrgModal = (email: any, d: any) => {
    swal({
      title: `Are you sure?`,
      text: `You want to set ${d.campaignName.toLowerCase()} \n as featured campaign.`,
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancel', 'Confirm']
    })
      .then((result: any) => {
        if (result !== null) {
          this.props.setFeatured(
            email,
            'campaign',
            d.campaignName,
            d.featured === 'false' ? 'true' : 'false'
          );
        }
      })
      .catch(() => {
        console.log('close');
      });
  };

  render() {
    const { show_dropdown_index, selectedCampaignName } = this.state;
    return (
      <Fragment>
        {(this.props.organisationsInfo === '' || this.props.loading) && (
          <Loader />
        )}
        {this.props.organisationsInfo !== '' && (
          <div className="organisatoins-cont">
            {/* <div className="manage-heading">Manage Volunteer Request</div> */}
            <div className="org-cont">
              <div
                className="table-cont"
                style={{ height: 'calc(100vh - 10rem)' }}
              >
                {this.props.organisationsInfo
                  .filter((ds: any) => ds.flag?.toString() === '4')
                  .filter((ds: any) => ds.volunteering !== undefined).length >
                0 ? (
                  <Table striped>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Registered Address</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.organisationsInfo
                        .filter((ds: any) => ds.flag?.toString() === '4')
                        .filter((ds: any) => ds.volunteering !== undefined)
                        .map((d: any) =>
                          d.volunteering.map((dss: any, i: any) => (
                            <tr key={i}>
                              <td></td>
                              <td>
                                <div className="orga-cont">
                                  <a
                                    href={`/volunteering/${dss.id}`}
                                    className="text-1"
                                  >
                                    {dss.name}
                                  </a>
                                  <div>
                                    {dss.numberOfPeople} Needed{' '}
                                    {dss?.volunteers?.length} Signed up
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="center-cont">{dss.address}</div>
                              </td>
                              <td>
                                <div className="center-cont status-cont">
                                  <i
                                    className={
                                      dss.vol_status?.toUpperCase() === 'ACTIVE'
                                        ? 'fa fa-circle ap'
                                        : 'fa fa-circle ac'
                                    }
                                  ></i>
                                  <span>
                                    {dss.vol_status === 'Archived'
                                      ? 'Inactive'
                                      : dss.vol_status}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div
                                  className="table-dropdown-cont"
                                  ref={this.myRef}
                                >
                                  <div
                                    className="table-dropdown"
                                    onClick={() =>
                                      this.setDropDown(i, dss.name)
                                    }
                                  >
                                    <i className="fa fa-angle-down" />
                                  </div>
                                  {show_dropdown_index === i &&
                                    dss.name === selectedCampaignName && (
                                      <DropDown
                                        onClick={(status: any) =>
                                          this.setStatus(d.id, dss.name, status)
                                        }
                                        myref={this.myRef}
                                        status={dss.vol_status}
                                      />
                                    )}
                                </div>
                              </td>
                            </tr>
                          ))
                        )}
                    </tbody>
                  </Table>
                ) : (
                  <div className="no-result-found">
                    Sorry, we couldn't find any volunteers.
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const DropDown = ({ onClick, myref, status }: any) => {
  return (
    <div className="o-dropdown" ref={myref}>
      {status === 'Active' ? (
        <div className="o-dropdown__item" onClick={() => onClick('Archived')}>
          Inactive
        </div>
      ) : (
        <div className="o-dropdown__item" onClick={() => onClick('Active')}>
          Active
        </div>
      )}
    </div>
  );
};

export default OrganisationContainer(ManageVolunteers);
