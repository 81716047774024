export interface RegisterCCOState {
  fetch_register_cco_loading: boolean;
  fetch_register_cco_succeeded: boolean;
  fetch_register_cco_failed: boolean;

  verify_otp_loading: boolean;
  verify_otp_succeeded: boolean;
  verify_otp_failed: boolean;

  get_charity_types_loading: boolean;
  get_charity_types_succeeded: boolean;
  get_charity_types_failed: boolean;
  charity_types: any,

  get_charity_details_loading: boolean;
  get_charity_details_succeeded: boolean;
  get_charity_details_failed: boolean;
  charity_details: any,

  confirm_charity_details_loading: boolean;
  confirm_charity_details_succeeded: boolean;
  confirm_charity_details_failed: boolean;
  services: any;

  save_services_loading: boolean;
  save_services_succeeded: boolean;
  save_services_failed: boolean;
}

export interface IHaveRegisterCCOState {
    register_cco: RegisterCCOState;
  }

export const REGISTER_CCO_REQUESTED = "REGISTER_CCO_REQUESTED";
export const REGISTER_CCO_SUCCEEDED = "REGISTER_CCO_SUCCEEDED";
export const REGISTER_CCO_FAILED = "REGISTER_CCO_FAILED";

export const VERIFY_OTP_REQUESTED = "VERIFY_OTP_REQUESTED";
export const VERIFY_OTP_SUCCEEDED = "VERIFY_OTP_SUCCEEDED";
export const VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED";

export const GET_CHARITY_TYPES_REQUESTED = "GET_CHARITY_TYPES_REQUESTED";
export const GET_CHARITY_TYPES_SUCCEEDED = "GET_CHARITY_TYPES_SUCCEEDED";
export const GET_CHARITY_TYPES_FAILED = "GET_CHARITY_TYPES_FAILED";

export const GET_CHARITY_DETAILS_REQUESTED = "GET_CHARITY_DETAILS_REQUESTED";
export const GET_CHARITY_DETAILS_SUCCEEDED = "GET_CHARITY_DETAILS_SUCCEEDED";
export const GET_CHARITY_DETAILS_FAILED = "GET_CHARITY_DETAILS_FAILED";

export const CONFIRM_CHARITY_DETAILS_REQUESTED = "CONFIRM_CHARITY_DETAILS_REQUESTED";
export const CONFIRM_CHARITY_DETAILS_SUCCEEDED = "CONFIRM_CHARITY_DETAILS_SUCCEEDED";
export const CONFIRM_CHARITY_DETAILS_FAILED = "CONFIRM_CHARITY_DETAILS_FAILED";

export const SAVE_SERVICES_REQUESTED = "SAVE_SERVICES_REQUESTED";
export const SAVE_SERVICES_SUCCEEDED = "SAVE_SERVICES_SUCCEEDED";
export const SAVE_SERVICES_FAILED = "SAVE_SERVICES_FAILED";