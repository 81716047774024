export interface SearchState {
  searchOpen: boolean;
  location: any;
  search_org_info: any[];
  search_org_loading: any;
  search_org_failed: any;
  search_org_succeeded: any;
  latitude: any;
  longitude: any;
}

export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';

export const SET_LOCATION = 'SET_LOCATION';

export const SET_LAT_LONG = 'SET_LAT_LONG';

export const SEARCH_ORG_REQUESTED = 'SEARCH_ORG_REQUESTED';
export const SEARCH_ORG_SUCCEEDED = 'SEARCH_ORG_SUCCEEDED';
export const SEARCH_ORG_FAILED = 'SEARCH_ORG_FAILED';
