import { connect } from 'react-redux';
import {
  getProfile,
  saveOrganizationProfile,
  getOrganisationProfile,
  updateOrganisationProfile,
  inviteUser,
  getCCOUsers,
  deActivateUser,
  getAllFilters,
  getAllLocations,
  userLogout,
  getUserProfile,
  updateOrganisationProfileNew
} from '../../../services/CCOProfile/actions';
import {
  toggleSearch,
  setLocation,
  setLatLong
} from '../../../services/Search/actions';
import { getHomeCards } from '../../../services/Home/actions';
import {
  sendMyPasswordToUpdate,
  setMyPassword
} from '../../../services/auth/login/actions';

interface StateProps {
  fetch_login_cco_succeeded: boolean;
  loading: boolean;
  fetch_profile_succeeded: boolean;
  fetch_profile_failed: boolean;
  profile_info: any;
  save_org_profile_succeeded: boolean;
  save_org_profile_failed: boolean;
  invite_user_succeeded: boolean;
  invite_user_failed: boolean;
  team_user_info: any;
  filters_succeeded: boolean;
  filters_failed: boolean;
  filters_data: any;
  locations_succeeded: boolean;
  locations_failed: boolean;
  locations: any;
  location: any;
  campaignInfo: any;
  send_password_succeeded: boolean;
  send_password_failed: boolean;
  set_password_succeeded: boolean;
  set_password_failed: boolean;
}

interface DispatchProps {
  getUserProfile: () => void;
  getProfile: () => void;
  saveOrgProfile: (payload: any) => void;
  getOrganisationProfile: () => void;
  updateOrgProfile: (payload: any) => void;
  updateOrgProfileNew: (
    payload: any,
    flag: string,
    isReApproval: boolean
  ) => void;
  inviteUser: (payload: any) => void;
  getCCOUsers: () => void;
  deActivateUser: (body: any) => void;
  getAllFilters: () => void;
  getAllLoacations: (lat: any, long: any) => void;
  toggleSearch: () => void;
  setLocation: (location: string) => void;
  userLogout: () => void;
  setLatLong: (payload: any) => void;
  getCampaigns: () => void;
  sendPassword: (email: string) => void;
  setPassword: (email: string, otp: string, newPassword: string) => void;
}

interface OwnProps {
  activeTab?: string;
  handleEdit?: () => void;
  organisationProfileInfo?: any;
  savedProfile?: boolean;
  handleClose?: () => void;
  setStep?: () => void;
  email?: string;
  adminTabName?: string;
}

export interface CCOProfileProps extends StateProps, DispatchProps, OwnProps {}

const mapStateToProps = (state: any): StateProps => {
  return {
    fetch_login_cco_succeeded: state.login_cco.fetch_login_cco_succeeded,
    loading:
      state.cco_profile.fetch_profile_loading ||
      state.cco_profile.save_org_profile_loading ||
      state.cco_profile.fetch_org_profile_loading ||
      state.cco_profile.invite_user_loading ||
      state.cco_profile.locations_loading ||
      state.home.campaigns_cards_loading ||
      state.login_cco.send_password_loading ||
      state.login_cco.set_password_loading,
    fetch_profile_succeeded: state.cco_profile.fetch_profile_succeeded,
    fetch_profile_failed: state.cco_profile.fetch_profile_failed,
    profile_info: state.cco_profile.cco_profile_info,

    campaignInfo: state.home.campaign_card_info,

    save_org_profile_succeeded: state.cco_profile.save_org_profile_succeeded,
    save_org_profile_failed: state.cco_profile.save_org_profile_succeeded,

    invite_user_succeeded: state.cco_profile.invite_user_succeeded,
    invite_user_failed: state.cco_profile.invite_user_failed,

    team_user_info: state.cco_profile.team_user_info,

    filters_succeeded: state.cco_profile.filters_succeeded,
    filters_failed: state.cco_profile.filters_failed,
    filters_data: state.cco_profile.filters_data,

    locations_succeeded: state.cco_profile.locations_succeeded,
    locations_failed: state.cco_profile.locations_failed,
    locations: state.cco_profile.locations_data,
    location: state.search.location,

    send_password_succeeded: state.login_cco.send_password_succeeded,
    send_password_failed: state.login_cco.send_password_failed,

    set_password_succeeded: state.login_cco.set_password_succeeded,
    set_password_failed: state.login_cco.set_password_failed
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    getUserProfile: () => dispatch(getUserProfile()),
    getCampaigns: () => dispatch(getHomeCards()),
    getProfile: () => dispatch(getProfile()),
    setLatLong: (payload: any) => dispatch(setLatLong(payload)),
    saveOrgProfile: (payload: any) =>
      dispatch(saveOrganizationProfile(payload)),
    updateOrgProfile: (payload: any) =>
      dispatch(updateOrganisationProfile(payload)),
    updateOrgProfileNew: (payload: any, flag: string, isReApproval: boolean) =>
      dispatch(updateOrganisationProfileNew(payload, flag)),

    inviteUser: (payload: any) => dispatch(inviteUser(payload)),
    getOrganisationProfile: () => dispatch(getOrganisationProfile()),
    getCCOUsers: () => dispatch(getCCOUsers()),
    deActivateUser: (body: any) => dispatch(deActivateUser(body)),
    getAllFilters: () => dispatch(getAllFilters()),
    toggleSearch: () => dispatch(toggleSearch()),
    setLocation: (string) => dispatch(setLocation(string)),
    userLogout: () => dispatch(userLogout()),
    getAllLoacations: (lat: any, long: any) =>
      dispatch(getAllLocations(lat, long)),
    sendPassword: (email: string) => dispatch(sendMyPasswordToUpdate(email)),
    setPassword: (email: string, otp: string, newPassword: string) =>
      dispatch(setMyPassword(email, otp, newPassword))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
