import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import ProfileContainer, {
  CCOProfileProps
} from '../../views/CCOProfile/container/ProfileContainer';
import SearchContainer, {
  SearchProps
} from '../../views/Search/containers/SearchContainer';
import MobileSearch from '../../views/Search/components/MobileSearch';

interface MobileNavBarProps {
  sidebarOpen: boolean;
  onSetOpen: () => void;
}

class MobileNavBar extends Component<
  CCOProfileProps & SearchProps & MobileNavBarProps
> {
  state = {
    openLocationModal: false,
    searchOpen: false
  };

  myRef2: any = React.createRef();

  handleSearch = () => {
    this.setState({
      searchOpen: !this.state.searchOpen
    });

    if (this.props.sidebarOpen) {
      this.props.onSetOpen();
    }
  };

  openCurrentLocationModal = () => {
    this.setState({
      openLocationModal: !this.state.openLocationModal,
      openDiscoverMoreModal: false
    });
  };
  setCurrentLocation = (currentLocation: any) => {
    this.props.setLocation(currentLocation);
    this.setState({
      openLocationModal: false,
      currentLocation
    });
  };
  render() {
    const { searchOpen } = this.state;
    return (
      <div className="mobile-nav-bar show-mobile-only">
        <Form
          inline
          className={
            this.state.openLocationModal
              ? 'location-links activeLocationLinks'
              : 'location-links'
          }
          onClick={this.openCurrentLocationModal}
        >
          <div
            className={
              this.state.openLocationModal
                ? 'active-link-drops link-drops-cont'
                : 'link-drops-cont'
            }
          >
            <span className="name">
              {this.props.location === 'all'
                ? 'Nationwide'
                : "Near Me"}
            </span>
            <i className="fa fa-angle-down"></i>
          </div>
          {this.state.openLocationModal && (
            <div className="location-items" ref={this.myRef2}>
              <span
                className={
                  this.props.location === 'all'
                    ? 'loc-item loc-item-active'
                    : 'loc-item'
                }
                onClick={() => this.setCurrentLocation('all')}
              >
                Nationwide
              </span>
              <span
                className={
                  this.props.location === 'nearMe'
                    ? 'loc-item loc-item-active'
                    : 'loc-item'
                }
                onClick={() => this.setCurrentLocation('nearMe')}
              >
                Near Me
              </span>
            </div>
          )}
        </Form>
        <i className="fa fa-search search" onClick={this.handleSearch} />
        {searchOpen && (
          <MobileSearch open={searchOpen} closeSearch={this.handleSearch} />
        )}
      </div>
    );
  }
}

export default ProfileContainer(SearchContainer(MobileNavBar));
