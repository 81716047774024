import React, { Component } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import './MyProfile.scss';
import ProfileContainer, {
  CCOProfileProps
} from '../container/ProfileContainer';
import SweetAlert from 'react-bootstrap-sweetalert';
import OrganisationProfile from './OrganisationProfile';
import { validatePassword } from '../../Auth/components/CCO-Registration/CCORegister';
import { doLogout } from '../../../config/constants';

class MyProfile extends Component<CCOProfileProps> {
  state = {
    openResetPasswordModal: false,
    otp: '',
    newPassword: '',
    errors: {
      newPassword: ''
    }
  };

  openResetModal = () => {
    this.setState({
      openResetPasswordModal: true,
      otp: '',
      newPassword: '',
      errors: {
        newPassword: ''
      }
    });
  };

  onConfirm = () => {};
  onCancel = () => {
    this.setState({
      openResetPasswordModal: false
    });
  };

  handleInputChange = (ev: any) => {
    ev.preventDefault();
    const { name, value } = ev.target;
    let errors = this.state.errors;
    switch (name) {
      case 'newPassword':
        errors.newPassword = validatePassword.test(value)
          ? ''
          : 'Password must contain at least 1 uppercase & 1 lowercase letter, 1 number and 1 special character, and be a minimum of 8 characters long.';
        break;
      default:
        break;
    }
    this.setState({
      [ev.target.name]: ev.target.value
    });
  };

  sendMyPassword = () => {
    this.props.sendPassword(this.props.profile_info.email);
  };

  setMyPassword = () => {
    this.props.setPassword(
      this.props.profile_info.email,
      this.state.otp,
      this.state.newPassword
    );
  };

  componentDidUpdate(prevProps: CCOProfileProps) {
    if (
      this.props.send_password_succeeded &&
      this.props.send_password_succeeded !== prevProps.send_password_succeeded
    ) {
      this.openResetModal();
    }

    if (
      this.props.set_password_succeeded &&
      this.props.set_password_succeeded !== prevProps.set_password_succeeded
    ) {
      doLogout();
    }
  }

  render() {
    const { otp, newPassword, errors } = this.state;
    let is_cco_logged_in = localStorage.getItem('__is_loggedIn');
    return (
      <div className="my-profile-cont">
        {this.props.fetch_profile_succeeded && (
          <form className="form-cont">
            <div className="profile-pic">
              <div>
                {this.props.profile_info.firstName[0] +
                  this.props.profile_info.lastName[0]}
              </div>
            </div>

            <div style={{ marginTop: '2rem' }}>
              <div className="name-cont">
                <span className="text-1">NAME : </span>
                <span>
                  {this.props.profile_info.firstName +
                    ' ' +
                    this.props.profile_info.lastName}
                </span>
              </div>
              <div className="name-cont">
                <span className="text-1">EMAIL : </span>
                <span>{this.props.profile_info.email}</span>
              </div>

              {is_cco_logged_in && (
                <>
                  <div className="name-cont">
                    <span className="text-1">Charity :</span>
                    <span>
                      {this.props.profile_info !== undefined &&
                        this.props.profile_info?.charity?.CharityName}
                    </span>
                  </div>
                  <div className="name-cont">
                    <span className="text-1">Registered Charity Number :</span>
                    <span>
                      {this.props.profile_info !== undefined &&
                        this.props.profile_info?.charity
                          ?.RegisteredCharityNumber}
                    </span>
                  </div>
                  <div className="name-cont">
                    <span className="text-1">Registered Address :</span>
                    <span>
                      {this.props.profile_info?.charity?.Line1 +
                        ', ' +
                        this.props.profile_info?.charity?.Line2 +
                        ', ' +
                        this.props.profile_info?.charity?.Line3 +
                        ', ' +
                        this.props.profile_info?.charity?.Postcode}
                    </span>
                  </div>
                </>
              )}
              <Button onClick={this.sendMyPassword}>
                <span>Change Password</span>
              </Button>
            </div>
          </form>
        )}

        {is_cco_logged_in && (
          <>
            <div className="my-profile-heading">Organisation Profile</div>
            <OrganisationProfile />
          </>
        )}
        {this.state.openResetPasswordModal && (
          <SweetAlert
            custom
            showCancel={false}
            showCloseButton
            showConfirm={false}
            confirmBtnText="Yes"
            cancelBtnText="No"
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="light"
            title="Change Your Password.!"
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
          >
            <div className="form-conts-s">
              <label htmlFor="" className="label" style={{ textAlign: 'left', color: 'var(--vivid-green)', marginTop: '-2rem', marginBottom: '1rem'}}>
                OTP sent successfully to your email id.
              </label>
              <div className="form-group">
                <label htmlFor="" className="label">
                  OTP
                </label>
                <FormControl
                  type="number"
                  name="otp"
                  onChange={this.handleInputChange}
                  value={otp}
                  autoComplete="off"
                />
              </div>
              <div className="form-group">
                <label htmlFor="" className="label">
                  New Password
                </label>
                <FormControl
                  type="password"
                  name="newPassword"
                  onChange={this.handleInputChange}
                  value={newPassword}
                />
                {newPassword.length > 0 && (
                  <label className="error-msg" style={{ textAlign: 'left' }}>
                    {newPassword.length > 0 ? errors.newPassword : ''}
                  </label>
                )}
              </div>
              <div className="btn-cont">
                {/* <Button className="btn-default btn-form">Cancel</Button> */}
                <Button
                  className="btn-primary btn-form"
                  disabled={otp.length === 0 || newPassword.length === 0}
                  onClick={this.setMyPassword}
                >
                  Update and Logout
                </Button>
              </div>
            </div>
          </SweetAlert>
        )}
      </div>
    );
  }
}

export default ProfileContainer(MyProfile);
