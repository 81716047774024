import React, { Component } from 'react';
import ProfileContainer, {
  CCOProfileProps
} from '../../views/CCOProfile/container/ProfileContainer';

import SearchContainer, {
  SearchProps
} from '../../views/Search/containers/SearchContainer';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

interface SideBarContentProps {
  open: boolean;
  onSetOpen: () => void;
}

class SideBarContent extends Component<
  CCOProfileProps & SearchProps & SideBarContentProps
> {
  componentDidMount() {
    this.props.getAllFilters();

    if (localStorage.getItem('__is_user_loggedIn')) {
      this.props.getUserProfile();
    }

    if (localStorage.getItem('__is_loggedIn')) {
      this.props.getProfile();
    }

    if (this.props.location !== 'all' && this.props.location !== 'nearMe') {
      this.props.setLocation('all');
    }
  }

  componentDidUpdate(
    prevProps: Readonly<CCOProfileProps & SearchProps & SideBarContentProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (this.props.open && this.props.open !== prevProps.open) {
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    }

    if (!this.props.open && this.props.open !== prevProps.open) {
      document.getElementsByTagName('body')[0].style.overflowY = 'auto';
    }
  }

  render() {
    const { onSetOpen, userLogout } = this.props;
    const authToken =
      localStorage.getItem('__auth') || localStorage.getItem('__admin_auth');
    const isLoggedIn = localStorage.getItem('__is_loggedIn');
    const isUserLoggedIn = localStorage.getItem('__is_user_loggedIn');
    const isAdminLoggedIn = localStorage.getItem('__is_admin_loggedIn');
    return (
      <div className="mobile-side-bar">
        <div className="close-icon" onClick={onSetOpen}>
          <i className="fa fa-close" />
        </div>
        <div className="auth-wrapper">
          {!authToken && (
            <>
              <Nav.Link href="/auth/login" className="btn-auth">
                Login
              </Nav.Link>

              <Nav.Link href="/auth/register" className="btn-auth">
                Register
              </Nav.Link>
            </>
          )}
        </div>
        {authToken && this.props.profile_info !== '' && (
          <div className="profile">
            <div className="profile__pic-wrapper">
              <div className="profile__pic">
                {this.props.profile_info?.firstName[0] +
                  this.props.profile_info?.lastName[0]}
              </div>
            </div>
            <div className="profile__details">
              <div>
                {`${this.props.profile_info?.firstName}  ${this.props.profile_info?.lastName}`}
              </div>
              <div>{this.props.profile_info?.email}</div>
              <div>{this.props.profile_info?.charity?.CharityName}</div>
            </div>
          </div>
        )}
        <div className="navigation">
          {!isAdminLoggedIn && (
            <NavLink
              to="/all-causes"
              className="navigation__item"
              activeClassName="navigation__item--active"
            >
              Discover
            </NavLink>
          )}

          {!isAdminLoggedIn && (
            <NavLink
              to="/volunteering"
              className="navigation__item"
              activeClassName="navigation__item--active"
            >
              Volunteering
            </NavLink>
          )}

          {isUserLoggedIn && (
            <>
              <a href="/profile/my_profile" className="navigation__item">
                My Profile
              </a>
              <a href="/profile/my_donations" className="navigation__item">
                My Donations
              </a>
            </>
          )}

          {isLoggedIn && (
            <>
              <a href="/profile/dashboard" className="navigation__item">
                Dashboard
              </a>
              <a href="/profile/my_profile" className="navigation__item">
                My Profile
              </a>
              <a href="/profile/my_wishlist" className="navigation__item">
                My Wishlists
              </a>
            </>
          )}

          {isAdminLoggedIn && (
            <a href="/admin/manage_organisations" className="navigation__item">
              Manage Organisation
            </a>
          )}

          {isAdminLoggedIn && (
            <a href="/admin/manage_campaigns" className="navigation__item">
              Manage Campaigns
            </a>
          )}

          {isAdminLoggedIn && (
            <a href="/admin/manage_volunteering" className="navigation__item">
              Manage Volunteering
            </a>
          )}

          {authToken && (
            <div className="navigation__item" onClick={userLogout}>
              Logout
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ProfileContainer(SearchContainer(SideBarContent));
