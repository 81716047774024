import React, { Component } from 'react';
import './DoneConfiguration.scss';
import { history } from '../../../../routes';
import { authorizer } from '../../../../config/constants';

let timer: any = 0;

class DoneConfiguring extends Component {
  state = {
    time: {
      m: '',
      s: ''
    },
    seconds: 25,
    resendOtpClicked: false
  };

  secondsToTime = (secs: any) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  };

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar }, () => this.startTimer());
  }

  startTimer = () => {
    timer = 0;
    let timeLeftVar = this.secondsToTime(25);
    this.setState({ time: timeLeftVar, seconds: 25 }, () => {
      if (timer === 0 && this.state.seconds > 0) {
        timer = setInterval(this.countDown, 1000);
      }
    });
  };

  countDown = () => {
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds
    });
    if (seconds === 0) {
      if (localStorage.getItem('__auth')) {
        let token = localStorage.getItem('__auth');
        authorizer.setHeader(token);
        clearInterval(timer);
        history.push('/profile');
      } else {
        clearInterval(timer);
        history.push('/auth/login');
      }
    }
  };

  redirect = () => {
    if (localStorage.getItem('__auth') !== null) {
      clearInterval(timer);
      authorizer.setHeader(localStorage.getItem('__auth'));
      history.push('/profile');
    } else {
      clearInterval(timer);
      history.push('/auth/login');
    }
  };

  render() {
    return (
      <div className="register-cont-block">
        <div className="part-1-3" />
        <div className="part-2-3">
          <div className="title">CONGRATULATIONS..!</div>
          <div className="sub-title">
            Thank you for registering with HelpBritain.{' '}
          </div>

          <div className="description-cont">
            <span className="header-1">Not long now.</span>
            <span className="desc">
              We have received your registration and our team will verify your
              details and publish your charity onto the site. In order to appear
              on HelpBritain, you need to create at least one wishlist.
            </span>

            <a href="/auth/login" className="linksssss">
              Click here to log in now and create your first wishlist.
            </a>
          </div>

          {/* <div className="timer-cont">
            {localStorage.getItem('__auth') !== null
              ? 'Please wait we are redirecting you to the dashboard page'
              : 'We are redirecting you to login page'}{' '}
            -{' '}
            <span onClick={this.redirect} className="time">
              Redirect Now ( {this.state.time.s} )
            </span>
          </div> */}
        </div>
      </div>
    );
  }
}

export default DoneConfiguring;
