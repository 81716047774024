import { connect } from 'react-redux';

interface StateProps {
  services: any;
}

interface DispatchProps {}

export interface FooterProps extends StateProps, DispatchProps {}

const mapStateToProps = (state: any): StateProps => {
  return {
    services: state.cco_profile.filters_data
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps);
