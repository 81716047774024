import { connect } from "react-redux";
import { getWishlist, publish } from "../../../services/Campaigns/actions";
import { getProfile } from "../../../services/CCOProfile/actions";

interface StateProps {
  loading?: boolean;
  fetch_wishlist_succeeded?: boolean;
  fetch_wishlist_failed?: boolean;
  wishlistInfo?: any[];
  publish_succeeded: boolean;
  publish_info: any;
  profile_info: any;
}

interface DispatchProps {
  getWishlist: (url : string) => void;
  publish: (data : any,type: any) => void;
  getProfile: () => void;
}

interface OwnProps {
    itemId: number
    goBack: () => void;
    handlePublish: (campaignName: string, publishInfo: any) => void;
}

export interface CreateCampaignProps
  extends StateProps,
    DispatchProps,
    OwnProps {}

const mapStateToProps = (state: any): StateProps => {
  return {
    loading: state.campaign.fetch_wishlist_loading || state.campaign.publish_loading,
    fetch_wishlist_succeeded: state.campaign.fetch_wishlist_succeeded,
    fetch_wishlist_failed: state.campaign.fetch_wishlist_loading,
    wishlistInfo: state.campaign.wishlist_info,
    publish_succeeded: state.campaign.publish_succeeded,
    publish_info: state.campaign.publish_info,
    profile_info: state.cco_profile.cco_profile_info,
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    getWishlist: (url: any) => dispatch(getWishlist(url)),
    publish: (data : any, type: any) => dispatch(publish(data, type)),
    getProfile: () => dispatch(getProfile()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
