import React, { Component } from 'react';
import Search from './Search';
import ProfileContainer, {
  CCOProfileProps
} from '../../CCOProfile/container/ProfileContainer';
import SearchContainer, {
  SearchProps
} from '../../Search/containers/SearchContainer';
import { Form, FormControl} from 'react-bootstrap';
import PuffLoader from '../../../components/ReactLoaders/ReactLoader';

interface MobileSearchProps {
  closeSearch: () => void;
  open: boolean;
}

class MobileSearch extends Component<
  CCOProfileProps & SearchProps & MobileSearchProps
> {
  state = {
    searchItem: [],
    searchValue: '',
    tab: 'campaign'
  };
  myRefSearch: any = React.createRef();
  myRef: any = React.createRef();

  handleTab = (e: any, tabValue: string) => {
    e.preventDefault();
    const { searchValue } = this.state;
    const { current_location } = this.props;
    this.setState({
      tab: tabValue
    });
    this.props.searchCampaign(searchValue, current_location, tabValue);
  };

  handleSearch = (ev: any) => {
    const { current_location } = this.props;
    const { tab } = this.state;
    this.setState({
      searchValue: ev.target.value
    });
    this.props.searchCampaign(ev.target.value, current_location, tab);
  };

  render() {
    const { tab, searchValue } = this.state;
    const { closeSearch, open } = this.props;

    return (
      <div className="mobile-search">
        <div className="mobile-search__close">
          <i className="fa fa-close close" onClick={closeSearch} />
        </div>
        <Form inline className="form-container" ref={this.myRef}>
          <FormControl
            type="text"
            placeholder="Search"
            className={!open ? 'input-field active-input' : 'input-field'}
            value={searchValue}
            onChange={(ev) => this.handleSearch(ev)}
          />
          {this.props.search_org_loading ? (
            <div className="load-container">
              <PuffLoader />
            </div>
          ) : (
            <i
              className={
                open
                  ? 'fa fa-search search-icon'
                  : 'fa fa-search search-icon search-icon-active'
              }
              aria-hidden="true"
            />
          )}
        </Form>
        {searchValue.length > 0 && (
          <div className="mobile-search__results" ref={this.myRefSearch}>
            <Search activeTab={tab} onTabClick={this.handleTab} />
          </div>
        )}
      </div>
    );
  }
}

export default ProfileContainer(SearchContainer(MobileSearch));
