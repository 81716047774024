import React, { Component } from 'react';
import TermsAndConditionModal from './TermsAndConditionModal';
import { FormControl, Button } from 'react-bootstrap';

export const validEmailRegex = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const validatePassword = RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-+!@#$%^&*_.,)(=;:])(?=.{8,})'
);

interface CCORegisterProps {
  registerCCO: (
    firstName: any,
    lastName: any,
    email: any,
    password: any
  ) => void;
}

class CCORegister extends Component<CCORegisterProps> {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    passwordType: 'password',
    checkTerms: false,
    checkPermissions: false,
    openTermsConditionModal: false,
    errors: {
      fullName: '',
      email: '',
      password: '',
      confirmPassword: ''
    }
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case 'fullName':
        errors.fullName = value.length === 0 ? 'Full Name required!' : '';
        break;
      case 'email':
        errors.email = validEmailRegex.test(value) ? '' : 'Email is not valid!';
        break;
      case 'password':
        errors.password = validatePassword.test(value)
          ? ''
          : 'Password must contain at least 1 uppercase & 1 lowercase letter, 1 number and 1 special character, and be a minimum of 8 characters long.';
        break;
      default:
        break;
    }
    this.setState({
      errors,
      [event.target.name]: event.target.value
    });
  };

  setPasswordType = () => {
    this.setState({
      passwordType:
        this.state.passwordType === 'password'
          ? 'text'
          : this.state.passwordType === 'text'
          ? 'password'
          : 'text'
    });
  };

  checkTerms = () => {
    this.setState({
      checkTerms: !this.state.checkTerms
    });
  };

  acceptTerms = () => {
    this.setState({
      checkTerms: true
    });
  };

  checkPermissions = () => {
    this.setState({
      checkPermissions: !this.state.checkPermissions
    });
  };

  openTermsConditionModal = () => {
    this.setState({
      openTermsConditionModal: !this.state.openTermsConditionModal
    });
  };

  registerCCO = (ev: any) => {
    ev.preventDefault();
    const { firstName, lastName, email, password } = this.state;
    this.props.registerCCO(firstName, lastName, email, password);
  };

  render() {
    const {
      firstName,
      lastName,
      password,
      email,
      checkPermissions,
      checkTerms,
      openTermsConditionModal,
      errors,
      confirmPassword
    } = this.state;

    let disableRegisterBtn =
      firstName === '' ||
      lastName === '' ||
      password === '' ||
      email === '' ||
      errors.email.length > 0 ||
      errors.password.length > 0 ||
      this.state.password !== this.state.confirmPassword ||
      !checkTerms;

    return (
      <div className="part-2">
        <div className="part-2-header">
          <div className="title">Provide Your Details</div>
          <div className="sub-title">
            Let’s get you all set up so you can verify your organisation and
            begin setting up campaigns
          </div>
        </div>

       <div className="form-cont-wrapper">
         <form
           className="form-cont"
           onSubmit={(ev: any) => this.registerCCO(ev)}
         >
           <div className="name-form-cont">
             <div className="name-form-group">
               <label className="label">First Name *</label>
               <FormControl
                 type="text"
                 value={firstName}
                 name="firstName"
                 onChange={this.handleInputChange}
               />
               <label className="error-msg">{errors.fullName}</label>
             </div>

             <div className="name-form-group">
               <label className="label">Last Name *</label>
               <FormControl
                 type="text"
                 value={lastName}
                 name="lastName"
                 onChange={this.handleInputChange}
               />
             </div>
           </div>

           <div className="email-form-cont">
             <label className="label">Email *</label>
             <FormControl
               type="text"
               value={email}
               name="email"
               onChange={this.handleInputChange}
             />
           </div>
           <label className="error-msg">{errors.email}</label>

           <div className="name-form-cont">
             <div className="name-form-group">
               <label className="label">Enter password *</label>
               <FormControl
                 type="password"
                 value={password}
                 name="password"
                 onChange={this.handleInputChange}
               />
             </div>

             <div className="name-form-group">
               <label className="label">Re-enter password *</label>
               <FormControl
                 type="password"
                 value={confirmPassword}
                 name="confirmPassword"
                 onChange={this.handleInputChange}
               />
             </div>
           </div>
           <label className="error-msg">
             {password.length > 0 ? errors.password : ''}
           </label>
           <label className="error-msg">
             {confirmPassword.length > 0 &&
             this.state.password !== this.state.confirmPassword
               ? 'Your password and confirm password do not match.'
               : ''}
           </label>
           <div className="terms-cont">
             <div className="terms-content">
               <input
                 type="checkbox"
                 checked={checkTerms}
                 onChange={this.checkTerms}
               />
               <span>
                {'I can confirm I have read and accepted the'}
                 <span className="link" onClick={this.openTermsConditionModal}>
                  {'Terms & Conditions '}
                </span>
              </span>
             </div>
             <div className="terms-content">
               <input
                 type="checkbox"
                 checked={checkPermissions}
                 onChange={this.checkPermissions}
               />
               <span>
                I would like to sign up and receive email updates from Help
                Britain. See Privacy Policy
              </span>
             </div>
             {/* <GoogleReCaptchaProvider reCaptchaKey="6LfdHcQZAAAAAJsEehDSqIqeZ0fz0raZjRuTaEyN">
              <GoogleReCaptcha onVerify={(token) => console.log(token)} />
            </GoogleReCaptchaProvider> */}
           </div>

           <Button
             type="submit"
             className={
               disableRegisterBtn ? 'btn-disable btn' : 'btn-primary btn'
             }
             disabled={disableRegisterBtn}
           >
             <span>Next Step</span>
           </Button>

         </form>
         <div className="login-cont">
           Already have an account? - <a href="/auth/login">Login here</a>
         </div>
       </div>

        {openTermsConditionModal && (
          <TermsAndConditionModal
            show={openTermsConditionModal}
            handleClose={this.openTermsConditionModal}
            handleAccept={this.acceptTerms}
          />
        )}
      </div>
    );
  }
}

export default CCORegister;
