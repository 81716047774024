import { connect } from 'react-redux';
import { toggleSearch, searchCampaign } from '../../../services/Search/actions';

interface StateProps {
  searchOpen: boolean;
  search_org_succeeded: boolean;
  search_org_failed: boolean;
  search_org_info: any;
  search_org_loading: boolean;
  current_location: string;
  locations: any[];
}

interface DispatchProps {
  toggleSearch: () => void;
  searchCampaign: (search: string, city: string, type: string) => void;
}

interface OwnProps {
  activeTab?: String,
  onTabClick?: any
}

export interface SearchProps extends StateProps, DispatchProps, OwnProps {}

const mapStateToProps = (state: any): StateProps => {
  return {
    searchOpen: state.search.searchOpen,
    search_org_loading: state.search.search_org_loading,
    search_org_succeeded: state.search.search_org_succeeded,
    search_org_failed: state.search.search_org_succeeded,
    search_org_info: state.search.search_org_info,
    current_location: state.search.location,
    locations: state.cco_profile.locations_data
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    searchCampaign: (search: string, city: string, type: string) =>
      dispatch(searchCampaign(search, city, type)),
    toggleSearch: () => dispatch(toggleSearch())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
