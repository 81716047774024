import React, { Component } from 'react';
import cx from 'classnames';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './dashboard.scss';
import Axios from 'axios';
import ProfileContainer, {
  CCOProfileProps
} from '../container/ProfileContainer';
import Loader from '../../../components/Loader/Loader';
import { baseUrl } from '../../../config/api-config';
import { history } from '../../../routes';
import { Table } from 'react-bootstrap';

class Dashboard extends Component<CCOProfileProps> {
  state = {
    dashboardDetails: {
      activeCampaigns: 0,
      inactiveCampaigns: 0,
      futureCampaigns: 0,
      totalCampaigns: 0
    } as any,
    loading: false,
    setTime: {
      value: 'All Time',
      label: 'All Time'
    } as any,
    amountReceived: 0,
    volunteers: 0
  };
  componentDidUpdate(prevProps: any) {
    const { profile_info, fetch_profile_succeeded } = this.props;

    if (
      fetch_profile_succeeded &&
      fetch_profile_succeeded !== prevProps.fetch_profile_succeeded
    ) {
      this.setState({
        loading: false
      });
      Axios(`${baseUrl}/dashboard/getDashboardData?cco=${profile_info.email}`)
        .then((res) => {
          this.setState({
            dashboardDetails: res.data.data,
            loading: false
          });
        })
        .catch((err) => {
          this.setState({
            loading: false
          });
        });

      this.getDataBasedOnTime('');
    }
  }

  getDataBasedOnTime = (option: any) => {
    var today = new Date();

    if (option === 'Last 24 hours') {
      var lastOneDay: any = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 1
      );

      let start = lastOneDay.setHours(0, 0, 0, 0);
      let end = new Date().setHours(23, 59, 59, 999);

      this.setState({
        loading: true
      });
      Axios(
        `${baseUrl}/dashboard/getDonationAmount?cco=${this.props.profile_info.email}&fromDate=${start}&toDate=${end}`
      ).then((data: any) => {
        this.setState({
          volunteers: data.data.data.volunteers,
          amountReceived: data.data.data.amount,
          loading: false
        });
      });
    }

    if (option === 'Last 7 days') {
      var lastWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 7
      );

      let start = lastWeek.setHours(0, 0, 0, 0);
      let end = new Date().setHours(23, 59, 59, 999);

      this.setState({
        loading: true
      });
      Axios(
        `${baseUrl}/dashboard/getDonationAmount?cco=${this.props.profile_info.email}&fromDate=${start}&toDate=${end}`
      ).then((data: any) => {
        this.setState({
          volunteers: data.data.data.volunteers,
          amountReceived: data.data.data.amount,
          loading: false
        });
      });
    }

    if (option === 'Last 30 days') {
      var lastMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 30
      );

      let start = lastMonth.setHours(0, 0, 0, 0);
      let end = new Date().setHours(23, 59, 59, 999);

      this.setState({
        loading: true
      });
      Axios(
        `${baseUrl}/dashboard/getDonationAmount?cco=${this.props.profile_info.email}&fromDate=${start}&toDate=${end}`
      ).then((data: any) => {
        this.setState({
          volunteers: data.data.data.volunteers,
          amountReceived: data.data.data.amount,
          loading: false
        });
      });
    }

    if (
      option !== 'Last 30 days' &&
      option !== 'Last 7 days' &&
      option !== 'Last 24 hours'
    ) {
      var start = new Date();
      start.setHours(0, 0, 0, 0);

      var end = new Date();
      end.setHours(23, 59, 59, 999);

      this.setState({
        loading: true
      });
      Axios(
        `${baseUrl}/dashboard/getDonationAmount?cco=${this.props.profile_info.email}`
      ).then((data: any) => {
        this.setState({
          volunteers: data.data.data.volunteers,
          amountReceived: data.data.data.amount,
          loading: false
        });
      });
    }
  };

  getVolunteeringData = () => {
    const { dashboardDetails } = this.state;
    let data = [];

    data.push(['Volunteers', 'Total needed', 'Signed Up']);

    dashboardDetails?.volunteering?.map((v: any) => {
      data.push([
        v.volunteeringName,
        Number(v.requiredVolunteers),
        Number(v.volunteers)
      ]);
    });

    return data;
  };

  getVisitsData = () => {
    const { dashboardDetails } = this.state;
    const data = {
      labels: dashboardDetails?.visits?.map((v: any) => v.campaignName),
      datasets: [
        {
          label: 'Visits Count',
          maxBarThickness: '16',
          backgroundColor: 'rgba(82, 244, 136, .8)',
          borderColor: 'rgba(82, 244, 136, 1)',
          pointBorderColor: 'rgba(82, 244, 136, 1)',
          pointBackgroundColor: '#fff',
          pointHoverBackgroundColor: 'rgba(82, 244, 136, 1)',
          pointHoverBorderColor: 'rgba(82, 244, 136, 1)',
          pointHoverBorderWidth: 2,
          data: dashboardDetails?.visits?.map((v: any) => v.count)
        }
      ],
      options: { maintainAspectRatio: false }
    };

    return data;
  };

  getTableVolunteeringData = () => {
    const { dashboardDetails } = this.state;
    let data = [] as any;

    dashboardDetails?.volunteering?.map((v: any) => {
      data.push([
        v.volunteeringName,
        Number(v.requiredVolunteers),
        Number(v.volunteers)
      ]);
    });

    return data;
  };

  goToWishList = (status: string) => {
    history.push(`/profile/my_wishlist?status=${status}`);
  };

  onSelect = (option: any) => {
    this.setState(
      {
        setTime: option
      },
      () => {
        this.getDataBasedOnTime(this.state.setTime.value);
      }
    );
  };

  render() {
    const { loading, dashboardDetails } = this.state;
    const hasVolunteering = dashboardDetails?.volunteering?.length > 0;
    let options = [
      { value: 'All Time', label: 'All' },
      { value: 'Last 24 hours', label: '1D' },
      { value: 'Last 7 days', label: '1W' },
      { value: 'today', label: '1M' }
    ];
    return (
      <>
        {loading && <Loader />}
        <div className="my-profile-cont dashboard">
          <h4 className="dashboard__heading">My Stats</h4>

          <div className="part-1-stats">
            <div className="wishlist-stats">
              <div className="sub-headers">Wishlists</div>
              <div className="links-cont">
                <a href="/profile/my_wishlist?status=Active" className="link">
                  <span>Active Wishlists</span>
                  <span className="numbers">
                    {dashboardDetails?.activeCampaigns}
                  </span>
                </a>
                <a href="/profile/my_wishlist?status=Inactive" className="link">
                  <span>Inactive Wishlists</span>
                  <span className="numbers">
                    {dashboardDetails?.inactiveCampaigns}
                  </span>
                </a>
                <div className="link">
                  <span>Planned Wishlists</span>
                  <span className="numbers">
                    {dashboardDetails?.futureCampaigns}
                  </span>
                </div>
                <a href="/profile/my_wishlist?status=All" className="link">
                  <span>Total Wishlists</span>
                  <span className="numbers">
                    {dashboardDetails?.totalCampaigns}
                  </span>
                </a>
              </div>
            </div>

            <div className="wishlist-stats new">
              <div className="select-cont">
                {options.map((o: any, i: number) => (
                  <div
                    key={i}
                    onClick={(e): any => this.onSelect(o)}
                    className={cx('pill', {
                      'pill-active': this.state.setTime.value === o.value
                    })}
                  >
                    {o.label}
                  </div>
                ))}
              </div>
              <div className="links-cont new-links-cont">
                <div className="newlink">
                  <div
                    className="newlink--sub-headers"
                    style={{ color: 'var(--text-secondary)' }}
                  >
                    Total Donations
                  </div>
                  £{this.state.amountReceived?.toFixed(2)}
                </div>

                <div className="newlink">
                  <div
                    className="newlink--sub-headers"
                    style={{ color: 'var(--text-secondary)' }}
                  >
                    Total Volunteers
                  </div>
                  {this.state.volunteers}
                </div>
              </div>
            </div>
          </div>

          <div className="part2-stats">
            <div className="part2-stats__left">
              {dashboardDetails?.visits?.length > 0 && (
                <div className="sub-headers">Visits</div>
              )}

              {dashboardDetails?.visits?.length > 0 && (
                <div className="part-1-stats">
                  <div className="views-stats">
                    <Table striped bordered>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Campaign Name</th>
                          <th>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardDetails.visits.map((v: any, i: number) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{v.campaignName}</td>
                            <td>{v.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}
            </div>

            <div className="part2-stats__right">
              {hasVolunteering && (
                <div className="sub-headers">Volunteering</div>
              )}

              <div className="part-1-stats">
                {hasVolunteering && (
                  <div className="views-stats">
                    {(dashboardDetails?.volunteering || []).map((v: any, i: any) => (
                      <VolunteeringProgress
                        name={v.volunteeringName}
                        total={Number(v.requiredVolunteers)}
                        value={Number(v.volunteers)}
                        key={i}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProfileContainer(Dashboard);

const VolunteeringProgress = ({ name, total, value }: any) => {
  return (
    <div className="vlp">
      <div className="vlp__header">
        <div className="vlp__header--text">{name}</div>
        <div className="vlp__header--value">{`${value}/${total}`}</div>
      </div>
      <ProgressBar bsPrefix="progress" now={(value / total) * 100} />
    </div>
  );
};
