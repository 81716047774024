import React, { Component } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './Causes.scss';
import CauseContainer, { CauseProps } from '../container/CausesContainer';
import Loader from '../../../components/Loader/Loader';
import { NavLink } from 'react-router-dom';
import CauseCard from './CauseCard';

export const calculate = (data: any) => {
  let sumOfPurchasedAmount = 0;
  let sumOfRequestedAmount = 0;

  for (var i = 0; i < data?.length; i++) {
    sumOfPurchasedAmount += parseInt(data[i].PurchasedAmount);
  }

  for (i = 0; i < data?.length; i++) {
    sumOfRequestedAmount += parseInt(data[i].RequestedAmount);
  }
  return (
    <ProgressBar now={(sumOfPurchasedAmount / sumOfRequestedAmount) * 100} />
  );
};

export const calculateSum = (data: any) => {
  let sumOfPurchasedAmount = 0;
  let sumOfRequestedAmount = 0;

  for (var i = 0; i < data.length; i++) {
    sumOfPurchasedAmount += parseInt(data[i].PurchasedAmount);
  }

  for (i = 0; i < data.length; i++) {
    sumOfRequestedAmount += parseInt(data[i].RequestedAmount);
  }
  return (sumOfPurchasedAmount / sumOfRequestedAmount) * 100;
};

class CausesNearMe extends Component<CauseProps> {
  componentDidMount() {
    const { current_location } = this.props;
    if (current_location !== null) {
      this.props.getCampaigns();
    }
  }

  componentDidUpdate(prevProps: CauseProps) {
    const { current_location } = this.props;
    if (current_location !== prevProps.current_location) {
      this.props.getCampaigns();
    }
  }

  render() {
    const { campaignInfo, loading } = this.props;
    let filteredCharities =
      campaignInfo.length > 0 &&
      campaignInfo
        .filter((ds: any) => ds.charityStatus !== undefined)
        .filter((d: any) => d.charityStatus.toUpperCase() === 'APPROVED' ||  d.charityStatus.toUpperCase() === 'REAPPROVAL')
        .filter((d: any) => d.campaigns !== undefined);

    let length = filteredCharities.length;

    return (
      <div className="causes">
        {loading && <Loader />}
        {!loading && filteredCharities.length > 0 && (
          <div className="cause-container">
            <div className="heading-cause">
              <div className="title">
                <span>
                  {this.props.title !== undefined
                    ? this.props.title
                    : 'Organisations'}
                </span>
                <img
                  src={require('../../../images/shapes/shape1.png')}
                  alt=""
                  className="shape-icon"
                />
              </div>

              {length > 4 && (
                <NavLink to="/all-causes" className="see-more">
                  {' '}
                  See more <i className="fa fa-angle-right" />
                </NavLink>
              )}
            </div>
            <div className="cards-container">
              {filteredCharities.slice(0, 4).map((d: any, index: number) => (
                <CauseCard key={index} cause={d} />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CauseContainer(CausesNearMe);
