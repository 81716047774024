import React, { Component } from 'react';

import Header from '../../../components/Header/Header';
import ReadMoreReact from '../../../components/ReadMore';
import './CampaignDetails.scss';
import { Button } from 'react-bootstrap';
import { history } from '../../../routes';
import WishlistContainer, {
  WishlistProps
} from '../container/WishlistContainer';
import Loader from '../../../components/Loader/Loader';
import Footer from '../../../components/Footer/Footer';
import CircularProgress from '../../../components/CircularProgress';
import { calculateSum } from '../../Causes-Near-Me/components/Causes';
import SocialShare from '../../../components/Share';
import stripePromise from '../../../components/Strapi';
import axios from 'axios';
import { baseUrl } from '../../../config/api-config';
import { notify } from '../../../components/Notifier/Notifier';
import CaptureEmail from '../../Auth/components/CaptureEmail';
import QueryString from 'query-string';
import DonationSuccess from './DonationSuccess';
import Select from '../../../components/Select';

export const CustomStyle = {
  option: () => ({
    backgroundColor: 'white'
  })
};

export const getBreakup = (data: any) => {
  let sumOfPurchasedAmount = 0;
  let sumOfRequestedAmount = 0;

  for (var i = 0; i < data.length; i++) {
    sumOfPurchasedAmount += parseInt(data[i].PurchasedAmount);
  }

  for (i = 0; i < data.length; i++) {
    sumOfRequestedAmount += parseInt(data[i].RequestedAmount);
  }
  return { sumOfPurchasedAmount, sumOfRequestedAmount };
};

class WishListExpand extends Component<WishlistProps> {
  state = {
    content: {} as any,
    donateAmount: 5,
    amountBuffer: 5,
    isLoading: false,
    contributionAmount: '0.00',
    askForEmailModal: false,
    donationSuccessModal: false,
    email: '',
    optionSelected: '0.36',
    atleastAmount: '0.36'
  };

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    this.props.getCampaign(params.campaign_url);
  }

  componentDidUpdate(prevProps: WishlistProps) {
    if (
      this.props.campaign_succeeded &&
      this.props.campaign_succeeded !== prevProps.campaign_succeeded
    ) {
      let is_cco_loggedin: any = localStorage.getItem('__is_loggedIn') || false;
      let is_admin_loggedin =
        localStorage.getItem('__is_admin_loggedIn') || false;
      if (
        !is_cco_loggedin &&
        this.props.campaign_info?.id !== this.props.email &&
        !is_admin_loggedin
      ) {
        let payload = {
          cco: this.props.campaign_info?.id,
          campaignId: this.props.campaign_info?.campaigns[0]?.campaignId,
          campaignName: this.props.campaign_info?.campaigns[0]?.campaignName
        };
        axios.post(
          'https://n1jy0w86cb.execute-api.eu-west-2.amazonaws.com/development/captureVisits',
          payload
        );
      }

      const wishlistUrl =
        this.props.campaign_info.campaigns !== undefined &&
        this.props.campaign_info?.campaigns[0]?.wishListURL;
      if (wishlistUrl) {
        this.props.getWishlist(wishlistUrl);
      }
      this.setState(
        {
          content: this.props.campaign_info
        },
        () => {
          const {
            location: { search }
          } = this.props;
          const queryParams = QueryString.parse(search);
          const is_logged_in = localStorage.getItem('__auth');
          if (queryParams.donation_success === 'true') {
            this.setState(
              {
                donationSuccessModal: true
              },
              () => {
                const { content, email } = this.state;
                let payload = {
                  ccoEmail: content.id,
                  userEmail: is_logged_in
                    ? localStorage.getItem('email')
                    : email,
                  charityName: content.CharityName,
                  campaignName: content.campaigns[0].campaignName,
                  amount: queryParams.donation_amount
                };
                axios.post(baseUrl + '/admin/capturePayment', payload);
              }
            );
          }
        }
      );
    }
  }

  increaseDonateAmount = () => {
    const { donateAmount, amountBuffer } = this.state;
    if (donateAmount + amountBuffer > 50) return;
    this.setState(
      {
        donateAmount: donateAmount + amountBuffer
      },
      () => {
        this.setState({
          atleastAmount: this.getExtraAmountOptions()[0].value,
          optionSelected: this.getExtraAmountOptions()[0].value
        });
      }
    );
  };

  decreaseDonateAmount = () => {
    const { donateAmount, amountBuffer } = this.state;
    if (donateAmount - amountBuffer < 5) return;
    this.setState(
      {
        donateAmount: donateAmount - amountBuffer
      },
      () => {
        this.setState({
          atleastAmount: this.getExtraAmountOptions()[0].value,
          optionSelected: this.getExtraAmountOptions()[0].value
        });
      }
    );
  };

  goBack = () => {
    if (!localStorage.getItem('__is_loggedIn')) {
      history.push(`/charity/${this.state.content.charityName}`);
    } else {
      history.push('/profile/my_wishlist');
    }
  };

  openWishlist = (url: string) => {
    window.open(url, '_blank');
  };

  checkUserLoginToDonate = () => {
    this.doDonate();
  };

  closeCaptureEmailModal = () => {
    this.setState({
      askForEmailModal: false
    });
  };

  doDonate = async () => {
    const { donateAmount, contributionAmount, optionSelected } = this.state;
    const is_logged_in = localStorage.getItem('__auth');
    // Get Stripe.js instance
    this.setState({
      isLoading: true
    });
    const stripe: any = await stripePromise;

    // Call your backend to create the Checkout Session
    let payload = {
      email: is_logged_in ? localStorage.getItem('email') : this.state.email,
      charity: this.state.content.CharityName,
      campaign: this.state.content.campaigns[0].campaignName,
      amount: donateAmount,
      extra_charges:
        optionSelected.toUpperCase() === 'OTHER'
          ? contributionAmount
          : optionSelected,
      stripeCharge: this.state.atleastAmount,
      successUrl:
        window.location.href +
        '?donation_success=true' +
        '&donation_amount=' +
        Number(donateAmount) +
        '&contribution_amount=' +
        Number(optionSelected.toUpperCase() === 'OTHER'
        ? contributionAmount
        : optionSelected),
      cancelUrl: window.location.href
    };
    axios
      .post(`${baseUrl}/hb/stripe`, payload)
      .then(async (res) => {
        const result = await stripe.redirectToCheckout({
          sessionId: res.data.id
        });
        console.log(result);
        this.setState({
          isLoading: false
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        });
        notify.error('Something went wrong', 'Failed to donate.');
      });
  };

  handleInput = (e: any) => {
    if (isNaN(e.target.value) || e.target.value.split('.')[1]?.length > 2)
      return;
    const value = parseFloat(e.target.value);
    if (value >= 101 || value < 0) return;

    this.setState({ [e.target.name]: e.target.value });
  };

  closeDonationSuccessModal = () => {
    const {
      location: { pathname }
    } = this.props;
    this.setState(
      {
        donationSuccessModal: false
      },
      () => {
        const {
          match: { params }
        } = this.props;

        this.props.getCampaign(params.campaign_url);
      }
    );
    this.props.history.push(pathname);
  };

  handleListShow = (option: any) => {
    this.setState({
      optionSelected: option.value
    });
  };

  handleSelectClick = () => {
    this.setState({
      optionSelected:
        this.state.optionSelected === 'Others' ? '' : this.state.optionSelected
    });
  };

  getExtraAmountOptions = (): any => {
    const { donateAmount } = this.state;
    const other = {
      label: 'Other',
      value: 'Other'
    };

    if (donateAmount === 5) {
      return [
        {
          label: '£0.36',
          value: '0.36'
        },
        {
          label: '£1.00',
          value: '1.00'
        },
        other
      ];
    } else if (donateAmount === 10) {
      return [
        {
          label: '£0.51',
          value: '0.51'
        },
        {
          label: '£1.00',
          value: '1.00'
        },
        other
      ];
    } else if (donateAmount === 15) {
      return [
        {
          label: '£0.66',
          value: '0.66'
        },
        {
          label: '£1.00',
          value: '1.00'
        },
        other
      ];
    } else if (donateAmount === 20) {
      return [
        {
          label: '£0.81',
          value: '0.81'
        },
        {
          label: '£1.00',
          value: '1.00'
        },
        other
      ];
    } else if (donateAmount === 25) {
      return [
        {
          label: '£0.96',
          value: '0.96'
        },
        {
          label: '£1.50',
          value: '1.50'
        },
        other
      ];
    } else if (donateAmount === 30) {
      return [
        {
          label: '£1.11',
          value: '1.11'
        },
        {
          label: '£1.50',
          value: '1.50'
        },
        other
      ];
    } else if (donateAmount === 35) {
      return [
        {
          label: '£1.26',
          value: '1.26'
        },
        {
          label: '£1.50',
          value: '1.50'
        },
        other
      ];
    } else if (donateAmount === 40) {
      return [
        {
          label: '£1.41',
          value: '1.41'
        },
        {
          label: '£2.00',
          value: '2.00'
        },
        other
      ];
    } else if (donateAmount === 45) {
      return [
        {
          label: '£1.56',
          value: '1.56'
        },
        {
          label: '£2.00',
          value: '2.00'
        },
        other
      ];
    } else if (donateAmount === 50) {
      return [
        {
          label: '£1.70',
          value: '1.70'
        },
        {
          label: '£2.00',
          value: '2.00'
        },
        other
      ];
    }
  };

  render() {
    const {
      content,
      donateAmount,
      contributionAmount,
      askForEmailModal,
      donationSuccessModal
    } = this.state;
    const {
      match: { params },
      location: { search },
      wishlist_info: { items = [] }
    } = this.props;

    const queryParams = QueryString.parse(search);

    const totalAmount =
      this.state.optionSelected === 'Other'
        ? (Number(donateAmount) + Number(contributionAmount)).toFixed(2)
        : (Number(donateAmount) + Number(this.state.optionSelected)).toFixed(2);

    let is_cco_loggedin: any = localStorage.getItem('__is_loggedIn') || false;
    let is_admin_loggedin =
      localStorage.getItem('__is_admin_loggedIn') || false;

    const option = this.getExtraAmountOptions()
      ?.filter((o: any) => o.value === this.state.optionSelected)
      .shift();

    return (
      <div style={{ height: '100%' }}>
        <Header activeTab="campaigns" />
        {(this.props.loading || this.state.isLoading) && <Loader />}
        {!this.props.loading && (
          <div className="wishlist-expand-cont">
            {/* {content.banner !== undefined && <div className="banner-part" style={{ backgroundImage: `url(${content.banner})`}}/>} */}
            {is_admin_loggedin && (
              <div
                className="back-cont"
                style={{ width: '100%', padding: '0 6rem', marginTop: '2rem' }}
              >
                <a
                  className="back"
                  href={`/admin/manage_campaigns`}
                  style={{ fontSize: '1.2rem' }}
                >
                  <i
                    className="fa fa-arrow-circle-o-left"
                    style={{ fontSize: '1.6rem' }}
                  ></i>
                  Back
                </a>
              </div>
            )}
            <div className="wishlist-main-cont-block">
              <div className="wishlist-cont">
                {content.campaigns !== undefined &&
                  content.campaigns
                    .filter(
                      (d: any) => d.campaignNameUrl === params.campaign_url
                    )
                    .map((data: any, i: number) => (
                      <div className="expand-part-2" key={i}>
                        <div className="right-logo-part">
                          <div
                            className="image-part"
                            style={{
                              backgroundImage: `url(${content.banner})`
                            }}
                          >
                            <img
                              src={data.image}
                              alt=""
                              className="rightlogo"
                            />
                          </div>
                          <div className="org-wrapper">
                            <img src={content.logo} alt="" />
                            <div>
                              <a
                                href={`/charity/${content.charityNameUrl}`}
                                className="name"
                              >
                                {content.CharityName}
                              </a>
                              <span className="address">
                                {content.Line1}, {content.Line2},{' '}
                                {content.Line3}, {content.Postcode}
                              </span>
                            </div>
                          </div>
                        </div>

                        {data.type !== 'Love2Shop' ? (
                          <div className="love2shop-wrapper">
                            <div className="love2shop-wrapper__progress">
                              <div className="love2shop-wrapper__progress-details-wrapper">
                                <CircularProgress
                                  value={calculateSum(items)}
                                  text=" "
                                  width={115}
                                  height={130}
                                  strokeWidth={10}
                                />
                                <div className="love2shop-wrapper__progress-details">
                                  <p>
                                    <span>
                                      {' '}
                                      {
                                        getBreakup(items).sumOfRequestedAmount
                                      }{' '}
                                    </span>{' '}
                                    needed
                                  </p>
                                  <p>
                                    <span>
                                      {' '}
                                      {getBreakup(items).sumOfPurchasedAmount}
                                    </span>{' '}
                                    donated
                                  </p>
                                  <p
                                    style={{
                                      paddingTop: '0.5rem',
                                      borderTop: '0.2rem solid var(--bg-3)'
                                    }}
                                  >
                                    <span>
                                      {getBreakup(items).sumOfRequestedAmount -
                                        getBreakup(items).sumOfPurchasedAmount}
                                    </span>{' '}
                                    Remaining
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="love2shop-wrapper">
                            <div className="love2shop-wrapper__progress">
                              <div className="love2shop-wrapper__progress-details-wrapper">
                                <CircularProgress
                                  value={
                                    (parseFloat(
                                      data.donationAmount !== undefined
                                        ? data.donationAmount
                                        : 0
                                    ) /
                                      parseFloat(data.pledgeAmount)) *
                                    100
                                  }
                                  text=" "
                                  width={115}
                                  height={130}
                                  strokeWidth={10}
                                />
                                <div className="love2shop-wrapper__progress-details">
                                  {data.pledgeAmount !== undefined &&
                                    data.pledgeAmount !== '' && (
                                      <p>
                                        <span>£{data.pledgeAmount}</span> Pledge
                                        Amount
                                      </p>
                                    )}
                                  <p>
                                    <span>
                                      £
                                      {data.donationAmount !== undefined
                                        ? data.donationAmount
                                        : '0'}
                                    </span>{' '}
                                    Donated Amount
                                  </p>

                                  {data.pledgeAmount !== undefined &&
                                    data.pledgeAmount !== '' && (
                                      <p
                                        style={{
                                          paddingTop: '0.5rem',
                                          borderTop: '0.2rem solid var(--bg-3)'
                                        }}
                                      >
                                        <span>
                                          £
                                          {(data.donationAmount !== undefined
                                            ? data.donationAmount
                                            : 0) > data.pledgeAmount
                                            ? 0
                                            : data.pledgeAmount -
                                              (data.donationAmount !== undefined
                                                ? data.donationAmount
                                                : 0)}
                                        </span>{' '}
                                        Remaining
                                      </p>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="social-media-container">
                          <div className="social-heading">
                            Share wishlist on social media
                          </div>
                          <SocialShare
                            ShareUrl={`https://www.helpbritain.org.uk/${params.charity_url}/campaigns/${params.campaign_url}`}
                            linkUrl={`https://www.helpbritain.org.uk/${params.charity_url}/campaigns/${params.campaign_url}`}
                            quote={
                              `I just found this wishlist for ${content.CharityName} on HelpBritain - help me support them!` +
                              '\n'
                            }
                          />
                        </div>
                      </div>
                    ))}
                {content.campaigns !== undefined &&
                  content.campaigns
                    .filter(
                      (d: any) => d.campaignNameUrl === params.campaign_url
                    )
                    .map((data: any, i: number) => (
                      <div className="expand-part-1" key={i}>
                        <div className="text-content">
                          <div className="main-heading">
                            {data.campaignName}
                          </div>
                        </div>

                        <div className="content-part">
                          <ReadMoreReact
                            min={200}
                            ideal={300}
                            max={100000}
                            text={data.description}
                          />
                        </div>

                        <div className="wishlist-itmes-cont">
                          <div className="heading">
                            {data.type === 'Amazon' && (
                              <span>Wishlist items</span>
                            )}
                            {data.type === 'Love2Shop' &&
                              !is_cco_loggedin &&
                              !is_admin_loggedin && (
                                <span>Donate vouchers</span>
                              )}

                            {data.type === 'Amazon' && (
                              <img
                                src={require('../../../images/Dashboard/amazon.jpg')}
                                alt=""
                                className="type-image"
                              />
                            )}
                            {data.type === 'Love2Shop' &&
                              !is_cco_loggedin &&
                              !is_admin_loggedin && (
                                <img
                                  src={require('../../../images/select.png')}
                                  alt=""
                                  className="type-image"
                                />
                              )}
                          </div>

                          {data.type === 'Amazon' && (
                            <>
                              <div className="sub-heading">
                                If you want Amazon to deliver the item(s) you
                                donate direct to the organisation, please ensure
                                the delivery address is set to{' '}
                                {content.Line1 +
                                  ', ' +
                                  content.Line2 +
                                  ', ' +
                                  content.Line3 +
                                  ', ' +
                                  content.Postcode}
                                . We do set the address correctly but sometimes
                                this can be over-ridden in the basket depending
                                on your Amazon settings.
                              </div>
                              <div className="wishlist-itmes-cont-wrapper">
                                {items.map((ds: any, index: number) => (
                                  <div className="item" key={index}>
                                    <div className="logo">
                                      <img
                                        src={ds.imgURL}
                                        alt=""
                                        className="image"
                                      />
                                    </div>
                                    <div className="discription-donation">
                                      <div className="description">
                                        {ds.title}
                                      </div>
                                      <div className="donations">
                                        <div className="needed">
                                          {ds.RequestedAmount} needed
                                        </div>
                                        <div className="donated">
                                          {ds.PurchasedAmount} donated
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <CircularProgress
                                        value={
                                          (Number(ds.PurchasedAmount) /
                                            Number(ds.RequestedAmount)) *
                                          100
                                        }
                                        text=""
                                        width={'60px'}
                                        height={'60px'}
                                      />
                                    </div>

                                    {!is_cco_loggedin && (
                                      <Button
                                        className="btn-primary donate-btn"
                                        onClick={() =>
                                          this.openWishlist(
                                            content.campaigns[0].wishListURL
                                          )
                                        }
                                      >
                                        Donate
                                      </Button>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </>
                          )}

                          {data.type === 'Love2Shop' &&
                            !is_cco_loggedin &&
                            !is_admin_loggedin && (
                              <>
                                <div className="sub-heading">
                                  Select vouchers can be redeemed by the charity
                                  at Tesco, Morrisons and a number of other
                                  retailers listed here: <br />
                                  <div
                                    onClick={() =>
                                      window.open(
                                        'https://www.select-your-reward.co.uk/YourChoices',
                                        '_blank'
                                      )
                                    }
                                    className="anchor-tag"
                                  >
                                    https://www.select-your-reward.co.uk/YourChoices
                                  </div>
                                </div>
                                <div className="love2shop-wrapper">
                                  <div className="love2shop">
                                    <div className="love2shop__inputs">
                                      <div
                                        className="love2shop__minus"
                                        onClick={this.decreaseDonateAmount}
                                      >
                                        <i className="fa fa-minus" />
                                      </div>
                                      <div className="love2shop__show">{`£ ${donateAmount}`}</div>
                                      <div
                                        className="love2shop__plus"
                                        onClick={this.increaseDonateAmount}
                                      >
                                        <i className="fa fa-plus" />
                                      </div>
                                    </div>

                                    <div className="note">
                                      HelpBritain never charges any fees to
                                      charities for using the service and
                                      receiving donations. It costs us at least
                                      £ {this.state.atleastAmount} in fees to
                                      our suppliers to process this payment.
                                      Including a small voluntary contribution
                                      on top of your donation means we can keep
                                      HelpBritain running and help more
                                      charities. We have estimated the cost of
                                      processing your payment below as a
                                      suggested gratuity to help keep us
                                      running.
                                    </div>
                                    <Select
                                      options={this.getExtraAmountOptions()}
                                      value={option}
                                      onChange={this.handleListShow}
                                      className="vl__category v-select-small"
                                      styles={CustomStyle}
                                    />

                                    {this.state.optionSelected === 'Other' && (
                                      <div className="love2shop__contribute">
                                        <label className="love2shop__contribute--label">
                                          Enter Gratuity Amount £ (0 to 100)
                                        </label>
                                        <div className="love2shop__contribute--input-wrapper">
                                          <span className="love2shop__contribute--symbol">
                                            £
                                          </span>
                                          <input
                                            type="text"
                                            name="contributionAmount"
                                            value={contributionAmount}
                                            className="love2shop__contribute--input"
                                            onChange={this.handleInput}
                                            min="0"
                                            max="100"
                                          />
                                        </div>
                                      </div>
                                    )}

                                    <button
                                      className="btn-primary-green love2shop__btn"
                                      onClick={this.checkUserLoginToDonate}
                                    >
                                      Donate
                                    </button>

                                    <div className="love2shop__contribute--total">
                                      {`Total amount £ ${totalAmount}`}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        )}
        {!this.props.loading && <Footer />}

        {askForEmailModal && (
          <CaptureEmail
            email={this.state.email}
            show={askForEmailModal}
            closeModal={this.closeCaptureEmailModal}
            handleEmail={this.handleInput}
            onSubmit={this.doDonate}
            charityName={content.CharityName}
          />
        )}
        {donationSuccessModal && (
          <DonationSuccess
            show={donationSuccessModal}
            closeModal={this.closeDonationSuccessModal}
            donatedAmount={queryParams.donation_amount}
            contributionAmount={queryParams.contribution_amount}
          />
        )}
      </div>
    );
  }
}

export default WishlistContainer(WishListExpand);
