import { combineReducers } from 'redux';
import RegisterReducer from '../services/auth/register/reducer';
import LoginReducer from '../services/auth/login/reducer';
import HomeReducer from '../services/Home/reducer';
import CampaignReducer from '../services/Campaigns/reducer';
import CCOProfileReducer from '../services/CCOProfile/reducer';
import SearchReducer from '../services/Search/reducer';
import VolunteeringReducer from '../services/Volunteering/reducer';

export const reducers = combineReducers({
  login_cco: LoginReducer,
  register_cco: RegisterReducer,
  home: HomeReducer,
  campaign: CampaignReducer,
  cco_profile: CCOProfileReducer,
  search: SearchReducer,
  volunteering: VolunteeringReducer
});
