import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

export default (ComposedComponent: any) => {
    class UnAuth extends Component<RouteComponentProps> {
        checkAuth = () => {
            if(!localStorage.getItem('__is_loggedIn')){
                this.props.history.push('/');
            }
        };

        componentDidMount() {
            this.checkAuth();
        }
        componentDidUpdate() {
            this.checkAuth();
        }

        render() {
            return <ComposedComponent {...this.props} />;
        }
    }

    return UnAuth;
};
