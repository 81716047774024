import React, { Component } from 'react';
import './ConfirmAddress.scss';
import { Button, FormControl } from 'react-bootstrap';
import GoogleMaps from '../../../../components/GoogleMap/GoogleMap';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { valid_postcode } from '../../../Volunteering/components/VolunteeringCreate';

interface AddressProps {
  confirmAddress: (address: any) => void;
  charity_details: any;
}

class ConfirmOrganizationAddress extends Component<AddressProps> {
  state = {
    editAddress: false,
    Line1: this.props.charity_details.Line1,
    Line2: this.props.charity_details.Line2,
    Line3:
      this.props.charity_details.Line3 === undefined
        ? ''
        : this.props.charity_details.Line3,
    Postcode: this.props.charity_details.Postcode,
    organizationName: this.props.charity_details.CharityName,
    latitude: this.props.charity_details.latitude,
    longitude: this.props.charity_details.longitude,
    address: this.props.charity_details.Postcode,
    newAddress: {
      Line1: this.props.charity_details.Line1,
      Line2: this.props.charity_details.Line2,
      Line3:
        this.props.charity_details.Line3 === undefined
          ? ''
          : this.props.charity_details.Line3,
      Postcode: this.props.charity_details.Postcode,
      organizationName: this.props.charity_details.CharityName,
      latitude: this.props.charity_details.latitude,
      longitude: this.props.charity_details.longitude
    },
    postCodeError: true,
    message: ''
  };

  componentDidMount() {
    if (
      (this.props.charity_details === '' ||
        this.props.charity_details === undefined) &&
      localStorage.getItem('session') !== ''
    ) {
      let sessionData: any = localStorage.getItem('session');
      this.setState({
        Line1: JSON.parse(sessionData).Line1,
        Line2: JSON.parse(sessionData).Line2,
        Line3:
          JSON.parse(sessionData).Line3 === undefined
            ? ''
            : JSON.parse(sessionData).Line3,
        Postcode: JSON.parse(sessionData).Postcode,
        organizationName: JSON.parse(sessionData).CharityName,
        latitude: JSON.parse(sessionData).latitude,
        longitude: JSON.parse(sessionData).longitude,
        address: JSON.parse(sessionData).Postcode,
        newAddress: {
          Line1: JSON.parse(sessionData).Line1,
          Line2: JSON.parse(sessionData).Line2,
          Line3:
            JSON.parse(sessionData).Line3 === undefined
              ? ''
              : JSON.parse(sessionData).Line3,
          Postcode: JSON.parse(sessionData).Postcode,
          latitude: JSON.parse(sessionData).latitude,
          longitude: JSON.parse(sessionData).longitude,
          organizationName: JSON.parse(sessionData).CharityName
        }
      });
    }
  }

  handleEditDetails = () => {
    this.setState({
      editAddress: !this.state.editAddress
    });
  };

  handleAddress = (ev: any) => {
    this.setState({
      newAddress: {
        ...this.state.newAddress,
        [ev.target.name]: ev.target.value
      }
    });
  };

  handleCkeckPostcode = (e: any) => {
    let test = valid_postcode(e.target.value);
    this.setState({
      postCodeError: test,
      address: e.target.value
    });
  };

  getAddress = () => {
    this.setState({
      newAddress: {
        ...this.state.newAddress,
        Postcode: this.state.address
      }
    });
    geocodeByAddress(this.state.address)
      .then((results) => this.getAdressss(results))
      .catch((error) =>
        this.setState({
          postCodeError: false
        })
      );
  };

  getAdressss = (result: any) => {
    getLatLng(result[0]).then((latLng) => {
      this.setState(
        {
          newAddress: {
            ...this.state.newAddress,
            latitude: latLng.lat,
            longitude: latLng.lng
          }
        },
        () => {
          this.setState({
            editAddress: true,
            newAddress: {
              ...this.state.newAddress,
              Line1: '',
              Line2: result[0].address_components[1].long_name,
              Line3:
                result[0].address_components[2].long_name +
                ', ' +
                result[0].address_components[3].long_name
            }
          });
        }
      );
    });
  };

  confirmAddress = () => {
    const { newAddress, message } = this.state;
    let sessionData: any = localStorage.getItem('session');
    let address = {
      CharityName: newAddress.organizationName,
      Line1: newAddress.Line1,
      Line2: newAddress.Line2,
      Line3: newAddress.Line3,
      Postcode: newAddress.Postcode,
      message,
      RegisteredCharityNumber:
        this.props.charity_details !== ''
          ? this.props.charity_details.RegisteredCharityNumber
          : JSON.parse(sessionData).RegisteredCharityNumber
    };
    this.props.confirmAddress(address);
  };

  handleInput = (e: any) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      editAddress,
      Line1,
      Line2,
      Line3,
      newAddress,
      Postcode,
      postCodeError,
      message
    } = this.state;

    let disable =
      (this.state.organizationName !== undefined &&
        this.state.organizationName.length === 0) ||
      this.state.newAddress?.Line1?.length === 0;
    return (
      <div className="register-cont-block">
        <div className="part-1-1" />
        {
          <div className="part-2" style={{ overflow: 'auto' }}>
            <div className="part-2-header">
              <div className="title">CONFIRM ADDRESS DETAILS</div>
              <div className="sub-title">
                These are the details we found from the Govt. & will only be
                used to associate your account with the charity.
              </div>
            </div>
            <div className="registerAddressCont">
              <div className="title">Registered Address</div>
              <div className="address">
                <span>{this.state.organizationName}</span>
                <span>
                  {Line1 + ','} {Line2 + ','} {Line3 + ','} {Postcode}
                </span>
              </div>
            </div>

            <div className="address-cont">
              <div className="name-form-group">
                <label className="label">
                  Type the Organisation name as you would want to be shown:
                </label>
                <FormControl
                  type="text"
                  value={newAddress.organizationName || ''}
                  onChange={this.handleAddress}
                  name="organizationName"
                />
              </div>
              <div className="map-cont">
                {newAddress.latitude !== undefined && newAddress.longitude && (
                  <GoogleMaps
                    lat={newAddress.latitude}
                    lon={newAddress.longitude}
                  />
                )}
              </div>

              <div className="name-form-group">
                <label className="label">
                  If you need physical deliveries to go to a different address
                  rather than the registered address above, please enter the new
                  postcode here
                </label>
                <label className="label">Enter postcode</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControl
                    type="text"
                    name="address"
                    onChange={this.handleCkeckPostcode}
                    placeholder="E.g TW74HG"
                    value={this.state.address}
                    style={{ width: '30rem' }}
                  />
                  <div
                    onClick={() => postCodeError && this.getAddress()}
                    className={
                      postCodeError
                        ? 'get-address-btn'
                        : 'get-address-btn-disable'
                    }
                  >
                    Get Address
                  </div>
                </div>
                {this.state.postCodeError !== null &&
                  !this.state.postCodeError && (
                    <label className="error-msg">Enter valid postcode</label>
                  )}
              </div>

              {editAddress && (
                <div className="form-conts">
                  <div className="edit-form-group">
                    <label className="label">Address Line 1 *</label>
                    <FormControl
                      type="text"
                      value={newAddress.Line1}
                      onChange={this.handleAddress}
                      name="Line1"
                    />
                  </div>
                  <div className="edit-form-group">
                    <label className="label">Address Line 2</label>
                    <FormControl
                      type="text"
                      value={newAddress.Line2}
                      onChange={this.handleAddress}
                      name="Line2"
                    />
                  </div>
                  <div className="edit-form-group">
                    <label className="label">Area</label>
                    <FormControl
                      type="text"
                      value={newAddress.Line3}
                      onChange={this.handleAddress}
                      name="Line3"
                    />
                  </div>
                  <div className="edit-form-group">
                    <label className="label">
                      Please provide a little more info if you can, to help us
                      approve your registration
                    </label>
                    <FormControl
                      as="textarea"
                      type="text"
                      value={message}
                      onChange={this.handleInput}
                      name="message"
                    />
                  </div>
                </div>
              )}
            </div>

            <Button
              className={disable ? 'btn-disable' : 'btn-primary btn'}
              onClick={this.confirmAddress}
              disabled={disable}
            >
              Confirm
            </Button>
          </div>
        }
      </div>
    );
  }
}

export default ConfirmOrganizationAddress;
