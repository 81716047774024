import React from 'react';
import trimText from './trimText';

import './readmore.scss';

interface ReadMoreReactProps {
  text: string;
  min?: any;
  ideal?: any;
  max?: any;
  readMoreText?: any;
}

const getText = (args: any) => {
  const [primaryText, secondaryText] = trimText(...args);
  return {
    primaryText,
    secondaryText
  };
};

export default class ReadMoreReact extends React.Component<ReadMoreReactProps> {
  args = [this.props.text, this.props.min, this.props.ideal, this.props.max];
  state = {
    displaySecondary: false,
    primaryText: getText(this.args).primaryText,
    secondaryText: getText(this.args).secondaryText,
    readMoreText: 'read more'
  };

  setStatus() {
    let display = !this.state.displaySecondary;
    this.setState({ displaySecondary: display });
  }

  render() {
    let displayText;
    if (!this.state.secondaryText) {
      displayText = (
        <div className="display-text-group">
          <span className="displayed-text">
            {`${this.state.primaryText} ${this.state.secondaryText}`}
          </span>
        </div>
      );
    } else if (this.state.displaySecondary) {
      displayText = (
        <div className="display-text-group">
          <span className="displayed-text" onClick={this.setStatus.bind(this)}>
            {`${this.state.primaryText} ${this.state.secondaryText}`}
          </span>
          {this.state.displaySecondary && (
            <span
              className="read-more-button"
              onClick={this.setStatus.bind(this)}
            >
              {" "}read less
            </span>
          )}
        </div>
      );
    } else {
      displayText = (
        <div className="display-text-group">
          <span className="displayed-text">
            {this.state.primaryText}
            <span style={{ display: 'none' }}>{this.state.secondaryText}</span>
            <span
              className="read-more-button"
              onClick={this.setStatus.bind(this)}
            >
              {` ${this.state.readMoreText}`}
            </span>
          </span>
        </div>
      );
    }

    return displayText;
  }
}
