import React, { Component } from 'react';
import { pushLogout, doLogout } from '../config/constants';
import { notify } from '../components/Notifier/Notifier';

export default (ComposedComponent: any) => {
  class Authentication extends Component {
    warnTimeout: any;
    logoutTimeout: any;
    events: any;
    state = {
      profile_loaded: false,
      warningTime: 1000 * 10 * 60,
      signoutTime: 1000 * 10 * 65
    };

    checkAuth = () => {
      let token = localStorage.getItem('__auth')
      if (!token) {
        pushLogout();
      }
    };

    componentDidMount() {
      this.checkAuth();
      this.events = [
        'load',
        'mousemove',
        'mousedown',
        'click',
        'scroll',
        'keypress'
      ];

      for (var i in this.events) {
        window.addEventListener(this.events[i], this.resetTimeout);
      }

      this.setTimeout();
    }

    clearTimeoutFunc = () => {
      if (this.warnTimeout) clearTimeout(this.warnTimeout);

      if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    };

    setTimeout = () => {
      this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
      this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
    };

    resetTimeout = () => {
      this.clearTimeoutFunc();
      this.setTimeout();
    };

    warn = () => {
      notify.info('Info', 'You will be logged out automatically in 1 minute');
    };

    logout = () => {
      this.destroy();
    };

    destroy = () => {
      doLogout();
    };

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  return Authentication;
};
