import React, { Component } from "react";
import "./ServiceQuestionaries.scss";
import { Button } from "react-bootstrap";

interface ServiceQuestionariesProps {
  confirmConfiguration: (services: any) => void;
  organizationName: string;
  services: any;
}

class ServicesQutionaries extends Component<ServiceQuestionariesProps> {
  state = {
    services: this.props.services,
    selectedService: [] as any,
  };

  selectService = (data: any) => {
    if (this.state.selectedService.indexOf(data) === -1)
      this.setState({
        selectedService: this.state.selectedService.concat(data),
      });
    else {
      this.setState({
        selectedService: this.state.selectedService.filter((d: any) => {
          if (d === data) {
            return null;
          }
          return d;
        }),
      });
    }
  };

  render() {
    const { services, selectedService } = this.state;
    const { organizationName } = this.props;
    return (
      <div className="register-cont-block">
        <div className="part-1-2" />
        <div className="part-2">
          <div className="part-2-header">
            <div className="title">{organizationName}</div>
            <div className="sub-title">Please select all that apply</div>
          </div>

          <div className="select-services-cont">
            {services !== "" &&
              services.map((data: any, index: any) => (
                <div
                  key={index}
                  className={
                    this.state.selectedService.some((d: any) => d === data)
                      ? "services service-active"
                      : "services"
                  }
                  onClick={() => this.selectService(data)}
                >
                  {data}
                </div>
              ))}
          </div>
          <Button
            className={
              selectedService.length === 0
                ? "btn-disable btn"
                : "btn-primary btn"
            }
            onClick={() =>
              this.props.confirmConfiguration(this.state.selectedService)
            }
          >
            Done
          </Button>
        </div>
      </div>
    );
  }
}

export default ServicesQutionaries;
