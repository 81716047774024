import React, { Component } from "react";
import GoogleMapReacts from "google-map-react";

const Marker = (props: any) => {
  return (
    <div className="SuperAwesomePin">
      <i
        className="fa fa-map-marker"
        style={{ color: "#dc3545", fontSize: "2.4rem" }}
      ></i>
    </div>
  );
};

interface GoogleMapProps {
  lat: string;
  lon: string;
}

class GoogleMaps extends Component<GoogleMapProps> {
  state = {
    defaultCenter: {
      lat: parseFloat(this.props.lat),
      lng: parseFloat(this.props.lon),
    },
    updatedCenter : {
      lat: parseFloat(this.props.lat),
      lng: parseFloat(this.props.lon),
    },
    zoom: 14,
  };


  componentDidUpdate(prevProps: GoogleMapProps) {
    if(this.props.lat && this.props.lat !== prevProps.lat) {
      this.setState({
        updatedCenter: {
          lat: parseFloat(this.props.lat),
          lng: parseFloat(this.props.lon),
        },
      });
    }
  }

  render() {
    const defaultMapOptions = {
      fullscreenControl: false,
    };
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <GoogleMapReacts
          bootstrapURLKeys={{ key: "AIzaSyD0Y25-a81Fxe78qTY_4fUi6F8isqwN7SI"}}
          defaultCenter={this.state.defaultCenter}
          defaultZoom={this.state.zoom}
          center={{lat: this.state.updatedCenter.lat, lng: this.state.updatedCenter.lng}}
          draggable={false}
          options={defaultMapOptions}
        >
          <Marker
            lat={this.state.updatedCenter.lat}
            lng={this.state.updatedCenter.lng}
          />
        </GoogleMapReacts>
      </div>
    );
  }
}

export default GoogleMaps;
