import React, { Component } from 'react';
import ProfileContainer, {
  CCOProfileProps
} from '../container/ProfileContainer';
import Header from '../../../components/Header/Header';
import './CCOProfile.scss';
import OrganizationProfile from './OrganisationProfile';
import ManageTeam from './ManageTeam';
import MyProfile from './MyProfile';
import Dashboard from './Dashboard';

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  withRouter,
  RouteComponentProps
} from 'react-router-dom';
import MyDonations from './MyDonations';
import Campaigns from '../../Campaigns/components/Campaigns';

class CCOProfile extends Component<CCOProfileProps & RouteComponentProps> {

  


  getNavList = () => {
    const list = [];
    let is_user_logged_in = localStorage.getItem('__is_user_loggedIn');

    if (localStorage.getItem('__is_loggedIn')) {
      list.push({
        label: 'Dashboard',
        to: '/profile/dashboard'
      });
    }

    list.push({
      label: 'My Profile',
      to: '/profile/my_profile'
    });

    if (localStorage.getItem('__is_loggedIn')) {
      list.push({
        label: 'My Wishlists',
        to: '/profile/my_wishlist'
      });
    }

    if (is_user_logged_in) {
      list.push({
        label: 'My Donations',
        to: '/profile/my_donations'
      });
    }

    // if (localStorage.getItem('__is_loggedIn')) {
    //   list.push({
    //     label: 'Organisation Profile',
    //     to: '/profile/organisation_profile'
    //   });
    // }

    // if (localStorage.getItem('__is_loggedIn')) {
    //   list.push({
    //     label: 'Manage Teams',
    //     to: '/profile/invite_team'
    //   });
    // }

    return list;
  };

  render() {
    const { pathname } = window.location;
    let is_user_logged_in = localStorage.getItem('__is_user_loggedIn');
    return (
      <div>
        <Header />
        <div className="cco-profile-cont">
          <div className="cco-profile-cont__sidebar">
            {this.getNavList().map((item: any) => (
              <div className="cco-profile-cont__item" key={item.to}>
                <a
                  href={item.to}
                  className={
                    pathname === item.to
                      ? 'cco-profile-cont__item--active'
                      : 'cco-profile-cont__item'
                  }
                >
                  {item.label}
                </a>
              </div>
            ))}
          </div>
          <div className="cco-profile-cont__content">
            <Router>
              <Switch>
                <Route path={`/profile/dashboard`} component={Dashboard} />

                <Route path={`/profile/my_profile`} component={MyProfile} />
                <Route path={`/profile/my_wishlist`} component={Campaigns} />

                <Route
                  path={`/profile/organisation_profile`}
                  component={OrganizationProfile}
                />
                <Route path={`/profile/invite_team`} component={ManageTeam} />
                <Route path={`/profile/my_donations`} component={MyDonations} />
                <Redirect
                  from={`/profile`}
                  to={
                    is_user_logged_in
                      ? `/profile/my_profile`
                      : `/profile/dashboard`
                  }
                />
              </Switch>
            </Router>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileContainer(withRouter(CCOProfile));
