import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

interface ChooseUserTypeProps {
  setUserGroup: (user: any) => void;
  usergroup: any;
}

class ChooseUserType extends Component<ChooseUserTypeProps> {
  render() {
    const { usergroup, setUserGroup } = this.props;
    return (
      <div className="part-2">
        <div className="part-2-header">
          <div className="title">Welcome to HelpBritain</div>
          <div className="sub-title">
            Let’s get you all set up so your organisation can be verified and
            you can begin setting up wishlists!
          </div>
        </div>

        <div className="choose-user-wrapper">
          <div className="choose-user">
            <div className="choose-user__header">
              <input
                type="radio"
                id="chooseUser1"
                name="radio-group"
                checked={usergroup === 'cco_admin'}
                onChange={() => setUserGroup('cco_admin')}
              />
              <label htmlFor="chooseUser1">I work for a charity</label>
            </div>
          </div>
          <div className="choose-user">
            <div className="choose-user__header">
              <input
                type="radio"
                id="chooseUser2"
                name="radio-group"
                checked={usergroup === 'hb_user'}
                onChange={() => setUserGroup('hb_user')}
              />
              <label htmlFor="chooseUser2">I am a Donor / Volunteer</label>
            </div>
          </div>
          <div className="login-nav">
            Already have an account? -{' '}
            <NavLink to="/auth/login">Login here</NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default ChooseUserType;
