import React, { Component, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import QueryString from 'query-string';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './Causes.scss';
import CauseContainer, { CauseProps } from '../container/CausesContainer';
import Loader from '../../../components/Loader/Loader';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import CauseCard from './CauseCard';
import { withRouter } from 'react-router';
import Select from '../../../components/Select';
import noResultsFound from '../../../images/no-resultfound.png';

interface QueryString {
  [key: string]: any;
}

export const calculate = (data: any) => {
  let sumOfPurchasedAmount = 0;
  let sumOfRequestedAmount = 0;

  for (var i = 0; i < data.length; i++) {
    sumOfPurchasedAmount += parseInt(data[i].PurchasedAmount);
  }

  for (i = 0; i < data.length; i++) {
    sumOfRequestedAmount += parseInt(data[i].RequestedAmount);
  }
  return (
    <ProgressBar now={(sumOfPurchasedAmount / sumOfRequestedAmount) * 100} />
  );
};

class AllCauses extends Component<CauseProps & RouteComponentProps<{}>> {
  state = {
    isFiltered: false,
    filterInfo: ''
  };

  componentDidMount() {
    const {
      location: { search },
      match: { url },
      getFilteredCampaigns,
      getCampaigns,
      current_location
    } = this.props;
    const queryParams = QueryString.parse(search);
    if (Object.keys(queryParams).length <= 0) {
      this.props.history.push(`${url}?category=all`);
    }

    if (queryParams.category !== undefined && queryParams.category !== 'all') {
      return getFilteredCampaigns(queryParams.category, current_location);
    }

    getCampaigns();
  }

  componentDidUpdate(prevProps: any) {
    const {
      match: { url },
      location: { search },
      current_location,
      getFilteredCampaigns
    } = this.props;

    const queryParams = QueryString.parse(search);
    if (Object.keys(queryParams).length <= 0) {
      this.props.history.push(`${url}?category=all`);
    }

    if (current_location && current_location !== prevProps.current_location) {
      getFilteredCampaigns(queryParams.category, current_location);
    }
  }

  getCategoryFilterList = () => {
    const {
      categories,
      location: { search }
    } = this.props;
    const queryParams = QueryString.parse(search);
    const list = categories !== undefined ? [
      {
        label: 'All Categories',
        value: 'all'
      },
      ...categories.map((c: any) => ({
        label: c,
        value: c
      }))
    ] : [
      {
        label: 'All Categories',
        value: 'all'
      }
    ];

    return {
      list,
      active: list.filter((l) => l.value === queryParams.category).shift()
    };
  };

  handleFilter = (key: String, option: any) => {
    const {
      location: { search },
      match: { url },
      history,
      getFilteredCampaigns,
      current_location
    } = this.props;
    let queryParams: QueryString = QueryString.parse(search);
    queryParams = {
      ...queryParams,
      // @ts-ignore
      [key]: option.value
    };

    history.push(`${url}?${QueryString.stringify(queryParams)}`);
    getFilteredCampaigns(queryParams.category, current_location);
  };

  render() {
    const { campaignInfo, loading } = this.props;
    const campaigns_list = campaignInfo.filter(
      (c: any) => c.campaigns !== undefined
    );
    return (
      <Fragment>
        {loading && <Loader />}
        <Header />
        <div className="all-causes">
          <div className="all-causes__header">
            <h4 className="all-causes__header--title">Organisations</h4>
            <div className="vl__filter">
              <Select
                label="CATEGORY"
                options={this.getCategoryFilterList().list}
                value={this.getCategoryFilterList().active}
                onChange={(option: any) =>
                  this.handleFilter('category', option)
                }
                className="vl__category"
              />
              <div className="vl__charity" />
            </div>
          </div>
          <div className="all-causes__body">
            <div className="all-causes__content">
              {campaigns_list.filter((ds: any) => ds?.charityStatus?.toUpperCase() === "APPROVED" || ds?.charityStatus?.toUpperCase() === "REAPPROVAL" ).map((cause: any, index: number) => (
                <CauseCard key={index} cause={cause} />
              ))}
            </div>
          </div>
          {!campaigns_list.filter((ds: any) => ds?.charityStatus?.toUpperCase() === "APPROVED").length && (
            <div className="all-causes__empty">
              <img
                src={noResultsFound}
                alt="no results"
                className="all-causes__empty--image"
              />
            </div>
          )}
        </div>
        {!loading && <Footer />}
      </Fragment>
    );
  }
}

export default CauseContainer(withRouter(AllCauses));
