import axios from 'axios';
import { adminAuthorizer } from './constants';

export const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export const getHeader = () => {
  const is_admin_loggedin = localStorage.getItem('__is_admin_loggedIn');
  let token: any = is_admin_loggedin
    ? adminAuthorizer.getHeader()
    : localStorage.getItem('__auth');
  return (axios.defaults.headers.common['Authorization'] = `Bearer ${token}`);
};
