import { connect } from 'react-redux';
import { getHomeBanner, getHomeCards } from '../../../services/Home/actions';

interface StateProps {
  loading: boolean;
  campaignInfo: any[];
  current_location: string;
}

interface DispatchProps {
  getHomeBanner: () => void;
  getCampaigns: () => void;
}

export interface HomeProps extends StateProps, DispatchProps {}

const mapStateToProps = (state: any): StateProps => {
  return {
    loading: state.home.campaigns_loading,
    campaignInfo: state.home.campaign_card_info,
    current_location: state.search.location,
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    getHomeBanner: () => dispatch(getHomeBanner()),
    getCampaigns: () => dispatch(getHomeCards())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
