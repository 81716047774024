import * as types from './types';

const initialState: types.CCOProfileState = {
  fetch_profile_loading: false,
  fetch_profile_succeeded: false,
  fetch_profile_failed: false,
  cco_profile_info: '',

  fetch_org_profile_loading: false,
  fetch_org_profile_succeeded: false,
  fetch_org_profile_failed: false,
  cco_org_profile_info: '',

  save_org_profile_loading: false,
  save_org_profile_succeeded: false,
  save_org_profile_failed: false,
  org_profile_info: {},

  search_org_loading: false,
  search_org_succeeded: false,
  search_org_failed: false,
  search_org_info: '',

  invite_user_loading: false,
  invite_user_succeeded: false,
  invite_user_failed: false,
  team_user_info: '',

  filters_loading: false,
  filters_succeeded: false,
  filters_failed: false,
  filters_data: [],

  change_status_loading: false,
  change_status_succeeded: false,
  change_status_failed: false,

  locations_loading: false,
  locations_succeeded: false,
  locations_failed: false,
  locations_data: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.USER_LOGOUT:
      return {
        ...state,
        cco_profile_info:"",

      };
    case types.CCO_PROFILE_REQUESTED:
      return {
        ...state,
        fetch_profile_loading: true,
        fetch_profile_failed: false,
        fetch_profile_succeeded: false
      };
    case types.CCO_PROFILE_SUCCEEDED:
      return {
        ...state,
        fetch_profile_loading: false,
        fetch_profile_succeeded: true,
        fetch_profile_failed: false,
        cco_profile_info: action.payload.data
      };
    case types.CCO_PROFILE_FAILED:
      return {
        ...state,
        fetch_profile_loading: false,
        fetch_profile_succeeded: false,
        fetch_profile_failed: true
      };

    case types.CCO_ORG_PROFILE_REQUESTED:
      return {
        ...state,
        fetch_org_profile_loading: true,
        fetch_org_profile_failed: false,
        fetch_org_profile_succeeded: false
      };
    case types.CCO_ORG_PROFILE_SUCCEEDED:
      return {
        ...state,
        fetch_org_profile_loading: false,
        fetch_org_profile_succeeded: true,
        fetch_org_profile_failed: false,
        cco_org_profile_info: action.payload.data.data
      };
    case types.CCO_ORG_PROFILE_FAILED:
      return {
        ...state,
        fetch_org_profile_loading: false,
        fetch_org_profile_succeeded: false,
        fetch_org_profile_failed: true
      };

    case types.SAVE_ORG_PROFILE_REQUESTED:
      return {
        ...state,
        save_org_profile_loading: true,
        save_org_profile_failed: false,
        save_org_profile_succeeded: false
      };
    case types.SAVE_ORG_PROFILE_SUCCEEDED:
      return {
        ...state,
        save_org_profile_loading: false,
        save_org_profile_succeeded: true,
        save_org_profile_failed: false,
        org_profile_info: action.payload.data
      };
    case types.SAVE_ORG_PROFILE_FAILED:
      return {
        ...state,
        save_org_profile_loading: false,
        save_org_profile_succeeded: false,
        save_org_profile_failed: true
      };

    case types.INVITE_USER_REQUESTED:
      return {
        ...state,
        invite_user_loading: true,
        invite_user_failed: false,
        invite_user_succeeded: false
      };
    case types.INVITE_USER_SUCCEEDED:
      return {
        ...state,
        invite_user_loading: false,
        invite_user_succeeded: true,
        invite_user_failed: false,
        team_user_info: action.payload.data.data
      };
    case types.INVITE_USER_FAILED:
      return {
        ...state,
        invite_user_loading: false,
        invite_user_succeeded: false,
        invite_user_failed: true
      };

    case types.FILTER_REQUESTED:
      return {
        ...state,
        filters_loading: true,
        filters_failed: false,
        filters_succeeded: false
      };
    case types.FILTER_SUCCEEDED:
      return {
        ...state,
        filters_loading: false,
        filters_succeeded: true,
        filters_failed: false,
        filters_data: action.payload.data
      };
    case types.FILTER_FAILED:
      return {
        ...state,
        filters_loading: false,
        filters_succeeded: false,
        filters_failed: true
      };

    case types.CHANGE_STATUS_REQUESTED:
      return {
        ...state,
        change_status_loading: true,
        change_status_failed: false,
        change_status_succeeded: false
      };
    case types.CHANGE_STATUS_SUCCEEDED:
      return {
        ...state,
        change_status_loading: false,
        change_status_succeeded: true,
        change_status_failed: false
      };
    case types.CHANGE_STATUS_FAILED:
      return {
        ...state,
        change_status_loading: false,
        change_status_succeeded: false,
        change_status_failed: true
      };

    case types.LOCATIONS_REQUESTED:
      return {
        ...state,
        locations_loading: true,
        locations_failed: false,
        locations_succeeded: false
      };
    case types.LOCATIONS_SUCCEEDED:
      return {
        ...state,
        locations_loading: false,
        locations_succeeded: true,
        locations_failed: false,
        locations_data: action.payload.data
      };
    case types.LOCATIONS_FAILED:
      return {
        ...state,
        locations_loading: false,
        locations_succeeded: false,
        locations_failed: true
      };

    default:
      return state;
  }
};
