import React, { Component, Fragment } from 'react';
import './ManageOrganisations.scss';
import { Table } from 'react-bootstrap';
import Loader from '../../../components/Loader/Loader';
import OrganisationContainer, {
  AdminOrgProps
} from '../container/OrganisationContainer';
import swal from 'sweetalert';
import Select from '../../../components/Select';

class Organisations extends Component<AdminOrgProps> {
  state = {
    show_dropdown_index: null,
    openFeaturedConfirmModal: false,
    fliteredValue: { value: 'Under Review', label: 'Under Review' }
  };

  componentDidMount() {
    this.props.getAllOrganisations();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  myRef: any = React.createRef();

  setDropDown = (i: any) => {
    const { show_dropdown_index } = this.state;
    this.setState({
      show_dropdown_index: show_dropdown_index === i ? null : i
    });
  };

  handleClickOutside = (e: any) => {
    if (this.myRef.current !== null && !this.myRef.current.contains(e.target)) {
      this.setDropDown(null);
    }
  };

  setStatus = (email: any, params: any) => {
    this.setState(
      {
        show_dropdown_index: null
      },
      () => {
        this.props.changeCCOStatus(
          email,
          params?.toUpperCase(),
          this.state.fliteredValue.value === 'Reapproval' ? true : false
        );
      }
    );
  };

  opneFeaturedOrgModal = (d: any) => {
    swal({
      title: `Are you sure?`,
      text: `You want to set ${d.CharityName.toLowerCase()} as featured organisation.`,
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancel', 'Confirm']
    }).then((result: any) => {
      if (result !== null) {
        this.props.setFeatured(
          d.id,
          'charity',
          d.CharityName,
          d.featured === 'false' ? 'true' : 'false'
        );
      }
    });
  };

  handleListShow = (option: any) => {
    this.setState({
      fliteredValue: option
    });
  };

  getStatus = () => {
    let data = [];
    if (
      this.state.fliteredValue.value === 'Under Review' ||
      this.state.fliteredValue.value === 'Reapproval'
    ) {
      data.push('Approved', 'Rejected', 'Archived');
    }

    if (
      this.state.fliteredValue.value === 'Approved' ||
      this.state.fliteredValue.value === 'Rejected'
    ) {
      data.push('Archived');
    }

    if (
      this.state.fliteredValue.value === 'Archived' ||
      this.state.fliteredValue.value === 'Rejected'
    ) {
      data.push('Approved');
    }

    return data;
  };

  render() {
    const { show_dropdown_index } = this.state;
    let options = [
      {
        value: 'Under Review',
        label: 'Under Review'
      },
      {
        value: 'Approved',
        label: 'Approved'
      },
      {
        value: 'Reapproval',
        label: 'Reapproval'
      },
      {
        value: 'Rejected',
        label: 'Rejected'
      },
      {
        value: 'Archived',
        label: 'Archived'
      }
    ];
    return (
      <Fragment>
        {(this.props.organisationsInfo === '' || this.props.loading) && (
          <Loader />
        )}
        {this.props.organisationsInfo !== '' && (
          <div className="organisatoins-cont">
            <div className="manage-heading">
              <Select
                options={options}
                className="vl__category v-select-small"
                value={this.state.fliteredValue}
                onChange={this.handleListShow}
              />
            </div>
            <div className="org-cont">
              <div className="table-cont">
                {this.props.organisationsInfo
                  .filter((ds: any) => ds.flag?.toString() === '4')
                  .filter(
                    (dss: any) =>
                      dss?.charityStatus?.toUpperCase() ===
                      this.state.fliteredValue.value?.toUpperCase()
                  ).length > 0 ? (
                  <Table striped>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Registered Address</th>
                        <th>Delivery Address</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.organisationsInfo
                        .filter((ds: any) => ds.flag?.toString() === '4')
                        .filter(
                          (dss: any) =>
                            dss?.charityStatus?.toUpperCase() ===
                            this.state.fliteredValue.value?.toUpperCase()
                        )
                        .map((d: any, i: any) => (
                          <tr key={i}>
                            <td>
                              <div
                                className="icon-cont center-cont"
                                onClick={() => this.opneFeaturedOrgModal(d)}
                              >
                                {d.featured === 'false' && (
                                  <i className="fa fa-star-o icon" />
                                )}
                                {d.featured === 'true' && (
                                  <i className="fa fa-star icon-active" />
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="orga-cont">
                                {d.charityNameUrl !== undefined ? (
                                  <a
                                    href={`/charity/${d.charityNameUrl}`}
                                    className="text-1"
                                    onClick={() =>
                                      localStorage.setItem('cco', d.id)
                                    }
                                  >
                                    {d.CharityName}
                                  </a>
                                ) : (
                                  <div className="dis">{d.CharityName}</div>
                                )}
                                <div>{d.RegisteredCharityNumber}</div>
                              </div>
                            </td>
                            <td>
                              <div className="center-cont">
                                {d.Line1 +
                                  ', ' +
                                  d.Line2 +
                                  ', ' +
                                  d.Line3 +
                                  ', ' +
                                  d.Postcode}
                              </div>
                            </td>
                            <td>
                              {d?.newDetails?.deliveryAddress !== undefined ? (
                                <div className="center-cont">
                                  {d?.newDetails.deliveryAddress?.Line1 +
                                    ', ' +
                                    d?.newDetails.deliveryAddress?.Line2 +
                                    ', ' +
                                    d?.newDetails.deliveryAddress?.Line3 +
                                    ', ' +
                                    (d?.newDetails.deliveryAddress?.Postcode !==
                                    undefined
                                      ? d?.newDetails.deliveryAddress?.Postcode
                                      : d?.newDetails.deliveryAddress
                                          ?.PostCode)}
                                </div>
                              ) : d.deliveryAdress !== undefined ? (
                                <div className="center-cont">
                                  {d?.deliveryAdress?.Line1 +
                                    ', ' +
                                    d?.deliveryAdress?.Line2 +
                                    ', ' +
                                    d?.deliveryAdress?.Line3 +
                                    ', ' +
                                    (d?.deliveryAdress?.Postcode !== undefined
                                      ? d?.deliveryAdress?.Postcode
                                      : d?.deliveryAddress?.PostCode)}
                                </div>
                              ) : (
                                ''
                              )}
                            </td>
                            <td>
                              <div className="center-cont status-cont">
                                <i
                                  className={
                                    d?.charityStatus?.toUpperCase() ===
                                    'UNDER REVIEW'
                                      ? 'fa fa-circle ur'
                                      : d?.charityStatus?.toUpperCase() ===
                                        'ARCHIVED'
                                      ? 'fa fa-circle ac'
                                      : d?.charityStatus?.toUpperCase() ===
                                        'APPROVED'
                                      ? 'fa fa-circle ap'
                                      : 'fa fa-circle ur'
                                  }
                                ></i>
                                <span>
                                  {d.charityStatus === undefined
                                    ? 'Under Review'
                                    : d.charityStatus}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div
                                className="table-dropdown-cont"
                                ref={this.myRef}
                              >
                                <div
                                  className="table-dropdown"
                                  onClick={() => this.setDropDown(i)}
                                >
                                  <i className="fa fa-angle-down" />
                                </div>
                                {show_dropdown_index === i && (
                                  <DropDown
                                    onClick={(status: any) =>
                                      this.setStatus(d.id, status)
                                    }
                                    myref={this.myRef}
                                    status={d.charityStatus}
                                    allStatus={this.getStatus()}
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="no-result-found">
                    Sorry, we couldn't find any result for{' '}
                    {this.state.fliteredValue.label} Organisations.
                  </div>
                )}{' '}
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const DropDown = ({ onClick, myref, status, allStatus }: any) => {
  return (
    <div className="o-dropdown" ref={myref}>
      {allStatus.map((d: any) => (
        <div className="o-dropdown__item" onClick={() => onClick(d)}>
          {d}
        </div>
      ))}
    </div>
  );
};

export default OrganisationContainer(Organisations);
