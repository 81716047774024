export interface WishlistState {

  fetch_charity_loading: boolean;
  fetch_charity_succeeded: boolean;
  fetch_charity_failed: boolean;
  charity_info: any[];

  fetch_campaign_loading: boolean;
  fetch_campaign_succeeded: boolean;
  fetch_campaign_failed: boolean;
  campaign_info: any[];

  fetch_wishlist_loading: boolean;
  fetch_wishlist_succeeded: boolean;
  fetch_wishlist_failed: boolean;
  wishlist_info: any[];

  publish_loading: boolean;
  publish_succeeded: boolean;
  publish_failed: boolean;
  publish_info: any;
}

export const CHARITY_REQUESTED = "CHARITY_REQUESTED";
export const CHARITY_SUCCEEDED = "CHARITY_SUCCEEDED";
export const CHARITY_FAILED = "CHARITY_FAILED";

export const CAMPAIGNS_REQUESTED = "CAMPAIGNS_REQUESTED";
export const CAMPAIGNS_SUCCEEDED = "CAMPAIGNS_SUCCEEDED";
export const CAMPAIGNS_FAILED = "CAMPAIGNS_FAILED";

export const WISHLIST_REQUESTED = "WISHLIST_REQUESTED";
export const WISHLIST_SUCCEEDED = "WISHLIST_SUCCEEDED";
export const WISHLIST_FAILED = "WISHLIST_FAILED";

export const PUBLISH_REQUESTED = "PUBLISH_REQUESTED";
export const PUBLISH_SUCCEEDED = "PUBLISH_SUCCEEDED";
export const PUBLISH_FAILED = "PUBLISH_FAILED";

export const AMOUNT_REQUESTED = "AMOUNT_REQUESTED";
export const AMOUNT_SUCCEEDED = "AMOUNT_SUCCEEDED";
export const AMOUNT_FAILED = "AMOUNT_FAILED";
