import React, { Component } from "react";
import Header from "../../../../components/Header/Header";
import "./CCO-Login.scss";
import "../CCO-Registration/AuthRegister.scss";
import { FormControl, Button } from "react-bootstrap";
import LoginContainer, {
  LoginCCOProps,
} from "../../container/CCO-Login/LoginContainer";
import Loader from "../../../../components/Loader/Loader";
import ScrollAnimation from "react-animate-on-scroll";
import { validatePassword } from "../CCO-Registration/CCORegister";
import loginleft from "../../../../images/loginleft.jpg";

class CCOLogin extends Component<LoginCCOProps> {
  state = {
    email: "",
    password: "",
    passwordType: "password",
    forgotPassword: false,
    passwordSent: false,
    otp: "",
    newPassword: "",
    errors: {
      newPassword: "",
    },
  };

  componentDidMount() {
    localStorage.removeItem("session");
  }

  handleInputChange = (ev: any) => {
    ev.preventDefault();
    const { name, value } = ev.target;
    let errors = this.state.errors;
    switch (name) {
      case "newPassword":
        errors.newPassword = validatePassword.test(value)
          ? ""
          : "Password must contain at least 1 uppercase & 1 lowercase letter, 1 number and 1 special character, and be a minimum of 8 characters long.";
        break;
      default:
        break;
    }
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  setPasswordType = () => {
    this.setState({
      passwordType:
        this.state.passwordType === "password"
          ? "text"
          : this.state.passwordType === "text"
          ? "password"
          : "text",
    });
  };

  submit = (ev: any, email: string, password: string) => {
    ev.preventDefault();
    this.props.loginCCO(email, password, null);
  };

  handleForgotPasswordModal = () => {
    this.setState({
      email: "",
      forgotPassword: !this.state.forgotPassword,
    });
  };

  sendMyPassword = () => {
    this.props.sendPassword(this.state.email);
  };

  setMyPassword = () => {
    this.props.setPassword(
      this.state.email,
      this.state.otp,
      this.state.newPassword
    );
  };

  componentDidUpdate(prevProps: LoginCCOProps) {
    if (
      this.props.send_password_succeeded &&
      this.props.send_password_succeeded !== prevProps.send_password_succeeded
    ) {
      this.setState({
        passwordSent: true,
      });
    }

    if (
      this.props.set_password_succeeded &&
      this.props.set_password_succeeded !== prevProps.set_password_succeeded
    ) {
      this.setState({
        forgotPassword: false,
        passwordSent: false,
      });
    }
  }

  render() {
    const {
      email,
      password,
      forgotPassword,
      passwordSent,
      otp,
      newPassword,
      errors,
    } = this.state;
    let disable = email.length === 0 || password.length === 0;
    let forgotBtnDisable = email.length === 0;
    let saveNewDisable =
      otp.length === 0 ||
      newPassword.length === 0 ||
      errors.newPassword.length > 0;
    return (
      <div>
        {this.props.loading && <Loader />}
        <Header activeTab="login" />
        <ScrollAnimation animateIn="fadeIn" delay={100}>
          <div className="register-cont">
            <div className="register-cont-block">
              <div className="part-1 " style={{ backgroundImage: `url(${loginleft})`}}>
                {/* <div className="title">Login To your ORGANISATION</div> */}
              </div>
              <div className="cco-login-part-2">
                <form
                  className="login-form"
                  onSubmit={(ev) => this.submit(ev, email, password)}
                >
                  {!forgotPassword && (
                    <div className="welcome">Welcome BacK</div>
                  )}
                  {forgotPassword && (
                    <div className="welcome">Forgot Password</div>
                  )}
                  {!passwordSent && (
                    <div className="form-group">
                      <label className="label">Email *</label>
                      <FormControl
                        type="text"
                        value={email}
                        name="email"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  )}
                  {passwordSent && (
                    <div className="form-group">
                      <label className="label">OTP *</label>
                      <FormControl
                        type="number"
                        value={otp}
                        name="otp"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  )}
                  {passwordSent && (
                    <div className="form-group">
                      <label className="label">New Password *</label>
                      <FormControl
                        type="password"
                        value={newPassword}
                        name="newPassword"
                        onChange={this.handleInputChange}
                      />
                      {newPassword.length > 0 && (
                        <label className="error-msg">
                          {newPassword.length > 0 ? errors.newPassword : ""}
                        </label>
                      )}
                    </div>
                  )}
                  {!forgotPassword && (
                    <div className="form-group">
                      <label className="label">Enter password *</label>
                      <FormControl
                        type={this.state.passwordType}
                        value={password}
                        name="password"
                        onChange={this.handleInputChange}
                      />
                      {this.state.passwordType === "password" ? (
                        <i
                          className="fa fa-eye image"
                          aria-hidden="true"
                          onClick={this.setPasswordType}
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye-slash image"
                          aria-hidden="true"
                          onClick={this.setPasswordType}
                        ></i>
                      )}
                    </div>
                  )}
                  {!forgotPassword && (
                    <Button
                      type="submit"
                      className={
                        disable ? "btn-disable btn" : "btn-primary btn"
                      }
                      disabled={disable}
                    >
                      LOGIN
                    </Button>
                  )}
                  {forgotPassword && (
                    <div className="forgot-btn-cont">
                      {!passwordSent && (
                        <div className="back-cont" style={{ width: "30%", padding: '0' }}>
                          <div
                            className="back"
                            onClick={this.handleForgotPasswordModal}
                            style={{ fontSize: "1.2rem" }}
                          >
                            <i
                              className="fa fa-arrow-circle-o-left"
                              style={{ fontSize: "1.6rem" }}
                            ></i>
                            Cancel
                          </div>
                        </div>
                      )}
                      {!passwordSent && (
                        <Button
                          className={
                            forgotBtnDisable
                              ? "btn-disable btn forgot-btn"
                              : "btn-primary btn forgot-btn"
                          }
                          disabled={forgotBtnDisable}
                          onClick={this.sendMyPassword}
                        >
                          Send my password
                        </Button>
                      )}
                      {passwordSent && (
                        <Button
                          className={
                            saveNewDisable
                              ? "btn-disable btn forgot-btn"
                              : "btn-primary btn forgot-btn"
                          }
                          disabled={saveNewDisable}
                          onClick={this.setMyPassword}
                        >
                          Save new password
                        </Button>
                      )}
                    </div>
                  )}
                  {!forgotPassword && (
                    <div
                      className="forgot-password"
                      onClick={this.handleForgotPasswordModal}
                    >
                      Forgot Password ?
                    </div>
                  )}
                  {!forgotPassword && (
                    <div className="have-account">
                      Don’t have an account? -{" "}
                      <a href="/auth/register">Register</a>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    );
  }
}

export default LoginContainer(CCOLogin);
