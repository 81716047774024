import { connect } from 'react-redux';
import { getSingleVolunteering } from '../../../services/Volunteering/actions';
import { RouteComponentProps } from 'react-router';

interface StateProps {
  volunteering: [];
  current_volunteering: any;
  loading: boolean;
  email: string;
  charityInfo: any;
}

interface DispatchProps {
  getSingleVolunteering: (id: string) => void;
}

interface OwnProps extends RouteComponentProps {
  match: any;
}

export interface VolunteeringDetailsProps
  extends StateProps,
    DispatchProps,
    OwnProps {}

const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => {
  return {
    volunteering: state.volunteering.volunteering_list,
    loading:
      state.volunteering.loading ||
      state.home.campaigns_cards_loading ||
      state.home.admin_campaigns_list_loading,
    charityInfo:
      state.volunteering?.volunteering_list !== undefined &&
      state.volunteering?.volunteering_list,
    current_volunteering:
      state.volunteering?.volunteering_list?.volunteering !== undefined &&
      state.volunteering?.volunteering_list?.volunteering[0],
    email: state.cco_profile.cco_profile_info.email
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    getSingleVolunteering: (id) => dispatch(getSingleVolunteering(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
