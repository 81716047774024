import React, { Component } from 'react';
import { Carousel, Button } from 'react-bootstrap';
import './Home.scss';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import CausesNearMe from '../../Causes-Near-Me/components/Causes';
import HomeContainer, { HomeProps } from '../container/HomeContainer';
import Loader from '../../../components/Loader/Loader';
import ScrollAnimation from 'react-animate-on-scroll';
import ReadMoreReact from '../../../components/ReadMore';

class Home extends Component<HomeProps> {
  state = {
    latitude: 0,
    longitude: 0
  };

  render() {
    const { loading, campaignInfo } = this.props;
    const campaigns_list: any =
      campaignInfo.length > 0
        ? campaignInfo
            .filter((ds: any) => ds.charityStatus !== undefined)
            .filter((d: any) => d.charityStatus.toUpperCase() === 'APPROVED' || d.charityStatus.toUpperCase() === 'REAPPROVAL')
            .filter((c: any) => c.campaigns !== undefined)
        : [];
    let is_cco_loggedin = localStorage.getItem('__is_loggedIn');
    return loading ? (
      <Loader />
    ) : (
      <div>
        <Header />
        <div className="home-container">
          <div className="ad-banner">
            We are new! HelpBritain allows the public to donate goods directly
            to where they’re needed most, and charities to receive donations and
            volunteering offers.{' '}
            <a href="/howitworks" className="how-links">
              Find out more here.
            </a>
          </div>
          <Carousel>
            {campaigns_list.map((d: any) =>
              d.campaigns
                .filter((ds: any) => ds.featured === 'true')
                .map((ds: any, i: number) => (
                  <Carousel.Item key={i}>
                    <div
                      className="banner"
                      style={{
                        backgroundImage: `url(${ds.image})`
                      }}
                    >
                      <div className="banner-contents">
                        <div className="tag-cont">
                          {d?.services?.slice(0, 2).map((d: any, index: any) => (
                            <div className="tag" key={index}>
                              {d}
                            </div>
                          ))}
                        </div>

                        <div className="title">{ds.campaignName}</div>
                        <div className="description">
                          By {d.CharityName.toLowerCase()}.
                        </div>
                        <div className="sub-description">{ds.description}</div>
                        {!is_cco_loggedin && (
                          <a
                            href={`/${d.charityNameUrl}/campaigns/${ds.campaignNameUrl}`}
                            className="btn-primary-green btn"
                          >
                            Donate
                          </a>
                        )}
                      </div>
                    </div>
                  </Carousel.Item>
                ))
            )}
          </Carousel>

          {
            <CausesNearMe
              title={
                this.props.current_location === 'all'
                  ? 'Organisations'
                  : 'Organisations Near Me'
              }
            />
          }

          {campaigns_list.filter((d: any) => d.featured === 'true').length >
            0 && (
            <ScrollAnimation animateIn="fadeIn">
              <div className="featured-container">
                <div
                  className="image-container"
                  style={{
                    backgroundImage: `url(${
                      campaigns_list.filter(
                        (d: any) => d.featured === 'true'
                      )[0]?.banner
                    })`
                  }}
                >
                  <div
                    className="logo-cont"
                    style={{
                      backgroundImage: `url(${
                        campaigns_list.filter(
                          (d: any) => d.featured === 'true'
                        )[0]?.logo
                      })`
                    }}
                  ></div>
                </div>
                <div className="carousel-container">
                  <div className="title">
                    <span>Featured organisation</span>
                    <img
                      src={require('../../../images/shapes/shape2.png')}
                      alt=""
                      className="shape-icon"
                    />
                  </div>

                  {
                    campaigns_list
                      .filter((d: any) => d.featured === 'true')
                      .map((d: any, i: number) => (
                        <div className="contents" key={i}>
                          <div className="organiztion">{d.CharityName}</div>
                          <div className="content-title">{d.oneLiner}</div>
                          <div className="description">
                            <ReadMoreReact
                              min={250}
                              ideal={350}
                              max={1000}
                              text={d.description}
                            />
                          </div>
                          <div className="btn-container">
                            <a
                              href={`/charity/${d.charityNameUrl}`}
                              className="btn"
                            >
                              <span>View Wishlists</span>
                            </a>
                          </div>
                        </div>
                      ))[0]
                  }
                </div>
              </div>
            </ScrollAnimation>
          )}

          <div className="support">
            <div className="layer">
              <div className="part-1">
                <div className="heading">Support HelpBritain.org.uk</div>
                <div className="title">
                  HelpBritain is run as a non-profit. It costs us at least 35p
                  in fees to partner organisations to take a donation, on top of
                  that we have hosting and other costs. HelpBritain does not
                  charge charities any fees to use the platform. If you would
                  like to support us so we can keep supporting charities,
                  vulnerable people around Britain, please click here:
                </div>
                <Button
                  className="btn-primary"
                  onClick={() =>
                    window.open(
                      'https://www.justgiving.com/crowdfunding/help-britain',
                      '_blank'
                    )
                  }
                >
                  Support HelpBritain
                </Button>
              </div>
              <div className="part-2">
                <div className="heading">Connect with us</div>
                <div className="title">
                  Find out more about the needs of British charities and how you
                  can support them, as well as ideas & tips for maximising the
                  impact of your wishlists. Follow us on social media today.
                </div>
                <div className="items social-items">
                  <span
                    className="icon-cont bg-1"
                    onClick={() =>
                      window.open(
                        'https://www.facebook.com/helpbritain',
                        '_blank'
                      )
                    }
                  >
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </span>
                  <span
                    className="icon-cont bg-2"
                    onClick={() =>
                      window.open(
                        'https://www.twitter.com/helpbritain',
                        '_blank'
                      )
                    }
                  >
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </span>
                  <span
                    className="icon-cont bg-3"
                    onClick={() =>
                      window.open(
                        'https://www.instagram.com/helpbritain',
                        '_blank'
                      )
                    }
                  >
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default HomeContainer(Home);
