import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './styles.scss';

const NotFoundIcon = () => (
  <div className="not-found-icon">
    <div className="not-found-icon__dots" />
    <span className="not-found-icon__404">404</span>
  </div>
);

class NotFound extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="not-found">
          <div className="not-found__icon-wrapper">
            <NotFoundIcon />
          </div>
            <h3>Sorry, that page doesn't exist!</h3>
            <div className="not-found__link">
                Head over to the <NavLink className="not-found__link" to="/">{" "}Home Page{" "}</NavLink>
                to choose a new direction.
            </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default NotFound;
