import * as types from "./types";

const initialState: types.RegisterCCOState = {
  fetch_register_cco_loading: false,
  fetch_register_cco_succeeded: false,
  fetch_register_cco_failed: false,

  verify_otp_loading: false,
  verify_otp_succeeded: false,
  verify_otp_failed: false,

  get_charity_types_loading: false,
  get_charity_types_succeeded: false,
  get_charity_types_failed: false,
  charity_types: [],

  get_charity_details_loading: false,
  get_charity_details_succeeded: false,
  get_charity_details_failed: false,
  charity_details: "",

  confirm_charity_details_loading: false,
  confirm_charity_details_succeeded: false,
  confirm_charity_details_failed: false,
  services: "",

  save_services_loading: false,
  save_services_succeeded: false,
  save_services_failed: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.REGISTER_CCO_REQUESTED:
      return {
        ...state,
        fetch_register_cco_loading: true,
        fetch_register_cco_failed: false,
        fetch_register_cco_succeeded: false,
      };
    case types.REGISTER_CCO_SUCCEEDED:
      return {
        ...state,
        fetch_register_cco_loading: false,
        fetch_register_cco_succeeded: true,
        fetch_register_cco_failed: false,
      };
    case types.REGISTER_CCO_FAILED:
      return {
        ...state,
        fetch_register_cco_loading: false,
        fetch_register_cco_succeeded: false,
        fetch_register_cco_failed: true,
      };

    case types.VERIFY_OTP_REQUESTED:
      return {
        ...state,
        verify_otp_loading: true,
        verify_otp_failed: false,
        verify_otp_succeeded: false,
      };
    case types.VERIFY_OTP_SUCCEEDED:
      return {
        ...state,
        verify_otp_loading: false,
        verify_otp_succeeded: true,
        verify_otp_failed: false,
      };
    case types.VERIFY_OTP_FAILED:
      return {
        ...state,
        verify_otp_loading: false,
        verify_otp_succeeded: false,
        verify_otp_failed: true,
      };

    case types.GET_CHARITY_TYPES_REQUESTED:
      return {
        ...state,
        get_charity_types_loading: true,
        get_charity_types_failed: false,
        get_charity_types_succeeded: false,
      };
    case types.GET_CHARITY_TYPES_SUCCEEDED:
      return {
        ...state,
        get_charity_types_loading: false,
        get_charity_types_succeeded: true,
        get_charity_types_failed: false,
        charity_types: action.payload.data,
      };
    case types.GET_CHARITY_TYPES_FAILED:
      return {
        ...state,
        get_charity_types_loading: false,
        get_charity_types_succeeded: false,
        get_charity_types_failed: true,
      };

    case types.GET_CHARITY_DETAILS_REQUESTED:
      return {
        ...state,
        get_charity_details_loading: true,
        get_charity_details_failed: false,
        get_charity_details_succeeded: false,
      };
    case types.GET_CHARITY_DETAILS_SUCCEEDED:
      return {
        ...state,
        get_charity_details_loading: false,
        get_charity_details_succeeded: true,
        get_charity_details_failed: false,
        charity_details: action.payload.data,
      };
    case types.GET_CHARITY_DETAILS_FAILED:
      return {
        ...state,
        get_charity_details_loading: false,
        get_charity_details_succeeded: false,
        get_charity_details_failed: true,
      };

    case types.CONFIRM_CHARITY_DETAILS_REQUESTED:
      return {
        ...state,
        confirm_charity_details_loading: true,
        confirm_charity_details_failed: false,
        confirm_charity_details_succeeded: false,
      };
    case types.CONFIRM_CHARITY_DETAILS_SUCCEEDED:
      return {
        ...state,
        confirm_charity_details_loading: false,
        confirm_charity_details_succeeded: true,
        confirm_charity_details_failed: false,
        services: action.payload.data.services,
      };
    case types.CONFIRM_CHARITY_DETAILS_FAILED:
      return {
        ...state,
        confirm_charity_details_loading: false,
        confirm_charity_details_succeeded: false,
        confirm_charity_details_failed: true,
      };

    case types.SAVE_SERVICES_REQUESTED:
      return {
        ...state,
        save_services_loading: true,
        save_services_failed: false,
        save_services_succeeded: false,
      };
    case types.SAVE_SERVICES_SUCCEEDED:
      return {
        ...state,
        save_services_loading: false,
        save_services_succeeded: true,
        save_services_failed: false,
      };
    case types.SAVE_SERVICES_FAILED:
      return {
        ...state,
        save_services_loading: false,
        save_services_succeeded: false,
        save_services_failed: true,
      };

    default:
      return state;
  }
};
