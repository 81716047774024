import React, { Component } from 'react';
import './CreateCampaign.scss';
import CreateCampaign from './CreateCampaign';

interface CampaignListProps {
  handlePublish: (campaignName: string, publishInfo: any) => void;
  goBack: () => void;
}

class CampaignList extends Component<CampaignListProps> {
  state = {
    createItem: false,
    itemId: 0
  };

  handleCreateItem = (itemId: number) => {
    this.setState({
      createItem: true,
      itemId
    });
  };

  handleCancel = () => {
    this.setState({
      createItem: false
    });
  };

  render() {
    const { createItem, itemId } = this.state;
    return (
      <div>
        {!createItem && (
          <div className="create-campaign-cont">
            <div className="campaigns-info">
              <div className="steps-count">Step 1 of 3</div>
              <div className="head">
                <div className="title">CREATE A NEW WISHLIST</div>
                <div className="description">
                  You can now create a wishlist to request for goods from the
                  following providers
                  <br />
                </div>
              </div>
              <div className="items">
                {/* Amazon */}
                <div className="box" onClick={() => this.handleCreateItem(1)}>
                  <div className="logo-cont">
                    <img
                      src={require('../../../images/Dashboard/amazon.jpg')}
                      alt=""
                      className="image"
                    />
                  </div>

                  <div className="info">
                    <div className="information">
                      If you have an amazon wishlist created, you can choose
                      this campaign. All you will need is the wishlist ID.
                    </div>

                    {/* <div className="btn-container">
                      <div className="text">
                        How to get the Wish List ID from Amazon
                      </div>
                    </div> */}
                  </div>
                </div>

                {/* Love2Shop */}
                <div className="box" onClick={() => this.handleCreateItem(2)}>
                  <div className="logo-cont">
                    <img
                      src={require('../../../images/select.png')}
                      alt=""
                      className="image"
                    />
                  </div>

                  <div className="info">
                    <div className="information">
                      Select vouchers are accepted at Tesco, Morrison's, Argos,
                      B&Q and a number of other retailers. See the full list at{' '}
                      <br />
                      <div
                        onClick={() => window.open("https://www.select-your-reward.co.uk/YourChoices", "_blank")}
                        className="anchor-tag"
                      >
                        https://www.select-your-reward.co.uk/YourChoices
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {createItem && (
          <div className="create-campaign-cont">
            <CreateCampaign
              itemId={itemId}
              goBack={this.handleCancel}
              handlePublish={this.props.handlePublish}
            />
          </div>
        )}
      </div>
    );
  }
}

export default CampaignList;
