import React, { Component } from 'react';
import { FormControl, Button } from 'react-bootstrap';

interface ChooseOrganizationProps {
  verifyAndGetDetailsOfOrganization: (
    ev: any,
    charityNumber: string,
    id: string
  ) => void;
}

class ChooseOrganization extends Component<ChooseOrganizationProps> {
  state = {
    charityNumber: ''
  };

  handleCharityNumber = (ev: any) => {
    this.setState({
      charityNumber: ev.target.value
    });
  };

  render() {
    const { verifyAndGetDetailsOfOrganization } = this.props;
    return (
      <div className="form-cont charity-form-cont">
        <form
          className="charity-form-cont"
          onSubmit={(ev) =>
            verifyAndGetDetailsOfOrganization(ev, this.state.charityNumber, '1')
          }
        >
          <div className="success-message">
            Thank you. Your email address has now been verified.
          </div>
          <div className="form-group">
            <label className="label">Enter registered charity number</label>
            <FormControl
              type="text"
              autoComplete="off"
              onChange={this.handleCharityNumber}
            />
          </div>

          <Button
            className={
              this.state.charityNumber.length === 0
                ? 'btn-disable btn'
                : 'btn-primary btn'
            }
            type="submit"
            disabled={this.state.charityNumber.length === 0}
          >
            Get Details
          </Button>
        </form>
      </div>
    );
  }
}

export default ChooseOrganization;
