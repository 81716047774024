import React, { Component } from "react";
import OtpInput from "react-optinput";
import "react-optinput/bundle.css";
import "./OtpModal.scss";
import { Button } from "react-bootstrap";

interface OtpModalProps {
  verifyOtp: (ev: any, otp: string) => void;
  resendOtp: () => void;
  fetch_register_cco_succeeded: boolean;
  verify_otp_failed: boolean
}

let timer: any = 0;

class OtpModal extends Component<OtpModalProps> {
  state = {
    message: "",
    otp: "",
    time: {
      m: "",
      s: "",
    },
    seconds: 30,
    resendOtpClicked: false,
  };

  componentDidUpdate(prevProps: OtpModalProps) {
    if (
      this.props.fetch_register_cco_succeeded &&
      this.props.fetch_register_cco_succeeded !==
        prevProps.fetch_register_cco_succeeded
    ) {
      this.resendOtp();
    }

    if(this.props.verify_otp_failed && this.props.verify_otp_failed !== prevProps.verify_otp_failed) {
      this.setState({
        message: "The OTP submitted seems to be incorrect. Please try again"
      })
    } 
  }

  resendOtp = () => {
    this.startTimer();
    this.setState({
      resendOtpClicked: true,
      message: "We have sent another OTP to your email just now.",
    });
  };

  secondsToTime = (secs: any) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }

  startTimer = () => {
    timer = 0;
    let timeLeftVar = this.secondsToTime(30);
    this.setState({ time: timeLeftVar, seconds: 30 }, () => {
      if (timer === 0 && this.state.seconds > 0) {
        timer = setInterval(this.countDown, 1000);
      }
    });
  };

  countDown = () => {
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    if (seconds === 0) {
      clearInterval(timer);
      this.setState({
        resendOtpClicked: false,
      });
    }
  };

  render() {
    const { otp, resendOtpClicked } = this.state;

    return (
      <form className="otp-container" onSubmit={(ev) => this.props.verifyOtp(ev, this.state.otp)}>
        <div className="title">Verify your email</div>
        <div className="sub-title">
          Please enter the code received to the email address that you used to
          register
        </div>

        <div className="otp-error-message">{this.state.message}</div>

        <div className="otp-input-container">
          <OtpInput
            codeLength={6}
            onInputChange={(value) => {
              this.setState({
                otp: value,
              });
            }}
          />
        </div>
        <div className="btn-container">
          <Button
            className={otp.length < 6 ? "btn-disable btn" : "btn-primary btn"}
            type="submit"
            disabled={otp.length < 6}
          >
            <span>Verify</span>
            <i className="fa fa-angle-right" aria-hidden="true"></i>
          </Button>
        </div>
        <div className="login-cont">
          <span onClick={() => this.props.resendOtp()}>
            <span className={!resendOtpClicked ? "" : "disable-resend"}>
              Resend the verification code{" "}
            </span>
            {resendOtpClicked && <span>{this.state.time.s} seconds left</span>}
          </span>
        </div>
      </form>
    );
  }
}

export default OtpModal;
