import React from 'react';
import { calculate } from './Causes';
import ProgressBar from 'react-bootstrap/ProgressBar';
import defaultImage from '../../../images/shapes/shape1.png';

interface CauseCardProps {
  cause: any;
}

const CauseCard = (props: CauseCardProps) => {
  const { cause } = props;
  return (
    <a
      className="cause-card"
      href={`/charity/${cause.charityNameUrl}`}
    >
      <div
        className="cause-card__img"
        style={{
          backgroundImage: `url(${
            cause.banner !== undefined
              ? cause.banner
              : cause.campaigns !== undefined
              ? cause.campaigns[0].image
              : defaultImage
          })`
        }}
      >
        <div className="overlay">
          <img src={cause.logo} alt="" className="logo" />
        </div>
      </div>
      <div className="cause-card__info">
        <div className="cause-card__organization">{cause.Line3 !== undefined ? cause.Line3 : cause?.Line1}</div>
        <div className="cause-card__title">{cause.CharityName}</div>
        <div className="cause-card__description">{cause.description}</div>

        <div className="cause-card__funds-progress">
          {cause.campaigns !== undefined ? (
            cause.campaigns.map((ds: any) => calculate(ds.wishlist))[0]
          ) : (
            <ProgressBar now={0} />
          )}
        </div>

        {/*<CircularProgress value={10} text="" width="3rem" height="3rem"/>*/}
      </div>
    </a>
  );
};

export default CauseCard;
