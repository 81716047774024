import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import CircularProgress from '../../../components/CircularProgress';

import '../styles/card.scss';
import { RouteComponentProps, withRouter } from 'react-router';

interface VolunteeringCardProps extends RouteComponentProps {
  data: any;
  showDropdown: boolean;
  handleStatus: (campaignName: any, status: any) => void;
}

const VolunteeringCard = (props: VolunteeringCardProps) => {
  const myRef: any = useRef(null);
  const [openStatusDropDown, handleOpenSetStatus] = useState(false);
  const { data, showDropdown, handleStatus } = props;
  const createdDate = moment(new Date(data.created).toUTCString());
  const handleDropdown = (e: any) => {
    e.stopPropagation();
    handleOpenSetStatus(!openStatusDropDown);
  };

  const handleClickOutside = (e: any) => {
    if (myRef.current !== null && !myRef?.current?.contains(e.target)) {
      handleOpenSetStatus(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div
      className="vc"
      onClick={() => props.history.push(`/volunteering/${data.id}`)}
    >
      {showDropdown && (
        <div className="drop-menu" ref={myRef} onClick={handleDropdown}>
          <div className="drop-name">
            <span>{data.vol_status?.toUpperCase() === 'ACTIVE' ? 'Active' : 'Inactive'}</span>
            <span className="fa fa-angle-down" />
          </div>
          {openStatusDropDown && (
            <div className="drop">
              {data.vol_status?.toUpperCase() !== 'ACTIVE' && (
                <span
                  className="items-drop"
                  onClick={() =>
                    handleStatus(
                      data.name,
                      'Active'
                    )
                  }
                >
                  Active
                </span>
              )}
              {data.vol_status?.toUpperCase() === 'ACTIVE' && (
                <span
                  className="items-drop"
                  onClick={() =>
                    handleStatus(
                      data.name,
                      'Archived'
                    )
                  }
                >
                  Inactive
                </span>
              )}
            </div>
          )}
        </div>
      )}
      <div className="vc__image-wrapper">
        <img src={data.bannerImage} className="vc__image" alt="" />
      </div>
      <div className="vc__info">
        <div className="vc__date">
          Created on {createdDate.format('MMM Do yyyy')}
        </div>
        <div className="vc__title">{data.name}</div>
        <div className="vc__venue">{data.address}</div>
        <div className="vc__footer">
          <CircularProgress
            value={
              (100 *
                (data.volunteers !== undefined ? data.volunteers.length : 0)) /
              data.numberOfPeople
            }
            text=""
            width="30px"
            height="30px"
          />
          <div className="vc__needed">
            {data.numberOfPeople} Volunteers needed
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(VolunteeringCard);
