import { connect } from 'react-redux';
import { changeCampaignStatus } from '../../../services/Campaigns/actions';
import {
  getHomeCards,
  getFilteredCards,
  getAdminCampaigns
} from '../../../services/Home/actions';
import { getVolunteeringList } from '../../../services/Volunteering/actions';

interface StateProps {
  categories: any;
  volunteering: any[];
  locations: any[];
  loading: boolean;
  profileEmail: string;
  current_location: string;
}

interface DispatchProps {
  getCampaigns: () => void;
  getFilteredCampaigns: (filter: any, city: any) => void;
  getVolunteeringList: () => void;
  getVolunteeringListAdmin: () => void;
  changeVolunteeringStatus: (campaignName: any, status: any) => void;
}

interface OwnProps {
  title: string;
}

export interface VolunteeringListProps
  extends StateProps,
    DispatchProps,
    OwnProps {}

const mapStateToProps = (state: any): StateProps => {
  return {
    categories: state.cco_profile.filters_data?.uniq,
    locations: state.cco_profile.locations_data,
    profileEmail: state.cco_profile.cco_profile_info.email,
    volunteering: state.volunteering.volunteering_list,
    loading: state.volunteering.loading,
    current_location: state.search.location
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    getCampaigns: () => dispatch(getHomeCards()),
    getVolunteeringList: () => dispatch(getVolunteeringList()),
    changeVolunteeringStatus: (campaignName: any, status: any) =>
      dispatch(changeCampaignStatus('volunteering', campaignName, status)),
    getVolunteeringListAdmin: () => dispatch(getAdminCampaigns()),
    getFilteredCampaigns: (filter: any, city: any) =>
      dispatch(getFilteredCards(filter, city))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
