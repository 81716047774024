import React from 'react';

import '../styles/resultcard.scss';
import { RouteComponentProps, withRouter } from 'react-router';

interface ResultCardProps extends RouteComponentProps {
  bannerImage: string;
  name: string;
  address: string;
  description: string;
  onClick: () => void;
  url: string;
}

const ResultCard = (props: ResultCardProps) => {
  const { bannerImage, name, description } = props;
  return (
    <a className="rc" href={props.url}>
      <div className="rc__image-wrapper">
        <img src={bannerImage} className="rc__image" alt="" />
      </div>
      <div className="rc__info">
        <div className="rc__title">{name}</div>
        <div className="rc__description">{description}</div>
      </div>
    </a>
  );
};

export default withRouter(ResultCard);

export const ResultCardSkeleton = () => {
  return (
    <div className="rcs">
      <div className="rcs__image-wrapper"></div>
      <div className="rcs__info">
        <div className="rcs__title"></div>
        <div className="rcs__description"></div>
        <div className="rcs__venue"></div>
      </div>
    </div>
  );
};
