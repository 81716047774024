import React, { Component } from 'react';
import axios from 'axios';
import { notify } from '../../../components/Notifier/Notifier';
import { baseUrl } from '../../../config/api-config';
import './ManageOrganisations.scss';
import { Table } from 'react-bootstrap';
import Loader from '../../../components/Loader/Loader';

class Dashboard extends Component {
  state = {
    logs: [],
    isLoading: false
  };

  componentDidMount() {
    this.setState({
      isLoading: true
    });
    axios
      .get(baseUrl + '/dashboard/getAllDonations')
      .then((data: any) => {
        this.setState({
          logs: data.data.data.Items,
          isLoading: false
        });
      })
      .catch((error: any) => {
        this.setState({
          isLoading: false
        });
        notify.error('Something went wrong', 'Failed to get donation logs');
      });
  }

  render() {
    return (
      <div className="organisatoins-cont">
        {this.state.isLoading && <Loader />}
        <div className="org-cont">
          <div className="table-cont">
            {this.state.logs.length > 0 && (
              <Table striped>
                <thead>
                  <tr>
                    <th style={{ width: '30%' }}>Charity Name</th>
                    <th style={{ width: '30%' }}>Wishlist Name</th>
                    <th style={{ width: '20%' }}>Donation Amount</th>
                    <th style={{ width: '20%' }}>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.logs.map((d: any) => (
                    <tr>
                      <td>
                        <div className="center-cont">{d?.charityName}</div>
                      </td>
                      <td>
                        <div className="center-cont">{d?.campaignName}</div>
                      </td>
                      <td>
                        <div className="center-cont">£{d?.amount}</div>
                      </td>
                      <td>
                        <div className="center-cont">{d?.date}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {!this.state.isLoading && this.state.logs.length === 0 && (
              <div className="no-result-found">
                Sorry, we couldn't find any donations.
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
