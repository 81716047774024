import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

export default (ComposedComponent: any) => {
  class UnAuth extends Component<RouteComponentProps> {
    checkAuth = () => {
      const isLoggedIn = localStorage.getItem('__is_loggedIn');
      const isAdminLoggedIn = localStorage.getItem('__is_admin_loggedIn');
      const isUserLoggedIn = localStorage.getItem('__is_user_loggedIn');

      if (localStorage.getItem('session')) return;

      if (isUserLoggedIn || isLoggedIn) {
        this.props.history.push('/');
      }

      if (isAdminLoggedIn) {
        this.props.history.push('/admin');
      }
    };

    componentDidMount() {
      this.checkAuth();
    }
    componentDidUpdate() {
      this.checkAuth();
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  return UnAuth;
};
