import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './About.scss';

class About extends Component {
  redirectToOvyo = () => {
    window.open('https://ovyo.com/', '_blank');
  };

  render() {
    return (
      <div>
        <Header activeTab="about" />
        <div className="about-us-cont">
          <div className="about-us-header">
            <div className="title">About Us</div>
            <div className="about-header">
              HelpBritain is a not-for-profit platform launched in summer 2020,
              funded by a grant from Innovate UK in a competition run by the UK
              government with the aim of making the UK more resilient in any
              future lockdown scenarios. We do not charge any fees to use our
              platform.
            </div>
            <div className="block-cont">
              <div className="block">
                <div className="content">
                  The platform was created in response to a large drop-off in
                  donations during the initial Covid lockdown: people were
                  spending less time physically going to stores and community
                  centres where they might otherwise donate goods to food banks
                  and find out about volunteering opportunities nearby, and
                  simply didn’t have the same opportunity anywhere to donate
                  items. HelpBritain now provides a platform for the hugely
                  generous British public to be able to use retailers' existing
                  websites and delivery services to get practical help in the
                  form of physical goods to the people who need them most.
                </div>

                <div className="content">
                  We are currently a very small organisation running on a
                  shoestring but if we can find some sponsorship, we have plans
                  to set ourselves up formally as a charity, develop some
                  aspects of the site further and hire some support staff so we
                  can provide better support to our users.
                </div>
              </div>
              <div className="block">
                <div className="content">
                  Help Britain was launched by{' '}
                  <span
                    onClick={this.redirectToOvyo}
                    style={{
                      color: 'var(--light-magenta)',
                      fontWeight: 'bold',
                      cursor: 'pointer'
                    }}
                  >
                    Ovyo
                  </span>{' '}
                  a Technology Professional Services company based in Brighton
                  and London. We hope that many vulnerable people being cared
                  for by charities and other organisations will be even better
                  cared for as a result of people being able to use the
                  platform.
                </div>

                <div className="content">
                  We are still looking at opportunities to onboard more
                  retailers to the platform to provide greater choice for
                  donations - please reach out if you are an interested retailer
                  who can deliver nationally using the contact page. We are also
                  looking at opportunities to deploy the platform in other
                  countries to help even more people.
                </div>

                <div className="social-links">
                  <span className="social-text">Find us on social media:</span>
                  <span
                    className="icon-cont"
                    onClick={() =>
                      window.open(
                        'https://www.facebook.com/helpbritain',
                        '_blank'
                      )
                    }
                  >
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </span>
                  <span
                    className="icon-cont"
                    onClick={() =>
                      window.open(
                        'https://www.twitter.com/helpbritain',
                        '_blank'
                      )
                    }
                  >
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </span>
                  <span
                    className="icon-cont"
                    onClick={() =>
                      window.open(
                        'https://www.instagram.com/helpbritain',
                        '_blank'
                      )
                    }
                  >
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;
