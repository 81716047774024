import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import CookieConsent from 'react-cookie-consent';

import Home from '../views/Home/components/Home';
import About from '../views/StaticPages/About';
import AuthRegister from '../views/Auth/components/CCO-Registration/Index';
import Wishlist from '../views/OrganisationDetails/components/OrganisationDetails';
import WishListExpand from '../views/CampaignDetails/components/CampaignDetails';
// import ComingSoon from '../views/ComingSoon/ComingSoon';
// import Dashboard from '../views/Dashboard/components/Dashboard';
import requireAuth from '../hoc/require-auth';
import requireAdmin from '../hoc/require-admin';
import requireCcoAdmin from '../hoc/require-cco-admin';
import requireUnAuth from '../hoc/require-un-auth';
import requireNotAdmin from '../hoc/require-not-admin';
// import Campaigns from '../views/Campaigns/components/Campaigns';
import CCOProfile from '../views/CCOProfile/components/CCOProfile';
import AllCauses from '../views/Causes-Near-Me/components/AllCauses';
import UserActivate from '../views/Auth/components/User-Activate/UserActivate';
import VolunteeringList from '../views/Volunteering/components/VolunteeringList';
import VolunteeringDetails from '../views/Volunteering/components/VolunteeringDetails';
import VolunteeringCreate from '../views/Volunteering/components/VolunteeringCreate';
import PricingAndFees from '../views/StaticPages/PricingAndFees';
import CommonQuestions from '../views/StaticPages/CommonQuestions';
import CCOLogin from '../views/Auth/components/CCO-Login/CCO-Login';
import AdminView from '../views/Admin/components';
import NotFound from '../components/NotFound/404';
import TermsAndConditions from '../views/StaticPages/TermsAndConditions';
import HowItWorks from '../views/StaticPages/HowItWorks';
import Privacy from '../views/StaticPages/Privacy';
import Ideas from '../views/StaticPages/Ideas';
import { setCookie } from '../utils/helpers';
import CookiePrivacy from '../views/StaticPages/CookiePrivacy';

const UnAuthRoutes = () => (
  <Switch>
    {/* <Route path="/alpha" component={Home} /> */}
    <Route path="/howitworks" component={HowItWorks} />
    <Route path="/about" component={About} />
    <Route path="/fees" component={PricingAndFees} />
    <Route path="/support" component={CommonQuestions} />
    <Route path="/terms_and_conditions" component={TermsAndConditions} />
    <Route path="/cookie" component={CookiePrivacy} />

    <Route path="/privacy" component={Privacy} />
    <Route path="/ideas" component={Ideas} />
    <Route path="/all-causes" component={AllCauses} />
    <Route
      exact
      path="/volunteering/create"
      component={requireAuth(requireCcoAdmin(VolunteeringCreate))}
    />
    <Route path="/volunteering/:id" component={VolunteeringDetails} />
    <Route exact path="/volunteering" component={VolunteeringList} />
    <Route path="/activate/:user_email/:cco" exact component={UserActivate} />
    <Route exact path="/" component={Home} />

    <Redirect to="/404" />
  </Switch>
);

export const MainRoutes = () => {
  return (
    <div className={`body`}>
      <ToastContainer
        transition={Zoom}
        position="bottom-right"
        autoClose={5000}
      />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="hbcookie"
        style={{ background: '#ffffff', color: '#000000', fontSize: '16px' }}
        buttonStyle={{
          background: 'var(--highlight-primary)',
          color: '#ffffff',
          width: '12rem',
          borderRadius: '.4rem',
          height: '3rem',
          fontSize: '1.4rem',
          outline: 'none'
        }}
        enableDeclineButton
        declineButtonStyle={{
          background: 'transparent',
          width: '12rem',
          borderRadius: '.4rem',
          height: '3rem',
          fontSize: '1.4rem',
          outline: 'none',
          color: "var(--highlight-primary)",
          border:".15rem solid var(--highlight-primary)"
        }}
        declineButtonText="No Thanks"
        expires={60}
        onAccept={() => {
          setCookie('hbcookie', 'true', 60);
        }}
        onDecline={() => {
          setCookie('hbcookie', 'false', 60);
        }}
      >
        We use cookies to personalise content and ads, to provide social media
        features and to analyse our traffic. We also share information about
        your use of our site with our social media. Read our{' '}
        <a
          href="/terms_and_conditions"
          style={{ color: 'var(--highlight-primary)' }}
        >
          Terms & Conditions
        </a>{' '}
        to know more.{' '}If you wish to withdraw your consent then please see our{' '}
        <a
          href="/cookie"
          style={{ color: 'var(--highlight-primary)' }}
        >
          Cookies Information
        </a>{' '}
        page.
      </CookieConsent>
      <Switch>
        <Route path="/auth/login" component={requireUnAuth(CCOLogin)} />
        <Route path="/auth/register" component={requireUnAuth(AuthRegister)} />
        <Route path="/admin" component={requireAdmin(AdminView)} />
        <Route path="/profile" component={requireAuth(CCOProfile)} />
        <Route
          path="/:charity_url/campaigns/:campaign_url"
          exact
          component={WishListExpand}
        />
        <Route path="/charity/:charity_url/" exact component={Wishlist} />
        <Route path="/404" component={NotFound} />
        <Route path="/" component={requireNotAdmin(UnAuthRoutes)} />
      </Switch>
    </div>
  );
};
