import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import "./TermsAndConditionModal.scss";

interface TermsAndConditionModalProps {
  show: boolean;
  handleClose: () => void;
  handleAccept?: () => void;
}

class TermsAndConditionModal extends Component<TermsAndConditionModalProps> {

  handleAcceptTerms = () => {
    if(this.props.handleAccept !== undefined) {
      this.props.handleAccept();
      this.props.handleClose();
    }
  }

  render() {
    const { show, handleClose } = this.props;
    return (
      <Modal show={show} onHide={handleClose}>
        <div className="modal-content">
          <Modal.Header>
            <div className="modal-header">
              <Modal.Title>
                <div className="modal-title">
                  Help Britain - Terms and Conditions
                </div>
              </Modal.Title>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              These terms tell you the rules for using our website
              www.helpbritain.org.uk(“our site”). Who we are and how to contact
              us www.helpbritain.org.uk is a site operated by Ovyo Tech Services
              (“We”). <br /><br /><br /> We are registered in England and Wales under charity
              number xxxxxxx and have our registered office at xx Extra Lane,
              London TW7 4HG To contact us, please email info@helpbritain.org.uk
              <div className="tandc-heading">
                1. By using our site you accept these terms
              </div>
              By using our site, you confirm that you accept these terms of use
              and that you agree to comply with them. If you do not agree to
              these terms, you must not use our site. We recommend that you
              print a copy of these terms for future reference.
              <div className="tandc-heading">
                2. There are other terms that may apply to you
              </div>
              Our Privacy Policy and Cookie Policy
              www.helpbritain.org.uk/information-security/, which sets out the
              terms on which we process any personal data we collect from you,
              or that you provide to us as well as the cookies we use on our
              site. By using our site, you consent to such processing and you
              warrant that all data provided by you is accurate. We may make
              changes to these terms We amend these terms from time to time.
              Every time you wish to use our site, please check these terms to
              ensure you understand the terms that apply at that time.
              <div className="tandc-heading">
                3. We may make changes to our site
              </div>
              We may update and change our site from time to time. We may
              suspend or withdraw our site. Our site is made available free of
              charge. We do not guarantee that our site, or any content on it,
              will always be available or be uninterrupted. We may suspend or
              withdraw or restrict the availability of all or any part of our
              site for business and operational reasons. We will try to give you
              reasonable notice of any suspension or withdrawal. You are also
              responsible for ensuring
            </div>
          </Modal.Body>

          {this.props.handleAccept !== undefined &&
          <Modal.Footer>
            <div className="donate-btn">
              <Button variant="secondary" className="rejectbtn" onClick={handleClose}>
                REJECT
              </Button>
              <Button variant="secondary" className="acceptbtn" onClick={this.handleAcceptTerms}>
                ACCEPT
              </Button>
            </div>
          </Modal.Footer>}
        </div>
      </Modal>
    );
  }
}

export default TermsAndConditionModal;
