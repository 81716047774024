import React, { Component } from 'react';
import './ManageTeam.scss';
import { FormControl, Button } from 'react-bootstrap';
import ProfileContainer, {
  CCOProfileProps
} from '../container/ProfileContainer';
import { validEmailRegex } from '../../Auth/components/CCO-Registration/CCORegister';

class ManageTeam extends Component<CCOProfileProps> {
  state = {
    userEmail: '',
    userType: 'CCO_USER',
    errors: {
      email: ""
    }
  };

  componentDidMount() {
    this.props.getCCOUsers();
  }

  inviteUserToTeam = () => {
    const { userEmail, userType } = this.state;
    let payload = {
      userEmail,
      userType,
      cco: 'CCO-' + localStorage.getItem('email')
    };
    this.props.inviteUser(payload);
  };

  componentDidUpdate(prevProps: CCOProfileProps) {
    if (
      this.props.invite_user_succeeded &&
      this.props.invite_user_succeeded !== prevProps.invite_user_succeeded
    ) {
      this.setState({
        userEmail: ''
      });
    }
  }

  handleEmail = (ev: any) => {
    ev.preventDefault();
    const { name, value } = ev.target;
    let errors = this.state.errors;

    switch (name) {
      case 'userEmail':
        errors.email = validEmailRegex.test(value) ? '' : 'Email is not valid!';
        break;

      default:
        break;
    }

    this.setState({
      errors,
      userEmail: ev.target.value
    });
  };

  handleStatus = (user: any) => {
    let payload = {
      userEmail: user.email,
      cco: user.cco
    };
    this.props.deActivateUser(payload);
  };

  render() {
    return (
      <div className="manage-team-container">
        <div className="heading-1">Invite member</div>
        <div className="invite-team-member">
          <div className="input-part">
            <FormControl
              type="text"
              placeholder="Enter email id"
              className="form-control"
              name="userEmail"
              onChange={this.handleEmail}
              value={this.state.userEmail}
            />
             <label className="error-msg" style={{ marginLeft: '1rem'}}>{this.state.errors.email}</label>
          </div>
          <div className="invite-btn-part">
            <Button
              disabled={this.state.userEmail.length === 0 || this.state.errors.email.length > 0}
              className={
                (this.state.userEmail.length === 0 || this.state.errors.email.length > 0)
                  ? 'btn-disable invite-btn'
                  : 'invite-btn btn-primary'
              }
              onClick={this.inviteUserToTeam}
            >
              <span>Invite member</span>
            </Button>
          </div>
        </div>

        {this.props.team_user_info.length > 0 && (
          <div>
            <div className="heading-2">Organisation members</div>

            <div className="table-cont">
              <div className="memberslist-part table-head">
                <div className="empty-circle-part"></div>
                <div className="email-part">Email</div>
                <div className="status">Status</div>
                <div className="remove-btn-part"></div>
              </div>
              <div className="table-body">
                {this.props.team_user_info.map((d: any, i: number) => (
                  <div className="memberslist-part" key={i}>
                    <div className="circle-part">{d.email[0]}</div>
                    <div className="email-part">{d.email}</div>
                    <div className="status">{d.usr_status}</div>
                    <div className="remove-btn-part">
                      <Button
                        onClick={() => this.handleStatus(d)}
                        className="remove-btn"
                      >
                        Deactivate
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ProfileContainer(ManageTeam);
