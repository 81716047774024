export interface HomeState {
  fetch_banner_loading: boolean;
  fetch_banner_succeeded: boolean;
  fetch_banner_failed: boolean;
  banner_info : any[];

  campaigns_card_loading: boolean;
  campaigns_card_succeeded: boolean;
  campaigns_card_failed: boolean;
  campaign_card_info : any[];

  admin_campaigns_list : any,
  admin_campaigns_list_loading: boolean,
  admin_campaigns_list_succeeded: boolean,
  admin_campaigns_list_failed: boolean,
}

export const HOME_BANNER_REQUESTED = "HOME_BANNER_REQUESTED";
export const HOME_BANNER_SUCCEEDED = "HOME_BANNER_SUCCEEDED";
export const HOME_BANNER_FAILED = "HOME_BANNER_FAILED";

export const CAMPAIGNS_CARDS_REQUESTED = "CAMPAIGNS_CARDS_REQUESTED";
export const CAMPAIGNS_CARDS_SUCCEEDED = "CAMPAIGNS_CARDS_SUCCEEDED";
export const CAMPAIGNS_CARDS_FAILED = "CAMPAIGNS_FAILED";

export const ADMIN_CAMPAGIN_LIST_REQUESTED = "ADMIN_CAMPAGIN_LIST_REQUESTED";
export const ADMIN_CAMPAGIN_LIST_SUCCEEDED = "ADMIN_CAMPAGIN_LIST_SUCCEEDED";
export const ADMIN_CAMPAGIN_LIST_FAILED = "ADMIN_CAMPAGIN_LIST_FAILED";
