import React from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon } from '../../images';

import './styles.scss';

const SocialShare = ({ ShareUrl, linkUrl, quote }: any) => {
  return (
    <ul className="sharelist">
      <li className="sharelist__item">
        <div>
          <FacebookShareButton translate="" url={ShareUrl} quote={quote+linkUrl}>
            <FacebookIcon className="sharelist__item--icon" />
          </FacebookShareButton>
        </div>
      </li>
      <li className="sharelist__item">
        <div>
          <TwitterShareButton translate="" url={ShareUrl} title={quote}>
            <TwitterIcon className="sharelist__item--icon" />
          </TwitterShareButton>
        </div>
      </li>
    </ul>
  );
};

export default SocialShare;
