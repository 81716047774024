export const getKebabCase = (value: String) =>{
    return value && value.toLowerCase().replace(" ", "-")
}
export function setCookie(name: string,value: string,days: number) {
    let expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}