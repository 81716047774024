export interface CCOProfileState {
  fetch_profile_loading: boolean;
  fetch_profile_succeeded: boolean;
  fetch_profile_failed: boolean;
  cco_profile_info: any;

  fetch_org_profile_loading: boolean;
  fetch_org_profile_succeeded: boolean;
  fetch_org_profile_failed: boolean;
  cco_org_profile_info: any;

  save_org_profile_loading: boolean;
  save_org_profile_succeeded: boolean;
  save_org_profile_failed: boolean;
  org_profile_info: any;

  search_org_loading: boolean;
  search_org_succeeded: boolean;
  search_org_failed: boolean;
  search_org_info: any;

  invite_user_loading: boolean;
  invite_user_succeeded: boolean;
  invite_user_failed: boolean;
  team_user_info: any;

  filters_loading: boolean;
  filters_succeeded: boolean;
  filters_failed: boolean;
  filters_data: any;

  change_status_loading: boolean;
  change_status_succeeded: boolean;
  change_status_failed: boolean;

  locations_loading: boolean;
  locations_succeeded: boolean;
  locations_failed: boolean;
  locations_data: any;
}

export const USER_LOGOUT = "USER_LOGOUT";

export const CCO_PROFILE_REQUESTED = "CCO_PROFILE_REQUESTED";
export const CCO_PROFILE_SUCCEEDED = "CCO_PROFILE_SUCCEEDED";
export const CCO_PROFILE_FAILED = "CCO_PROFILE_FAILED";

export const CCO_ORG_PROFILE_REQUESTED = "CCO_ORG_PROFILE_REQUESTED";
export const CCO_ORG_PROFILE_SUCCEEDED = "CCO_ORG_PROFILE_SUCCEEDED";
export const CCO_ORG_PROFILE_FAILED = "CCO_ORG_PROFILE_FAILED";

export const SAVE_ORG_PROFILE_REQUESTED = "SAVE_ORG_PROFILE_REQUESTED";
export const SAVE_ORG_PROFILE_SUCCEEDED = "SAVE_ORG_PROFILE_SUCCEEDED";
export const SAVE_ORG_PROFILE_FAILED = "SAVE_ORG_PROFILE_FAILED";

export const INVITE_USER_REQUESTED = "INVITE_USER_REQUESTED";
export const INVITE_USER_SUCCEEDED = "INVITE_USER_SUCCEEDED";
export const INVITE_USER_FAILED = "INVITE_USER_FAILED";

export const FILTER_REQUESTED = "FILTER_REQUESTED";
export const FILTER_SUCCEEDED = "FILTER_SUCCEEDED";
export const FILTER_FAILED = "FILTER_FAILED";

export const CHANGE_STATUS_REQUESTED = "CHANGE_STATUS_REQUESTED";
export const CHANGE_STATUS_SUCCEEDED = "CHANGE_STATUS_SUCCEEDED";
export const CHANGE_STATUS_FAILED = "CHANGE_STATUS_FAILED";

export const LOCATIONS_REQUESTED = "LOCATIONS_REQUESTED";
export const LOCATIONS_SUCCEEDED = "LOCATIONS_SUCCEEDED";
export const LOCATIONS_FAILED = "LOCATIONS_FAILED";