import * as types from './types';

const initialState: types.VolunteeringState = {
  volunteering_list:[],
  loading: false
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.FETCH_VOLUNTEERING_LIST_REQUESTED:
      return {
        ...state,
        volunteering_list:[],
        loading: true
      };
    case types.FETCH_VOLUNTEERING_LIST_SUCCEEDED:
      return {
        ...state,
        volunteering_list:action.payload,
        loading: false
      };
    case types.FETCH_VOLUNTEERING_LIST_FAILED:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};
