import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { CreateCampaignProps } from '../container/CreateCampaignCont';
import { FormControl, Button } from 'react-bootstrap';
import CampaignContainer from '../container/CreateCampaignCont';
import Loader from '../../../components/Loader/Loader';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import OrganisationProfile from '../../CCOProfile/components/OrganisationProfile';

import 'react-datepicker/dist/react-datepicker.css';

class CreateCampaign extends Component<CreateCampaignProps> {
  upload: any;
  imageRef: any;
  fileUrl: any;
  myRef: any;

  state = {
    url: '',
    image: '',
    campaignName: '',
    description: '',
    data: {} as any,
    crop: {
      x: 100,
      y: 50,
      width: 230,
      height: 220,
      aspect: 4 / 4
    },
    openCropModal: false,
    croppedImageUrl: '',
    showStep2: false,
    pledgeAmount: '',
    campaignDate: new Date(),
    message: ''
  };

  handleInput = (ev: any) => {
    this.setState({
      [ev.target.name]: ev.target.value
    });
  };

  scrollToMyRef = () =>
    window.scrollTo({
      top: 800,
      behavior: 'smooth'
    });

  componentWillMount() {
    const { itemId } = this.props;
    if (itemId === 2) {
      this.setState({
        showStep2: false
      });
    } else {
      this.setState({
        showStep2: true
      });
    }
  }

  componentDidUpdate(prevProps: CreateCampaignProps) {
    if (
      this.props.fetch_wishlist_succeeded &&
      this.props.fetch_wishlist_succeeded !== prevProps.fetch_wishlist_succeeded
    ) {
      this.setState(
        {
          data: this.props.wishlistInfo
        },
        () => this.scrollToMyRef()
      );
    }
    if (
      this.props.publish_succeeded &&
      this.props.publish_succeeded !== prevProps.publish_succeeded
    ) {
      this.props.handlePublish(
        this.state.campaignName,
        this.props.publish_info
      );
    }
  }

  onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () =>
          this.setState({
            image: reader.result,
            openCropModal: true
          }),
        false
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  handleClose = () => {
    this.setState({
      openCropModal: false
    });
  };

  handlePublish = () => {
    const {
      campaignName,
      image,
      description,
      url,
      data,
      pledgeAmount,
      campaignDate,
      message
    } = this.state;
    let payload;
    const type = this.props.itemId === 1 ? 'amazon' : 'love2shop';
    if (this.props.itemId === 1) {
      payload = {
        campaignName,
        image,
        description,
        url,
        data,
        campaignDate: moment(campaignDate).format('DD-MM-yyyy')
      };
    } else {
      payload = {
        campaignName,
        image,
        description,
        pledgeAmount,
        message,
        campaignDate: moment(campaignDate).format('DD-MM-yyyy')
      };
    }

    this.props.publish(payload, type);
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image: any) => {
    this.imageRef = image;
  };

  onCropComplete = (crop: any) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop: any, percentCrop: any) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop: any) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image: any, crop: any, fileName: any) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // As Base64 string
    const base64Image = canvas.toDataURL('image/jpeg');
    return base64Image;
  }

  saveCroppedImage = () => {
    this.setState({
      image: this.state.croppedImageUrl,
      openCropModal: false
    });
  };

  handleShowStep = () => {
    this.setState(
      {
        showStep2: false
      },
      () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    );
  };

  handleSkipToStep3 = () => {
    this.props.getProfile();
  };

  setCampaignDate = (campaignDate: any) => {
    this.setState({
      campaignDate
    });
  };

  render() {
    const {
      url,
      campaignName,
      description,
      image,
      campaignDate,
      pledgeAmount,
      message
    } = this.state;
    let disablePublish =
      this.props.itemId === 1
        ? url.length === 0 ||
          campaignName.length === 0 ||
          description.length === 0 ||
          image.length === 0
        : campaignName.length === 0 ||
          description.length === 0 ||
          image.length === 0;
    const no_banner_details =
      this.props.profile_info.charity.banner === undefined;
    return (
      <div className="campaigns-info">
        {this.props.loading && <Loader />}

        {/* Getting whish list items */}

        {this.state.showStep2 && this.props.profile_info.banner === undefined && (
          <div>
            <div className="steps-count">Step 2 of 3</div>

            <div className="head">
              <div className="title">CREATE A NEW WISHLIST</div>
            </div>
            <div className="form-cont">
              <div className="part-2">
                <label className="label">
                  Please provide the wishlist URL here:
                </label>
                <FormControl
                  type="text"
                  onChange={this.handleInput}
                  name="url"
                  value={url}
                />
                <Button
                  className={
                    this.state.url.length === 0
                      ? 'btn-disable btn'
                      : 'btn-primary btn'
                  }
                  onClick={() => this.props.getWishlist(this.state.url)}
                >
                  Fetch Details
                </Button>
              </div>
            </div>

            {this.state.data.items !== undefined && (
              <div className="wishlist-items" ref={(ref) => (this.myRef = ref)}>
                <div className="wishlist-item-cont">
                  {this.state.data.items.map((ds: any, i: number) => (
                    <div className="item" key={i}>
                      <div className="logo">
                        <img src={ds.imgURL} alt="" className="image" />
                      </div>
                      <div>
                        <div className="description">{ds.title}</div>
                        <div className="price">Price - £{ds.price}</div>
                        <div className="price">
                          Total Needed - {ds.RequestedAmount}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {this.state.data.items !== undefined &&
              this.state.data.items.length !== 0 && (
                <div className="btn-container">
                  <Button
                    onClick={this.handleShowStep}
                    className={'btn-primary btn'}
                  >
                    Continue
                  </Button>
                </div>
              )}
          </div>
        )}

        {no_banner_details && !this.state.showStep2 && (
          <div className="create-campaign-step-3">
            <div className="steps-count">
              Please update your organisation profile details to continue
            </div>
            <OrganisationProfile setStep={this.handleSkipToStep3} />
          </div>
        )}

        {/* Update campaign information */}
        {!this.state.showStep2 && !no_banner_details && (
          <div>
            {this.props.itemId === 1 && (
              <div className="steps-count">{'Step 3 of 3'}</div>
            )}
            {this.props.itemId === 2 && (
              <div className="steps-count">{'Step 2 of 2'}</div>
            )}

            <div className="head">
              <div className="title">CREATE A NEW WISHLIST</div>
            </div>

            <div className="campaign-part-1">
              <div className="campaign-part-1-1">
                <input
                  id="myInput"
                  type="file"
                  ref={(ref) => (this.upload = ref)}
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={this.onSelectFile}
                />
                {this.state.image.length === 0 && (
                  <div
                    className="upload-pic"
                    onClick={() => {
                      this.upload.click();
                    }}
                  >
                    <img
                      src={require('../../../images/Dashboard/upload-light.svg')}
                      alt=""
                      className="img"
                    />
                    <span>Upload an image</span>
                  </div>
                )}
                {this.state.image.length > 0 && (
                  <div className="img-cont">
                    <img src={this.state.image} alt="" className="image" />
                    <div
                      className="middle"
                      onClick={() => {
                        this.upload.click();
                      }}
                    >
                      <img
                        src={require('../../../images/Dashboard/upload-light.svg')}
                        alt=""
                        className="img"
                      />
                      <span>Upload an image</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="campaign-part-1-2">
                <div className="campaign-part-2-1">
                  <label className="label">Wishlist Name:</label>
                  <FormControl
                    type="text"
                    onChange={this.handleInput}
                    name="campaignName"
                    value={campaignName}
                  />
                </div>
                <div className="campaign-part-2-2">
                  <div>
                    <label className="label">
                      Please write a short description:
                    </label>
                  </div>
                  <div>
                    <textarea
                      onChange={this.handleInput}
                      name="description"
                      value={description}
                      className="text-box"
                    />
                  </div>
                </div>

                <div className="campaign-part-2-1">
                  <label className="label">
                    Date to activate/launch wishlist:
                  </label>
                  <div className="date-time">
                    <DatePicker
                      selected={campaignDate}
                      onChange={(date) => this.setCampaignDate(date)}
                      dateFormat="dd MMM yyyy"
                      minDate={new Date()}
                    />
                  </div>
                </div>

                {this.props.itemId === 2 && (
                  <>
                    <div className="campaign-part-2-1">
                      <label className="label">Pledge Amount:</label>
                      <FormControl
                        type="number"
                        onChange={this.handleInput}
                        name="pledgeAmount"
                        value={pledgeAmount}
                      />
                      <label className="label">
                        (please leave blank to have an open-ended wishlist, only
                        include if you have a specific target in mind)
                      </label>
                    </div>
                  </>
                )}

                {this.props.itemId === 2 && (
                  <>
                    <div className="campaign-part-2-2">
                      <label className="label">Thank you message to send to people who donate to you through this wishlist:</label>
                      <div>
                        <textarea
                          onChange={this.handleInput}
                          name="message"
                          value={message}
                          className="text-box"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="btn-container">
              <Button
                onClick={this.handlePublish}
                disabled={disablePublish}
                className={
                  disablePublish ? 'btn-disable btn' : 'btn-primary btn'
                }
                style={{ width: '15rem' }}
              >
                Publish Wishlist
              </Button>
            </div>
          </div>
        )}

        {this.state.openCropModal && (
          <div className="cropper-modal">
            {this.state.image && (
              <div className="cropper-cont">
                <ReactCrop
                  src={this.state.image}
                  crop={this.state.crop}
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />

                <Button onClick={this.saveCroppedImage}>Crop</Button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
export default CampaignContainer(CreateCampaign);
