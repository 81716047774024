import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class Ideas extends Component {
  render() {
    return (
      <div>
        <Header activeTab="about" />
        <div className="about-us-cont">
          <div className="about-us-header">
            <div className="title">Ideas</div>
            <div className="about-header">
              Now that you’ve heard about HelpBritain, you’re probably wondering
              whether it can be a useful tool for your charity. Here are a few
              ideas about how HelpBritain can power additional fundraising for
              you.
            </div>

            <div className="block-cont">
              <div className="block" style={{ width: '100%' }}>
                <div className="content">
                  <span>
                    Select vouchers : Tesco, Morrisons, Argos, B&Q and more
                  </span>{' '}
                  <br />
                  <br />
                  <ul>
                    <li>
                      Tesco & Morrisons are perfect picks for food banks and
                      soup kitchens or hungry tummies in after-school clubs!
                    </li>
                    <li>B&Q works great if you need any renovations.</li>
                    <li>
                      {' '}
                      Do you provide gifts to people at Christmas or other
                      celebrations, or birthday gifts? Argos is ideal for
                      sourcing gifts for young and old alike, as are Amazon
                      wishlists.
                    </li>
                    <li>
                      Do you run a family area or drop-in facility? Use vouchers
                      to stock up on tea, biscuits, toys and more!
                    </li>
                    <li>
                      If you help people in poverty, vouchers can be used at
                      Primark for interview outfits, school uniforms etc. and
                      Amazon wishlists can cover even more needs.
                    </li>
                    <li>
                      If you run an animal shelter or welfare group, vouchers
                      can be used to purchase pet food and grooming and other
                      equipment.
                    </li>
                    <li>
                      The full set of retailers who accept these vouchers can be
                      found here:
                      https://www.select-your-reward.co.uk/YourChoices
                    </li>
                  </ul>
                  <br />
                  <br />
                  <span>Special projects</span> <br />
                  <br />
                  <ul>
                    <li>
                      Do you have a big project such as building or renovating a
                      site? Vouchers can help you buy items that will be useful
                      as part of that, and Amazon has a comprehensive stock of
                      items which could be useful, so they can sit alongside
                      traditional money fundraising efforts to provide your
                      supporters with a bit of variety in how they can help.
                    </li>
                    <li>
                      Do you need a new computer? Select vouchers can be
                      redeemed at Currys PC World, so set your supporters a
                      target to hit!
                    </li>
                  </ul>
                  <br />
                  <br />
                  <span>Back office needs</span> <br />
                  <br />
                  <ul>
                    <li>
                      Possibly not what gets everyone excited, but supporters
                      realise that charities need pens, paper, printer ink and
                      many other items that keep an office ticking over –
                      especially whilst more people need to work from home.
                      Create an Amazon wishlist and you’ll be able to cover a
                      lot of that off and reduce the amount of other donations
                      you spend on overheads
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Ideas;
