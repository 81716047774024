import React, { Component } from 'react';
import { Navbar, Nav, Form, NavDropdown, FormControl } from 'react-bootstrap';
import ProfileContainer, {
  CCOProfileProps
} from '../../views/CCOProfile/container/ProfileContainer';
import { NavLink } from 'react-router-dom';
import DiscoverMore from './DiscoverMore';
import SearchContainer, {
  SearchProps
} from '../../views/Search/containers/SearchContainer';
import PuffLoader from '../ReactLoaders/ReactLoader';
import Search from '../../views/Search/components/Search';

class NavBar extends Component<CCOProfileProps & SearchProps> {
  state = {
    searchNotOpen: true,
    searchItem: [],
    searchValue: '',
    redirect: false,
    openDiscoverMoreModal: false,
    openLocationModal: false,
    latitude: '',
    longitude: '',
    currentLocation: '',
    tab: 'campaign',
    openAdminModal: false
  };

  myRef: any = React.createRef();
  myRefSearch: any = React.createRef();
  myRef1: any = React.createRef();
  myRef2: any = React.createRef();
  myRefAdmin: any = React.createRef();

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.props.getAllFilters();

    // this.props.getCampaigns();

    if (localStorage.getItem('__is_user_loggedIn')) {
      this.props.getUserProfile();
    }

    if (localStorage.getItem('__is_loggedIn')) {
      this.props.getProfile();
    }

    if (this.props.location !== 'all' && this.props.location !== 'nearMe') {
      this.props.setLocation('all');
    }
  }

  componentDidUpdate(
    prevProps: Readonly<CCOProfileProps & SearchProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (
      this.props.fetch_login_cco_succeeded &&
      this.props.fetch_login_cco_succeeded !==
        prevProps.fetch_login_cco_succeeded
    ) {
      this.props.getProfile();
    }

    if (this.props.location && this.props.location !== prevProps.location) {
      this.props.getAllFilters();
    }
  }

  closeInput = () => {
    this.setState({
      searchNotOpen: true,
      searchValue: ''
    });
  };

  handleClickOutside = (e: any) => {
    if (
      !this.myRefSearch.current?.contains(e.target) &&
      !this.myRef.current?.contains(e.target)
    ) {
      this.setState({ searchNotOpen: true, searchValue: '' });
    }

    if (
      this.myRef1.current !== null &&
      !this.myRef1.current.contains(e.target)
    ) {
      this.setState({ openDiscoverMoreModal: false });
    }

    if (
      this.myRef2.current !== null &&
      !this.myRef2.current.contains(e.target)
    ) {
      this.setState({ openLocationModal: false });
    }

    if (
      this.myRefAdmin.current !== null &&
      !this.myRefAdmin.current.contains(e.target)
    ) {
      this.setState({ openAdminModal: false });
    }
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  openDiscoverMoreModal = () => {
    this.setState({
      openDiscoverMoreModal: !this.state.openDiscoverMoreModal,
      openLocationModal: false
    });
  };

  openCurrentLocationModal = () => {
    this.setState({
      openLocationModal: !this.state.openLocationModal,
      openDiscoverMoreModal: false
    });
  };

  setCurrentLocation = (currentLocation: any) => {
    this.props.setLocation(currentLocation);
    this.setState({
      openLocationModal: false,
      currentLocation
    });
  };

  handleClickInside = () =>
    this.setState({
      searchNotOpen: false,
      searchItem: [],
      openDiscoverMoreModal: false,
      openLocationModal: false
    });

  handleSearch = (ev: any) => {
    const { current_location } = this.props;
    const { tab } = this.state;
    this.setState({
      searchValue: ev.target.value
    });
    this.props.searchCampaign(ev.target.value, current_location, tab);
  };

  handleTab = (e: any, tabValue: string) => {
    e.preventDefault();
    const { searchValue } = this.state;
    const { current_location } = this.props;
    this.setState({
      tab: tabValue
    });
    this.props.searchCampaign(searchValue, current_location, tabValue);
  };

  openAdminOptions = () => {
    this.setState({
      openAdminModal: true
    });
  };

  render() {
    const { activeTab, userLogout } = this.props;
    const { tab, searchNotOpen, searchValue } = this.state;
    const isLoggedIn = localStorage.getItem('__is_loggedIn');
    const isAdminLoggedIn = localStorage.getItem('__is_admin_loggedIn');
    const isUserLoggedIn = localStorage.getItem('__is_user_loggedIn');
    const authToken = isLoggedIn || isAdminLoggedIn || isUserLoggedIn;

    return (
      <Navbar.Collapse
        id="basic-navbar-nav"
        className="show-mine show-desktop-only"
      >
        <Form inline className="mr-auto">
          <div
            className={
              this.state.openDiscoverMoreModal
                ? 'discover-more-links active-link-drops'
                : 'discover-more-links'
            }
            onClick={this.openDiscoverMoreModal}
          >
            <span>Discover</span>
            <i className="fa fa-angle-down"></i>
          </div>

          {isAdminLoggedIn && (
            <Nav.Link
              href="/authorized/Admin/Organisations"
              className={
                activeTab === 'organisations' ? 'links active-link' : 'links'
              }
            >
              Organisations
            </Nav.Link>
          )}
          <NavLink
            to="/volunteering"
            className={
              activeTab === 'volunteering' ? 'links active-link' : 'links'
            }
          >
            Volunteering
          </NavLink>
        </Form>

        <Nav className="search-login-cont">
          <Form inline className="form-container" ref={this.myRefSearch}>
            <FormControl
              type="text"
              placeholder="Search"
              className={
                !searchNotOpen ? 'input-field active-input' : 'input-field'
              }
              value={searchValue}
              onClick={this.handleClickInside}
              onChange={(ev) => this.handleSearch(ev)}
            />
            {this.props.search_org_loading ? (
              <div className="load-container">
                <PuffLoader />
              </div>
            ) : (
              <i
                className={
                  searchNotOpen
                    ? 'fa fa-search search-icon'
                    : 'fa fa-search search-icon search-icon-active'
                }
                aria-hidden="true"
              />
            )}
            {searchValue.length > 0 && (
              <i
                className="fa fa-times close"
                aria-hidden="true"
                onClick={this.closeInput}
              />
            )}
            {!searchNotOpen && searchValue.length > 0 && (
              <div className="search-items">
                <Search activeTab={tab} onTabClick={this.handleTab} />
              </div>
            )}
          </Form>

          <Form
            inline
            className={
              this.state.openLocationModal
                ? 'location-links activeLocationLinks'
                : 'location-links'
            }
            onClick={this.openCurrentLocationModal}
          >
            <div
              className={
                this.state.openLocationModal
                  ? 'active-link-drops link-drops-cont'
                  : 'link-drops-cont'
              }
            >
              <span className="name">
                {this.props.location === 'all' ? 'Nationwide' : 'Near Me'}
              </span>
              <i className="fa fa-angle-down"></i>
            </div>
            {this.state.openLocationModal && (
              <div className="location-items" ref={this.myRef2}>
                <span
                  className={
                    this.props.location === 'all'
                      ? 'loc-item loc-item-active'
                      : 'loc-item'
                  }
                  onClick={() => this.setCurrentLocation('all')}
                >
                  Nationwide
                </span>
                <span
                  className={
                    this.props.location === 'nearMe'
                      ? 'loc-item loc-item-active'
                      : 'loc-item'
                  }
                  onClick={() => this.setCurrentLocation('nearMe')}
                >
                  Near Me
                </span>
              </div>
            )}
          </Form>

          {!authToken && (
            <>
              <Nav.Link
                href="/auth/login"
                className={
                  activeTab === 'login' ? 'links active-link ' : 'links'
                }
                style={{ margin: '0' }}
              >
                Login
              </Nav.Link>

              <Nav.Link
                href="/auth/register"
                className={
                  activeTab === 'register'
                    ? 'links btn btn-active'
                    : 'links btn'
                }
              >
                Register
              </Nav.Link>
            </>
          )}

          {authToken && (
            <Nav className="my-dropdown">
              {this.props.profile_info !== '' && (
                <NavDropdown
                  title={
                    this.props.profile_info.firstName[0] +
                    this.props.profile_info.lastName[0]
                  }
                  id="basic-nav-dropdown own-dropdown"
                >
                  {/* <i className="fa fa-caret-up icon" aria-hidden="true"></i> */}
                  <NavDropdown.Item className="profile-name-cont">
                    <div className="profile-name">
                      {this.props.profile_info.firstName +
                        ' ' +
                        this.props.profile_info.lastName}
                    </div>
                    <div className="email">{this.props.profile_info.email}</div>
                    <div className="charity">
                      {this.props.profile_info?.charity?.CharityName}
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  {!isAdminLoggedIn && (
                    <>
                      <NavDropdown.Item
                        className="organization-name"
                        href="/profile"
                      >
                        <div className="name">
                          {/* {this.props.profile_info.charity.CharityName} */}
                          Manage Profile
                          <i className="fa fa-angle-right" aria-hidden="true" />
                        </div>
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                    </>
                  )}
                  <NavDropdown.Item
                    className="logout-cont"
                    onClick={userLogout}
                  >
                    <div className="name">Logout</div>
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
          )}
        </Nav>
        {this.state.openDiscoverMoreModal && (
          <div className="discover-more-cont" ref={this.myRef1}>
            <DiscoverMore
              handleClose={this.openDiscoverMoreModal}
              filteredData={this.props.filters_data}
              campaignInfo={this.props.campaignInfo}
            />
          </div>
        )}
      </Navbar.Collapse>
    );
  }
}

export default ProfileContainer(SearchContainer(NavBar));
