import React, { Component } from 'react';
import axios from 'axios';
import { Form, FormControl, Button } from 'react-bootstrap';
import Loader from '../../../components/Loader/Loader';
import Header from '../../../components/Header/Header';
import '../styles/create.scss';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import GoogleMaps from '../../../components/GoogleMap/GoogleMap';
import ReactCrop from 'react-image-crop';
import VolunteerContainer, {
  VolunteeringListProps
} from '../containers/VolunteeringList';
import { history } from '../../../routes/index';
import { notify } from '../../../components/Notifier/Notifier';
import { baseUrl } from '../../../config/api-config';

export const valid_postcode = (postcode: any) => {
  postcode = postcode.replace(/\s/g, '');
  var regex = /(^[A-Z]{1,2}[A-Z0-9]{1,2})([0-9][A-Z]{2}$)/i;
  return regex.test(postcode);
};

class VolunteeringCreate extends Component<VolunteeringListProps> {
  imageRef: any;
  upload: any;

  state = {
    bannerImage: '',
    bannerCrop: {
      x: 100,
      y: 50,
      width: 230,
      height: 250,
      aspect: 4 / 2
    },
    openCropModal: false,
    bannerCroppedImageUrl: '',
    name: '',
    numberOfPeople: 1,
    category: [] as any,
    editAddress: false,
    address: '',
    newAddress: {
      Line1: '',
      Line2: '',
      Line3: '',
      town: '',
      latitude: '51.5073509',
      longitude: '-0.1277583'
    },
    isEdit: false,
    isLoading: false,
    summary: '',
    postCodeError: null,
    openEditModal: false
  };

  loadScript = () => {
    const API_KEY = process.env.REACT_APP_GOOGLE_API;
    const url = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
    const s = document.createElement('script');
    s.src = url;
    document.head.appendChild(s);
  };

  componentDidMount() {
    this.loadScript();
  }

  handleChange = (address: any) => {
    this.setState({ address });
  };

  handleSelect = (address: any) => {
    let finalAddress = address.split(',');
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({
          address,
          newAddress: {
            Line1: finalAddress[0],
            Line2: finalAddress[1],
            Line3:
              (finalAddress[2] || '') +
              (finalAddress[3] || '') +
              (finalAddress[4] || '') +
              (finalAddress[5] || ''),
            latitude: latLng.lat,
            longitude: latLng.lng
          }
        });
      })
      .catch((error) => console.error('Error', error));
  };

  onInputClick = (event: any) => {
    event.target.value = '';
  };

  onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () =>
          this.setState({
            bannerImage: reader.result,
            openCropModal: true
          }),
        false
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  handleClose = () => {
    this.setState({
      openCropModal: false
    });
  };

  onImageLoaded = (image: any) => {
    this.imageRef = image;
  };

  onCropComplete = (bannerCrop: any) => {
    this.makeClientCrop(bannerCrop);
  };

  async makeClientCrop(bannerCrop: any) {
    if (this.imageRef && bannerCrop.width && bannerCrop.height) {
      const bannerCroppedImageUrl = this.getCroppedImg(
        this.imageRef,
        bannerCrop,
        'newFile.jpeg'
      );
      this.setState({ bannerCroppedImageUrl });
    }
  }

  getCroppedImg(image: any, crop: any, fileName: any) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // As Base64 string
    const base64Image = canvas.toDataURL('image/jpeg');
    return base64Image;
  }

  saveBannerCroppedImage = () => {
    this.setState({
      bannerImage: this.state.bannerCroppedImageUrl,
      openCropModal: false
    });
  };

  handleEdit = () => {
    this.setState({
      isEdit: !this.state.isEdit
    });
  };

  onCropChange = (bannerCrop: any, percentCrop: any) => {
    this.setState({ bannerCrop });
  };

  getCategoryOptions = () => {
    const { categories } = this.props;

    return categories.map((c: any) => ({ label: c, value: c }));
  };

  goToList = () => {
    history.push('/volunteering');
  };

  handleCategory = (option: any) => {
    this.setState({
      category: option ? option.map((o: any) => o.value) : []
    });
  };

  handleCreate = (e: any) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCkeckPostcode = (e: any) => {
    let test = valid_postcode(e.target.value);
    this.setState({ postCodeError: test, [e.target.name]: e.target.value });
  };

  getAddress = () => {
    geocodeByAddress(this.state.address)
      .then((results) => this.getAdressss(results))
      .catch((error) => console.error('Error', error));
  };

  getAdressss = (result: any) => {
    getLatLng(result[0]).then((latLng) => {
      this.setState(
        {
          newAddress: {
            ...this.state.newAddress,
            latitude: latLng.lat,
            longitude: latLng.lng
          }
        },
        () => {
          this.setState({
            openEditModal: true,
            newAddress: {
              ...this.state.newAddress,
              Line1: '',
              Line2: result[0].address_components[1].long_name,
              Line3: result[0].address_components[2].long_name,
              town: result[0].address_components[3].long_name
            }
          });
        }
      );
    });
  };

  handleUpdateAddress = (ev: any) => {
    this.setState({
      newAddress: {
        ...this.state.newAddress,
        [ev.target.name]: ev.target.value
      }
    });
  };

  createVolunteering = () => {
    this.setState({
      isLoading: true
    });
    const {
      bannerImage,
      name,
      numberOfPeople,
      newAddress: { latitude, longitude, Line1, Line2, Line3, town },
      address,
      summary
    } = this.state;
    const params = {
      bannerImage,
      name,
      numberOfPeople,
      latitude,
      longitude,
      address:
        address + ', ' + Line1 + ', ' + Line2 + ' ' + Line3 + ' ' + town + ' ',
      summary,
      cco: localStorage.getItem('email')
    };
    axios
      .post(baseUrl + '/hbAdmin/createVolunteer', params)
      .then((response) => {
        history.push('/volunteering');
        notify.success('Success', 'Successfully created');
        this.setState({
          isLoading: false
        });
      })
      .catch((error) => {
        notify.error('Something went wrong', 'Failed to request volunteer.');
        this.setState({
          isLoading: false
        });
      });
  };

  render() {
    const loading = false;
    const {
      address,
      bannerImage,
      newAddress,
      name,
      summary,
      numberOfPeople
    } = this.state;
    const disable_create =
      bannerImage.length <= 0 ||
      name.length <= 0 ||
      address.length <= 0 ||
      newAddress.Line1.length <= 0;
    return (
      <>
        {(loading || this.state.isLoading) && <Loader />}
        <Header activeTab="volunteering" />
        <div className="v-create">
          <div className="v-create__title">Volunteer request</div>

          <div className="banner-img">
            <input
              id="myInput"
              type="file"
              ref={(ref) => (this.upload = ref)}
              style={{ display: 'none' }}
              accept="image/*"
              onChange={this.onSelectFile}
              onClick={this.onInputClick}
            />
            {bannerImage.length === 0 && (
              <div
                className="upload-pic"
                onClick={() => {
                  this.upload.click();
                }}
              >
                <img
                  src={require('../../../images/Dashboard/upload-light.svg')}
                  alt=""
                  className="img"
                />
                <span>Upload Banner</span>
              </div>
            )}
            {bannerImage.length > 0 && (
              <div
                className="img-cont"
                style={{ backgroundImage: `url(${bannerImage})` }}
              >
                <div
                  className="middle"
                  onClick={() => {
                    this.upload.click();
                  }}
                  style={!this.state.isEdit ? { display: 'none' } : {}}
                >
                  <img
                    src={require('../../../images/Dashboard/upload-light.svg')}
                    alt=""
                    className="img"
                  />
                  <span>Upload new Banner</span>
                </div>
              </div>
            )}
          </div>

          <div className="v-create__details">
            <Form className="form">
              <div className="create-cont">
                <div className="v-create__form--wrapper">
                  <div className="v-create__form v-create__form--1">
                    <label className="label">Name *</label>
                    <FormControl
                      type="text"
                      name="name"
                      onChange={this.handleCreate}
                      placeholder="E.g Food distribution for Elderly people"
                      value={name}
                    />
                  </div>
                  <div className="v-create__form v-create__form--2">
                    <label className="label">Number of people needed *</label>
                    <FormControl
                      type="text"
                      name="numberOfPeople"
                      onChange={this.handleCreate}
                      placeholder="E.g 20"
                      value={numberOfPeople}
                    />
                  </div>
                </div>
                <div className="v-create__form">
                  <label className="label">Summary *</label>
                  <FormControl
                    as="textarea"
                    type="text"
                    name="summary"
                    onChange={this.handleCreate}
                    placeholder="Add description about this event…"
                    className="summary"
                    value={summary}
                  />
                </div>
                <div className="v-create__form v-create__form--2">
                  <label className="label">Enter postcode *</label>
                  <div style={{ display: 'flex' }}>
                    <FormControl
                      type="text"
                      name="address"
                      onChange={this.handleCkeckPostcode}
                      placeholder="E.g TW74HG"
                      value={address}
                      style={{ width: '30rem' }}
                    />
                    <div
                      onClick={() => this.getAddress()}
                      className={this.state.postCodeError ? "get-address-btn" : "get-address-btn-disable"}
                    >
                      Get Address
                    </div>
                  </div>
                  {this.state.postCodeError !== null &&
                    !this.state.postCodeError && (
                      <label className="error-msg">Enter valid postcode</label>
                    )}

                  {this.state.openEditModal && (
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: '30rem' }}>
                        <div style={{ margin: '2rem 0' }}>
                          <label className="label">Address line 1 *</label>
                          <FormControl
                            type="text"
                            name="Line1"
                            onChange={this.handleUpdateAddress}
                            placeholder="House or flat number"
                            value={newAddress.Line1}
                            style={{ width: '30rem' }}
                          />
                        </div>
                        <div style={{ margin: '2rem 0' }}>
                          <label className="label">Address line 2</label>
                          <FormControl
                            type="text"
                            name="Line2"
                            onChange={this.handleUpdateAddress}
                            placeholder="E.g TW74HG"
                            value={newAddress.Line2}
                            style={{ width: '30rem' }}
                          />
                        </div>
                        <div style={{ margin: '2rem 0' }}>
                          <label className="label">Address line 3</label>
                          <FormControl
                            type="text"
                            name="Line3"
                            onChange={this.handleUpdateAddress}
                            placeholder="E.g TW74HG"
                            value={newAddress.Line3}
                            style={{ width: '30rem' }}
                          />
                        </div>
                        <div style={{ margin: '2rem 0' }}>
                          <label className="label">Town / City</label>
                          <FormControl
                            type="text"
                            name="town"
                            onChange={this.handleUpdateAddress}
                            placeholder="E.g TW74HG"
                            value={newAddress.town}
                            style={{ width: '30rem' }}
                          />
                        </div>
                      </div>
                      <div className="map-cont">
                        <GoogleMaps
                          lat={newAddress.latitude}
                          lon={newAddress.longitude}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="v-create__footer">
                  <Button onClick={this.goToList} className="v-create__cancel">
                    Cancel
                  </Button>
                  <Button
                    onClick={
                      disable_create ? () => {} : this.createVolunteering
                    }
                    className="v-create__create btn-primary-green"
                    disabled={disable_create}
                  >
                    Create
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
        {this.state.openCropModal && (
          <div className="cropper-modal">
            {this.state.bannerImage && (
              <div className="cropper-cont">
                <ReactCrop
                  src={this.state.bannerImage}
                  crop={this.state.bannerCrop}
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />

                <Button onClick={this.saveBannerCroppedImage}>Crop</Button>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default VolunteerContainer(VolunteeringCreate);
