import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './About.scss';

class CookiePrivacy extends Component {
  redirectToOvyo = () => {
    window.open('https://ovyo.com/', '_blank');
  };

  render() {
    return (
      <div>
        <Header activeTab="about" />
        <div className="about-us-cont">
          <div className="about-us-header">
            <div className="title">Cookies Information</div>
            <div className="about-header">
              HelpBritain uses cookies to store data on your device for the
              purpose of identifying you when you return to our website. Most
              web browsers allow some control of cookies through the browser
              settings. If you have cookies disabled in your browser, you are
              still able to use our website but some of the features that rely
              on cookies may not work correctly.
            </div>
            <div className="block-cont">
              <div className="block" style={{ width: '100%' }}>
                <div className="content">
                  <span style={{ fontSize: '2rem' }}>
                    Types of Cookies used
                  </span>{' '}
                  <br />
                  <br />
                  <span>Strictly necessary Cookies</span> <br />
                  <br />
                  These cookies are necessary for our website to function and
                  cannot be switched off in our systems. They are only set in
                  response to actions made by you which amount to a request for
                  services, such as setting your privacy preferences, logging in
                  to your account or filling in forms.
                  <br /> You can set your browser to block or alert you about
                  these cookies, but some parts of the site may not work if they
                  are switched off.
                  <br />
                  <br />
                  <span>Performance Cookies</span> <br />
                  <br />
                  These cookies allow us to count visits and traffic sources so
                  we can measure and improve the performance of our site. They
                  help us to know which pages are the most and least popular and
                  see how visitors move around the site.
                  <br />
                  All information these cookies collect is aggregated and
                  therefore pseudonymous. If you do not allow these cookies we
                  will not know when you have visited our site, and will not be
                  able to monitor its performance.
                  <br />
                  <br />
                  <span>Functional Cookies</span> <br />
                  <br />
                  These cookies enable the website to provide enhanced
                  functionality and personalisation. They may be set by us or by
                  third party providers whose services we have added to our
                  pages.
                  <br />
                  If you do not allow these cookies then some or all of these
                  services may not function properly.
                  <br />
                  <br />
                  <span>Targeting Cookies</span> <br />
                  <br />
                  These cookies may be set through our site by third party
                  providers. They may be used by those companies to build a
                  profile of your interests and show you relevant adverts on
                  other sites.
                  <br />
                  They do not store directly personal information, but are based
                  on uniquely identifying your browser and internet device. If
                  you do not allow these cookies, you will experience less
                  targeted advertising.
                  <br />
                  <br />
                </div>
                {/* <div className="table-container">
                    <table>
                      <thead>
                          <tr>
                            <th>Cookie</th>
                            <th>Domain</th>
                            <th>Type</th>
                            <th>Life Span</th>
                            <th>Purpose</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                            <td></td>
                            </tr>
                      </tbody>
                    </table>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default CookiePrivacy;
