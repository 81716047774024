import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import './Register.scss';

interface CaptureEmailProps {
  show: boolean;
  email: string;
  charityName: string;
  handleEmail: any;
  closeModal: any;
  onSubmit: any;
}

class CaptureEmail extends Component<CaptureEmailProps> {
  render() {
    const {
      show,
      email,
      handleEmail,
      closeModal,
      onSubmit,
    } = this.props;
    return (
      <>
        <Modal
          show={show}
          onHide={closeModal}
          size="lg"
          aria-labelledby="example-modal-sizes-title-lg"
          className="capture-email"
        >
          <Modal.Header>
            <Modal.Title>Please Enter your email</Modal.Title>
            <i
              className="fa fa-close capture-email-close"
              onClick={closeModal}
            />
          </Modal.Header>
          <Modal.Body>
            <div className="capture-email-wrapper">
              <input
                type="text"
                name="email"
                value={email}
                placeholder="Enter email"
                className="capture-email-wrapper__input"
                onChange={handleEmail}
              />
              <p>
                {/* I understand that by clicking next, my email address will be
                shared with the Charity {` "${charityName}"`} */}
              </p>
              <button
                className="btn-primary-green capture-email-wrapper__btn"
                onClick={onSubmit}
              >
                Continue
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default CaptureEmail;
