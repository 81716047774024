import { connect } from 'react-redux';
import {
  getFilteredCards,
  getAdminCampaigns,
  getHomeCards
} from '../../../services/Home/actions';
import { changeCampaignStatus } from '../../../services/Campaigns/actions';

interface StateProps {
  loading: boolean;
  campaigns_succeeded: boolean;
  campaigns_failed: boolean;
  campaignInfo: any;
  categories: [];
  current_location: string;
  locations: any[];
  adminCampaignList: any;
}

interface DispatchProps {
  getCampaigns: () => void;
  getAdminCampaigns: () => void;
  getFilteredCampaigns: (filter: any, city: any) => void;
  changeCampaignStatus: (campaignName: any, status: any) => void;
}

interface OwnProps {
  title: string;
}

export interface CauseProps extends StateProps, DispatchProps, OwnProps {}

const mapStateToProps = (state: any): StateProps => {
  return {
    loading:
      state.home.campaigns_cards_loading ||
      state.home.admin_campaigns_list_loading,
    campaigns_succeeded: state.home.campaigns_succeeded,
    campaigns_failed: state.home.campaigns_loading,
    campaignInfo: state.home.campaign_card_info,
    categories: state.cco_profile.filters_data?.uniq,
    current_location: state.search.location,
    locations: state.cco_profile.locations_data,
    adminCampaignList: state.home.admin_campaigns_list
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    getCampaigns: () => dispatch(getHomeCards()),
    getAdminCampaigns: () => dispatch(getAdminCampaigns()),
    changeCampaignStatus: (campaignName: any, status: any) =>
      dispatch(changeCampaignStatus("campaign", campaignName, status)),
    getFilteredCampaigns: (filter: any, city: any) =>
      dispatch(getFilteredCards(filter, city))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
