import * as types from './types';

const initialState: types.HomeState = {
  fetch_banner_loading: false,
  fetch_banner_succeeded: false,
  fetch_banner_failed: false,
  banner_info: [],

  campaigns_card_loading: false,
  campaigns_card_succeeded: false,
  campaigns_card_failed: false,
  campaign_card_info: [],

  admin_campaigns_list: {},
  admin_campaigns_list_loading: false,
  admin_campaigns_list_succeeded: false,
  admin_campaigns_list_failed: false
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.HOME_BANNER_REQUESTED:
      return {
        ...state,
        fetch_banner_loading: true,
        fetch_banner_failed: false,
        fetch_banner_succeeded: false
      };
    case types.HOME_BANNER_SUCCEEDED:
      return {
        ...state,
        fetch_banner_loading: false,
        fetch_banner_succeeded: true,
        fetch_banner_failed: false,
        banner_info: action.payload.data
      };
    case types.HOME_BANNER_FAILED:
      return {
        ...state,
        fetch_banner_loading: false,
        fetch_banner_succeeded: false,
        fetch_banner_failed: true
      };

    case types.CAMPAIGNS_CARDS_REQUESTED:
      return {
        ...state,
        campaigns_cards_loading: true,
        campaigns_cards_failed: false,
        campaigns_cards_succeeded: false
      };
    case types.CAMPAIGNS_CARDS_SUCCEEDED:
      return {
        ...state,
        campaigns_cards_loading: false,
        campaigns_cards_succeeded: true,
        campaigns_cards_failed: false,
        campaign_card_info: action.payload.data
      };
    case types.CAMPAIGNS_CARDS_FAILED:
      return {
        ...state,
        campaigns_cards_loading: false,
        campaigns_cards_succeeded: false,
        campaigns_cards_failed: true
      };

    case types.ADMIN_CAMPAGIN_LIST_REQUESTED:
      return {
        ...state,
        admin_campaigns_list_loading: true,
        admin_campaigns_list_succeeded: null,
        admin_campaigns_list_failed: null
      };
    case types.ADMIN_CAMPAGIN_LIST_SUCCEEDED:
      return {
        ...state,
        admin_campaigns_list: action.payload.data,
        admin_campaigns_list_loading: false,
        admin_campaigns_list_succeeded: true,
        admin_campaigns_list_failed: false
      };
    case types.ADMIN_CAMPAGIN_LIST_FAILED:
      return {
        ...state,
        admin_campaigns_list_loading: false,
        admin_campaigns_list_succeeded: false,
        admin_campaigns_list_failed: true
      };

    default:
      return state;
  }
};
